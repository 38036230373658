import { useState } from "react";

import { StyledDashboard } from "styles/molecules/Dashboard.styled";
import {
  DashboardStat,
  DashboardSection,
} from "_components/molecules/Dashboard";

import GameCard, { type OverlayProps } from "features/game/GameCard";
import BoostOverlay from "features/game/BoostOverlay";
import InspectOverlay from "features/game/InspectOverlay";

const Games = () => {
  const [overlayActive, setOverlayActive] = useState<OverlayProps | null>(null);

  if (overlayActive?.overlay === "Inspect") {
    return (
      <InspectOverlay
        title={overlayActive.title}
        studio={overlayActive.studio}
        closeHandler={() => setOverlayActive(null)}
      />
    );
  }

  if (overlayActive?.overlay === "Boost") {
    return (
      <BoostOverlay
        title={overlayActive.title}
        closeHandler={() => setOverlayActive(null)}
      />
    );
  }

  return (
    <StyledDashboard>
      <DashboardSection>
        <DashboardStat title="Total Game Ownership" value="5%" />
        <DashboardStat title="Total Game Ownership" value="5%" />
        <DashboardStat title="Total Game Ownership" value="5%" />
      </DashboardSection>

      <DashboardSection title="Your Games" note="3 of 3 selected">
        <GameCard
          title="Astral"
          studio="Red Wall Productions"
          boostable
          setOverlayActive={setOverlayActive}
        />
      </DashboardSection>

      <DashboardSection
        title="Available Games"
        note="20 available"
      ></DashboardSection>
    </StyledDashboard>
  );
};

export default Games;

import { StyledChatBar } from "../styles/ChatElements.styled";

const ChatBar = ({ messageBody, setMessageBody, submitHandler }) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };

  return (
    <div className="chatbar">
      <StyledChatBar>
        <input
          type="text"
          placeholder="Your message here.."
          value={messageBody}
          onChange={(e) => setMessageBody(e.target.value)}
          maxLength={200}
          onKeyPress={handleKeyPress}
        />
        <button onClick={submitHandler} />
      </StyledChatBar>
    </div>
  );
};

export default ChatBar;

import styled, { css } from "styled-components";

import { hexToRgba } from "js/util/converters";

const TabStyle = css`
  height: 45px;
  padding: 8px;
`;

export const StyledChatSidebar = styled.div`
  background-color: var(--bgDarkAccent);

  padding: 10px;
  border-radius: 10px;

  min-height: 100%;
  min-width: min(400px, 90%);
  flex-grow: 1;
`;

export const StyledChatGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: start;
  gap: 10px;
  margin-bottom: 10px;

  width: 100%;
`;

const StyledGroupHeading = styled.h3`
  ${TabStyle}

  cursor: pointer;

  background-color: var(--main-secondary);
  border-radius: 5px;
  width: 100%;
  margin: 0;
`;

const StyledGroupTab = styled.div`
  --radius: 5px;
  --before-size: 10px;

  ${TabStyle}

  position: relative;
  width: 90%;

  .overflow-wrap {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 2;

    display: flex;
    align-items: center;

    position: absolute;
    padding-left: calc(var(--before-size) + 10px);
    transition: all 0.2s ease-in-out;
    background-color: var(--main-secondary);
    border-radius: var(--radius);

    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: var(--before-size);
      background-color: ${(props) =>
        props.$unread ? "var(--contrast-one)" : "var(--main-primary)"};

      transition: all 0.2s ease-in-out;
    }

    ${(props) =>
      props.$isActive
        ? css`
            --before-size: 100%;

            padding-left: 0;
            justify-content: center;
          `
        : css`
            &:hover {
              --before-size: 30px;
            }
          `}
  }

  h3 {
    margin: 0;
    z-index: 1;
    user-select: none;

    &::before {
      ${(props) => {
        if (props.$type === "group") {
          return css`
            content: "[Group] ";
          `;
        } else {
          return css`
            content: "";
          `;
        }
      }}

      position: relative;
      display: inline;
    }
  }

  input {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }
`;

const StyledOnlineStatus = styled.div`
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  transform: translate(25%, -25%);
  border-radius: 100%;
  z-index: 4;
  background-image: ${(props) =>
    props.$online
      ? `linear-gradient(to bottom right, var(--main-secondary), ${hexToRgba(
          "--main-secondary",
          0.5
        )})`
      : `linear-gradient(to bottom right, var(--main-secondary), ${hexToRgba(
          "--main-secondary",
          0.5
        )})`};
  filter: ${(props) =>
    props.$online
      ? "drop-shadow(0 0 1px #00FFA6)"
      : "drop-shadow(0 0 1px #D6D6D6)"};
`;

export const GroupHeading = ({ children: name }) => {
  return <StyledGroupHeading>{name}</StyledGroupHeading>;
};

export const GroupTab = ({ children: name, $online, ...props }) => {
  return (
    <StyledGroupTab {...props}>
      <StyledOnlineStatus $online={$online} />
      <div className="overflow-wrap">
        <h3>{name}</h3>
        <input name="chat" type="radio" />
      </div>
    </StyledGroupTab>
  );
};

import { styled, css } from "styled-components";

export interface FlexboxProps {
  $height?: string;
  $maxHeight?: string;
  $width?: string;
  $maxWidth?: string;
  $row?: boolean;
  $pad?: string;
  $bg?: string;
  $rad?: string;
  $align?: string;
  $justify?: string;
  $wrap?: boolean;
  $round?: string;
  $gap?: string;
  $grow?: string;
  $text?: string;
  $reversed?: boolean;
  style?: { [key: string]: any };
}
export interface GridProps extends FlexboxProps {
  $cols?: string;
  $rows?: string;
  $jusiifyItems?: string;
  $flow?: string;
  $autoCols?: string;
  $autoRows?: string;
}
export interface PairDisplayProps extends FlexboxProps {
  $primary?: string;
  $secondary?: string;
  $flipped?: boolean;
}
export interface SbsProps {
  $paddingX?: string;
  $align?: string;
  $justify?: string;
  $column?: string;
  $shadowed?: "center" | string;
  $gap?: string;
  $width?: string;
  $defaultWrap?: boolean;
  $centered?: string;
  $reversed?: boolean;
  flipped?: boolean;
  $manualWrap?: boolean;
  $yGap?: string;
}

// Allows dynamica use of '$cols' and '$rows' to manully or use 'repeat' to fill template
function getTemplate(prop?: string) {
  if (!prop) return;
  if (prop.split(" ").length === 1) {
    return `repeat(${prop}, 1fr)`;
  } else {
    return prop;
  }
}

const ReversedStyle = css`
  & > :first-child {
    order: 2;
  }

  & > :last-child {
    order: 1;
  }
`;

export const StyledFlexbox = styled.div<FlexboxProps>`
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};

  max-height: ${(props) => props.$maxHeight};
  max-width: ${(props) => props.$maxWidth};

  display: flex;
  flex-direction: ${(props) => (props.$row ? "row" : "column")};
  align-items: ${(props) => props.$align || "start"};
  justify-content: ${(props) => props.$justify || "start"};
  text-align: ${(props) => props.$text || "start"};

  background: ${(props) => props.$bg};
  border-radius: ${(props) => props.$rad};

  border-radius: ${(props) => props.$round};
  padding: ${(props) => props.$pad};
  flex-wrap: ${(props) => props.$wrap && "wrap"};
  flex-grow: ${(props) => props.$grow};
  gap: ${(props) => props.$gap};

  ${(props) => {
    switch (props.$reversed) {
      case true:
        return ReversedStyle;
      default:
        return null;
    }
  }};
`;

export const StyledGrid = styled(StyledFlexbox)<GridProps>`
  display: grid;
  grid-template-columns: ${(props) => getTemplate(props.$cols)};
  grid-template-rows: ${(props) => getTemplate(props.$rows)};
  justify-items: ${(props) => props.$jusiifyItems};
  grid-auto-flow: ${(props) => props.$flow};
  grid-auto-columns: ${(props) => props.$autoCols};
  grid-auto-rows: ${(props) => props.$autoRows};
`;

// Displays a 'pair' of stacked key, value 'pairs', presenting each data item individually
export const StyledPairDisplay = styled(StyledFlexbox)<PairDisplayProps>`
  --primary: ${(props) => props.$primary || "white"};
  --secondary: ${(props) => props.$secondary || "var(--defaultTint)"};

  width: ${(props) => props.$width || "170px"};
  // Wraps the <p> text children. Overrides 'Flexbox' '$wrap' as it's unneeded in this component
  overflow-wrap: ${(props) => props.$wrap && "anywhere"};

  & > *:first-child {
    color: ${(props) =>
      props.$flipped ? "var(--primary)" : "var(--secondary)"};
  }

  & > *:nth-child(2) {
    margin: 0;
    line-height: 1.4rem;
    color: ${(props) =>
      props.$flipped ? "var(--secondary)" : "var(--primary)"};
  }
`;

export const StyledSbs = styled.div<SbsProps>`
  display: flex;
  position: relative;
  padding: 0 ${(props) => props.$paddingX};

  align-items: ${(props) => props.$align || "center"};
  justify-content: ${(props) => props.$justify || "center"};

  flex-direction: ${(props) => (props.$column && "column") || "row"};
  filter: ${(props) =>
    props.$shadowed === "center"
      ? "drop-shadow(0px 0px 8px #000)"
      : props.$shadowed
      ? "drop-shadow(8px 8px 8px #000)"
      : "unset"};

  gap: ${(props) => props.$gap || "15%"};

  height: 100%;
  width: ${(props) => props.$width};

  flex-wrap: ${(props) => props.$defaultWrap && "wrap"};

  ${(props) => {
    if (props.$centered) {
      return css`
        margin-left: auto;
        margin-right: auto;
        max-width: ${props.$centered};
      `;
    }
  }}

  ${(props) => {
    switch (props.$reversed) {
      case true:
        return ReversedStyle;
      default:
        return null;
    }
  }};

  ${(props) => {
    switch (props.flipped) {
      case true:
        return css`
          & > :last-child .center img {
            transform: scaleX(-1);
          }
        `;
      default:
        return null;
    }
  }};

  @media screen and (max-width: 1370px) {
    ${(props) => {
      // Use manualWrap if the the yGap overriding the gap before wrap activates is an issue
      if (props.$manualWrap) {
        return css`
          flex-direction: column;
        `;
      } else {
        return css`
          flex-wrap: wrap;
        `;
      }
    }};

    gap: ${(props) => props.$yGap || "20px"};
  }
`;

import styled from "styled-components";

export interface StandaloneNoteProps {
  $align?: "start" | "center" | "end";
}

export const StyledStandaloneNote = styled.div<StandaloneNoteProps>`
  background: var(--bgDarkShadow);
  width: min(800px, 90%);
  padding: 60px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  & > *:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: ${(props) => props.$align || "center"};
    gap: 10px;
  }

  & > * {
    max-width: 500px;
  }
`;

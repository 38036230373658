import { useState, useEffect, useContext } from "react";

import { StyledAppendableList } from "styles/molecules/ColumnItem.styled";

import {
  StyledOptionContainer,
  StyledInputContainer,
} from "features/form/FormContainers.styled";
import { StyledFormPage } from "features/form/Form.styled";

import Form from "features/form/Form";
import FormItem from "_components/features/form/FormItem";
import FormOption from "_components/features/form/FormOption";
import FormSelect from "_components/features/form/elements/FormSelect";

import IncomingFile from "_components/features/file/download/IncomingFile";
import UploadBtn from "_components/features/file/buttons/UploadBtn";
import UploadingItem from "_components/features/file/upload/UploadingItem";

import { UploadContext } from "components/function/contexts/UploadContext";

const topicsList = [
  "10",
  "20",
  "Complaint",
  "Feature Request",
  "Account Issue",
  "Confusion",
];

const Send = ({
  method = "single",
  directory = "19/sent/editing-assets",
  acceptTypes = "image/*, video/*",
}) => {
  const [editEnabled, setEditEnabled] = useState(false);
  const [thumbnailEnabled, setThumbnailEnabled] = useState(false);

  const [duration, setDuration] = useState("");

  const [canUpload, setCanUpload] = useState(true);
  const [[progressUris], [], [completedUploads, setCompletedUploads]] =
    useContext(UploadContext);

  useEffect(() => {
    setCompletedUploads(
      completedUploads.filter(
        (uri) =>
          progressUris.find((targetUri) => targetUri === uri) != undefined
      )
    );
  }, [progressUris]);

  return (
    <StyledFormPage>
      <Form
        title="Service Request Form"
        description="Your team will receive your files and send you the product which you requested here within a few days"
        actionText="Publish"
        onSubmit={console.log} // TODO
      >
        <StyledOptionContainer>
          <FormOption
            value="Edit"
            name="product"
            inputType="checkbox"
            selectedOption={editEnabled}
            setSelectedOption={() => setEditEnabled(!editEnabled)}
          >
            Edit
          </FormOption>
          <FormOption
            value="Thumbnail"
            name="product"
            inputType="checkbox"
            selectedOption={thumbnailEnabled}
            setSelectedOption={() => setThumbnailEnabled(!thumbnailEnabled)}
          >
            Thumbnail
          </FormOption>
        </StyledOptionContainer>

        <StyledInputContainer>
          <FormItem
            $title="Video Name"
            placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr"
            style={{ gridColumn: !editEnabled ? "1 / -1" : "" }}
          />
          {editEnabled && (
            <>
              <FormSelect
                $title="Duration of Edit"
                selection={duration}
                setSelection={setDuration}
                optionsList={topicsList}
                placeholder="Select a duration"
              />
              <FormItem
                $title="Description of Edit"
                $large
                type="text"
                placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr"
                style={{ gridColumn: !thumbnailEnabled ? "1 / -1" : "" }}
              />
            </>
          )}
          {thumbnailEnabled && (
            <FormItem
              $title="Description of Thumbnail"
              $large
              type="text"
              placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr"
              style={{ gridColumn: !editEnabled ? "1 / -1" : "" }}
            />
          )}
        </StyledInputContainer>
      </Form>

      <StyledAppendableList>
        <UploadBtn
          directory={directory}
          acceptTypes={acceptTypes || "image/*, video/*"}
          setCanUpload={setCanUpload}
          method={method}
        />

        <UploadingItem
          key={"difuk@difw9"}
          progressUri={"difuk@difw9"}
          canUpload={canUpload}
          setCanUpload={setCanUpload}
        />
      </StyledAppendableList>
    </StyledFormPage>
  );
};

export default Send;

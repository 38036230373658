import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/*
  When the route associate with this page is visited, it will redirect to the child path.
  Useful for sidebar tabs which have sub-tabs but no main tab for the basename path
*/
const RerouteToChildChandler = ({ path }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(path);
  }, []);

  return <></>;
};

export default RerouteToChildChandler;

import styled from "styled-components";

import { StyledFlexbox } from "styles/util/Flex.styled";

export interface StyledStatus {
  $status: "Available" | "Busy" | "Vacation";
}

export const StyledUser = styled(StyledFlexbox)`
  & > p {
    color: var(--defaultDark);
  }
`;

export const StyledStatus = styled.p<StyledStatus>`
  color: ${(props) =>
    props.$status === "Busy"
      ? "var(--error)"
      : props.$status === "Vacation"
      ? "var(--inactive)"
      : "var(--primaryDim)"};
`;

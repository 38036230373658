import {
  StyledInfoBlockH2,
  StyledInfoBlockP,
} from "styles/molecules/InfoBlock.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledCenteredArea } from "styles/util/CenteredArea.styled";
import { XPattern } from "_components/molecules/structure/Patterns";

import StandaloneGallery from "components/organisms/StandaloneGallery";

import { Cta, CtaAlt, CtaInlineText } from "components/atoms/CtaElements";
import { ScrollToBtn } from "_components/atoms/buttons/ScrollElements";
import { TextFadeIcon } from "components/atoms/buttons/IconButtons";

import gameDatas from "js/data/gameDatas";

function HomeHeader({ name }) {
  // TODO: Change to non-watermarked, low resolution images
  const content = gameDatas.map((data) => {
    return {
      title: data.game,
      description: data.studio,
      image: data.preview[0].original,
    };
  });

  return (
    <section
      name={name}
      style={{
        position: "relative",
        height: "1000px",
        backgroundImage:
          "linear-gradient(to bottom right, var(--bgShadow), var(--bgShadowDarker))",
      }}
    >
      <StyledSpacer $pad="200px" />
      <StyledFlexbox $row $align="center" $justify="center" $gap="170px">
        <StyledFlexbox>
          <StyledInfoBlockH2>Channel Growth for YouTubers</StyledInfoBlockH2>
          <StyledInfoBlockP style={{ marginTop: "30px" }}>
            Connecting the Roblox Content Creation Industry with the Roblox Game
            Development Industry for the benefit of one-another
          </StyledInfoBlockP>
          <StyledFlexbox $row $gap="37px" style={{ marginTop: "46px" }}>
            <StyledFlexbox $align="center" $gap="13px">
              <Cta to="/join">Get Started</Cta>
              <CtaInlineText to="/explore/affiliate">
                View Alternatives
              </CtaInlineText>
            </StyledFlexbox>
            <CtaAlt to="/learn/production">Learn More</CtaAlt>
          </StyledFlexbox>
        </StyledFlexbox>
        <StandaloneGallery content={content} />
      </StyledFlexbox>

      <StyledSpacer $pad="170px" />
      <StyledFlexbox $row $gap="70px" $align="center" $justify="center">
        <TextFadeIcon title="Team" src="/images/buttons/team-icon.png" />
        <TextFadeIcon title="Consistency" src="/images/icons/active/sun.png" />
        <TextFadeIcon
          title="Advertising"
          src="/images/icons/active/chart.png"
        />
        <TextFadeIcon title="Payouts" src="/images/buttons/money-icon.png" />
      </StyledFlexbox>

      <StyledSpacer $pad="100px" />
      <div style={{ position: "relative" }}>
        <StyledCenteredArea $absolute>
          <ScrollToBtn
            to="about-us"
            style={{ transform: "translateY(-50%)" }}
          />
        </StyledCenteredArea>
      </div>

      <XPattern $top="50px" $left="50px" $origin="-50%, -50%" />
      <XPattern $top="120px" $right="120px" $origin="50%, -50%" />
      <XPattern $bottom="100px" $left="250px" $origin="-50%, 50%" />
    </section>
  );
}

export default HomeHeader;

import { useState } from "react";

import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledLineBorder } from "styles/molecules/LineBorder.styled";

import Header from "_components/molecules/structure/Header";
import { XPattern } from "_components/molecules/structure/Patterns";
import { ArrowColumnItem } from "components/molecules/ColumnItem";

import { centeredComponent } from "_components/function/modifiers/areaPositions";

const CenteredFlexbox = centeredComponent(StyledFlexbox);

interface FaqProps {
  $bg?: string;
  questions: any[];
  answers: any[];
}

const Faq = ({ $bg, questions, answers }: FaqProps) => {
  const [selectedItem, setSelectedItem] = useState<number | undefined>(
    undefined
  );

  return (
    <section
      style={{
        position: "relative",
        backgroundImage:
          $bg ||
          "linear-gradient(to bottom right, var(--bgShadow), var(--bgShadowDarker))",
        padding: "110px 0 160px 0",
      }}
    >
      <div style={{ position: "relative", zIndex: 1 }}>
        <Header
          $align="start"
          title="Frequently Asked Questions"
          description="The questions you ask us most often on social media, by email or on the site are answered here."
        />

        <StyledSpacer />
        <CenteredFlexbox $row $align="start" $justify="center" $gap="60px">
          <StyledLineBorder $title="Questions">
            {questions.map((question, i) => (
              <ArrowColumnItem
                $active={selectedItem === i}
                onClick={() => {
                  if (selectedItem === i) {
                    setSelectedItem(undefined);
                    return;
                  }
                  setSelectedItem(i);
                }}
              >
                {question}
              </ArrowColumnItem>
            ))}
          </StyledLineBorder>
          {selectedItem != undefined && (
            <StyledLineBorder $width="column" $title="Answer">
              {answers[selectedItem]}
            </StyledLineBorder>
          )}
        </CenteredFlexbox>
      </div>

      <XPattern $top="50px" $left="5%" $origin="-50%, -50%" />
      <XPattern $bottom="150px" $right="200px" $origin="50%, 50%" />
    </section>
  );
};

export default Faq;

import React, { useState, useRef, useEffect } from "react";
import { styled, css, keyframes } from "styled-components";

const itterateDuration = 3000;
const itterationAnimationDuration = itterateDuration / 1000 + "s";

const imgRadius = "35px";

const rotate = keyframes`
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
`;

const antiRotate = keyframes`
  0% {
    rotate: 0deg;
  }

  50% {
    rotate: -180deg;
  }

  100% {
    rotate: -360deg;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
`;

function getPositionOnCircle(index, length, axis, offset) {
  if (axis === "y") {
    return (
      ((35 * Math.sin((Math.PI / length) * index)) ^ 2) -
      (offset ? 35 * offset : 0) +
      "px"
    );
  }

  return (
    ((35 * Math.cos((Math.PI / length) * index)) ^ 2) -
    (offset ? 35 * offset : 0) +
    "px"
  );
}

const StyledRotCircle = styled.div`
  --rot-duration: 45s;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 300px;
  width: 300px;

  .circle {
    height: 100%;
    width: 100%;
    border: 1px solid var(--bgOutline);
    border-radius: 50%;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: ${rotate} var(--rot-duration) linear infinite;

    animation-play-state: ${({ $isActive }) => {
      switch ($isActive) {
        case true:
          return "running";
        case false:
          return "paused";
      }
    }};
  }

  .circle img {
    display: block;
    position: absolute;
    height: calc(${imgRadius} * 2);
    width: calc(${imgRadius} * 2);
    border-radius: 50%;
    background: linear-gradient(
      to bottom,
      transparent,
      var(--actionPrimaryDark)
    );

    padding: 15px;

    animation: ${antiRotate} var(--rot-duration) linear infinite;
    animation-play-state: ${({ $isActive }) => {
      switch ($isActive) {
        case true:
          return "running";
        case false:
          return "paused";
      }
    }};
  }

  ${(props) => {
    switch (props.$numIcons) {
      case 6:
        return css`
          .circle img:nth-child(1) {
            top: ${getPositionOnCircle(0, 6, "y", 1)};
            /* transform: translate(0, -50%); */
          }

          .circle img:nth-child(2) {
            right: ${getPositionOnCircle(1, 6, "x", 1)};
            top: ${getPositionOnCircle(1, 6, "y")};
            /* transform: translate(50%, 0); */
          }

          .circle img:nth-child(3) {
            right: ${getPositionOnCircle(2, 6, "x", 1)};
            bottom: ${getPositionOnCircle(2, 6, "y")};
            /* transform: translate(50%, 0); */
          }

          .circle img:nth-child(4) {
            bottom: ${getPositionOnCircle(3, 6, "y", 2)};
            /* transform: translate(0, 100%); */
          }

          .circle img:nth-child(5) {
            left: ${getPositionOnCircle(4, 6, "x")};
            bottom: ${getPositionOnCircle(4, 6, "y")};
            /* transform: translate(0, 0); */
          }

          .circle img:nth-child(6) {
            left: ${getPositionOnCircle(5, 6, "x", -1)};
            top: ${getPositionOnCircle(5, 6, "y")};
            /* transform: translate(50%, 0); */
          }
        `;
    }
  }}
`;

const StyledInfoCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  position: absolute;
  height: 90%;
  width: 90%;
  border-radius: 50%;
  background: linear-gradient(to bottom, transparent, var(--actionPrimaryDark));

  ${(props) => {
    switch (props.mode) {
      case "fadeIn":
        return css`
          animation: ${itterationAnimationDuration} ease-out ${fadeIn} infinite;
        `;
      default:
        return css`
          animation: none;
        `;
    }
  }};

  & > img {
    height: 50%;
    position: absolute;
    opacity: 0.15;
    z-index: -1;
  }

  & > p {
    width: 75%;
    text-align: center;
    color: white;
    font-size: 1.25rem;
    line-height: 1.4rem;
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    & > p {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    & > p {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`;

const HoverableIcon = ({
  setIsIconHovered,
  activeIconIndex,
  setActiveIconIndex,
  index,
  data,
}) => {
  const isActive = activeIconIndex === index;
  return (
    <img
      onMouseOver={() => {
        setIsIconHovered(true);
        setActiveIconIndex(index);
      }}
      onMouseOut={() => {
        setIsIconHovered(false);
      }}
      src={data[0]}
      style={{
        background: isActive
          ? "linear-gradient(to bottom, transparent, var(--actionPrimary))"
          : "",
      }}
    />
  );
};

const RotCircle = ({ infoList, ...props }) => {
  const numIcons = infoList.length;

  // Allows correct icon to be highlighted and display it's info
  const [activeIconIndex, setActiveIconIndex] = useState(0);
  // Determines whether the auto traversal is active or not based on take over from human
  const [isIconHovered, setIsIconHovered] = useState(false);

  // Track so we can activate it's animations in and out of transitions
  const [infoCenterMode, setInfoCenterMode] = useState("none");

  // Log this separate, to continue from last 'checkpoint' after done hovering
  const intervalIndexRef = useRef(0);
  // Log so we can cancel auto traversal of icons when hovering
  const intervalRef = useRef(null);

  const itterateIcons = (increment) => {
    increment = increment == null ? 1 : increment;
    const nextIndex = (intervalIndexRef.current + increment) % numIcons;
    intervalIndexRef.current = nextIndex;
    setActiveIconIndex(nextIndex);
  };

  useEffect(() => {
    if (isIconHovered === true) {
      if (intervalRef.current) {
        setInfoCenterMode("none");
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } else {
      if (intervalRef.current == null) {
        setInfoCenterMode("fadeIn");
        itterateIcons(0);
        const id = setInterval(itterateIcons, itterateDuration);
        intervalRef.current = id;
      }
    }
  }, [isIconHovered]);

  return (
    <StyledRotCircle {...props} $numIcons={numIcons} $isActive={!isIconHovered}>
      <StyledInfoCenter mode={infoCenterMode}>
        <img src={infoList[activeIconIndex][0]} />
        <p>{infoList[activeIconIndex][1]}</p>
      </StyledInfoCenter>
      <div className="circle">
        {infoList.map((data, index) => (
          <HoverableIcon
            key={index}
            setIsIconHovered={setIsIconHovered}
            activeIconIndex={activeIconIndex}
            setActiveIconIndex={setActiveIconIndex}
            index={index}
            data={data}
          />
        ))}
      </div>
    </StyledRotCircle>
  );
};

export default RotCircle;

import styled from "styled-components";

export const StyledContentDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$align || "center"};
  justify-content: ${(props) => props.$justify || "end"};
  position: relative;

  gap: ${(props) => (props.$gap === "close" ? "10px" : "30px")};
  height: auto;
  min-height: ${(props) => props.$height !== "auto" && "100%"};
  width: 100%;
  padding: min(10%, 20px);

  // For when embedded in forms
  grid-column: 1 / -1;
`;

export const StyledScrollContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.$bg};
`;

import { createContext } from "react";

export const UploadContext = createContext([
  [
    [],
    () =>
      console.error(
        "Set state failed! Upload context has not been initialized."
      ),
  ],
  [
    [],
    () =>
      console.error(
        "Set state failed! Upload context has not been initialized."
      ),
  ],
  [
    [],
    () =>
      console.error(
        "Set state failed! Upload context has not been initialized."
      ),
  ],
]);

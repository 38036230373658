import { useState } from "react";

import { StyledFlexbox, StyledGrid } from "styles/util/Flex.styled";

import Overlay from "components/organisms/Overlay";
import FormItem from "_components/features/form/FormItem";
import FormOption from "_components/features/form/FormOption";
import { Cta, CtaInlineText } from "components/atoms/CtaElements";

export interface BoostOverlayProps {
  title: string;
  closeHandler: () => void;
}

const BoostOverlay = ({ title, closeHandler }: BoostOverlayProps) => {
  const [selectedOption, setSelectedOption] = useState("Thumbnail");

  return (
    <Overlay
      $gap="80px"
      heading="Boost Game Ownership"
      title={title}
      src="/images/buttons/rocket-static.png"
    >
      <FormItem
        $title="Url"
        placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr"
        style={{ width: "100%" }}
      />

      <StyledFlexbox
        $width="100%"
        $align="center"
        $justify="center"
        $gap="25px"
      >
        <p
          style={{
            color: "var(--secondaryDim)",
            textAlign: "center",
            width: "75%",
          }}
        >
          Select your method of advertising
        </p>
        <StyledGrid
          $width="min(400px, 100%)"
          $align="center"
          $justify="center"
          $gap="20px"
          $cols="repeat(auto-fit, minmax(130px, 1fr))"
        >
          <FormOption
            value="a"
            name="method"
            inputType="checkbox"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          >
            Video
          </FormOption>
          <FormOption
            value="b"
            name="method"
            inputType="checkbox"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          >
            Video
          </FormOption>
          <FormOption
            value="c"
            name="method"
            inputType="checkbox"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          >
            Video
          </FormOption>
          <FormOption
            value="d"
            name="method"
            inputType="checkbox"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          >
            Video
          </FormOption>
        </StyledGrid>
      </StyledFlexbox>

      <StyledFlexbox
        $width="100%"
        $row
        $gap="40px"
        $align="center"
        $justify="center"
      >
        <CtaInlineText $col="var(--secondaryDim)" onClick={closeHandler}>
          Cancel
        </CtaInlineText>
        <Cta onClick={() => console.log(`Boosted '${title}'!`)}>Boost</Cta>
      </StyledFlexbox>
    </Overlay>
  );
};

export default BoostOverlay;

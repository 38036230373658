import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledUser, StyledStatus } from "../../styles/atoms/User.styled";
import { LinkSpan } from "./SpanElements";

interface User extends StyledFlexbox {
  $icon?: string;
  children?: any;
  [key: string]: any;
}

interface Status extends StyledStatus {
  path?: string;
  productTrackId?: string | number;
}

export const Status = ({ $status, path, productTrackId }: Status) => {
  return (
    <StyledStatus $status={$status}>
      {$status}
      {$status === "Busy" && (
        <>
          {": "}
          <LinkSpan $col="var(--error)" to={path}>
            #{productTrackId}
          </LinkSpan>
        </>
      )}
    </StyledStatus>
  );
};

const User = ({
  $icon = "/images/icons/user.png",
  $row,
  children,
  ...props
}: User) => {
  return (
    <StyledUser
      $width="170px"
      $align="center"
      $row={$row}
      $gap={$row ? "15px" : undefined} // Constant gap for row layout
      {...props}
    >
      <img src={$icon} />
      {children}
    </StyledUser>
  );
};

export default User;

import { StyledDashboard } from "styles/molecules/Dashboard.styled";
import { StyledSettings } from "features/Settings.styled";
import IconHeading from "components/atoms/IconHeading";

// separate <setting to handle button clicks,
// popup set state active,
// own style,
// button hover anim, self rendered bg changing

// add acocunt should be in <setting with the same functionality,
// but with an attribute to tell it to render <account instead

interface HeadingProps {
  title: string;
  src: string;
}

const Heading = ({ title, src }: HeadingProps) => {
  return (
    <div className="label">
      <IconHeading
        $alignSelf="center"
        title={title}
        src={src}
        style={{ transform: "scale(0.7)" }}
      />
    </div>
  );
};

const Settings = () => {
  return (
    <StyledDashboard>
      <StyledSettings>
        <section>
          <Heading
            title="Account"
            src="/images/buttons/notification-static.png"
          />
          <div className="list">
            <div aria-label="setting">
              <p>Username: Molly</p>
              <img src="/images/icons/static/basket.png" />
            </div>
            <div aria-label="setting"></div>
            <div aria-label="setting"></div>
          </div>
        </section>

        <section>
          <Heading
            title="Account"
            src="/images/buttons/notification-static.png"
          />
          <div className="list">
            <div aria-label="setting"></div>
            <div aria-label="setting"></div>
            <div aria-label="setting"></div>
            <div aria-label="setting"></div>
            <div aria-label="setting"></div>
            <div aria-label="setting"></div>
          </div>
        </section>

        <section>
          <Heading
            title="Account"
            src="/images/buttons/notification-static.png"
          />
          <div className="list">
            <p aria-label="heading"></p>
            <p aria-label="description"></p>
            <div aria-label="buttons">
              <img aria-label="option-button" />
            </div>
          </div>
        </section>
      </StyledSettings>
    </StyledDashboard>
  );
};

export default Settings;

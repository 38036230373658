import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AccountContext } from "components/function/contexts/AccountContext";

import getAccessToken from "js/features/auth/getAccessToken";

const AuthLoggedIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loggedIn] = useContext(AccountContext);

  const checkAuth = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) navigate("/dashboard/logout");
  };

  useEffect(() => {
    if (!loggedIn || location.pathname === "/dashboard/logout") return;
    checkAuth();
  }, [location.pathname]);

  return <></>;
};

export default AuthLoggedIn;

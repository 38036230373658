import styled from "styled-components";

export interface LineBorderProps {
  $title?: string;
  $height?: string;
  $width?: "column" | string;
  $pad?: string;
  $col?: string;
  $textCol?: string;
  $bg?: string;
}

export const StyledLineBorder = styled.div<LineBorderProps>`
  padding: ${(props) => props.$pad || "25px"};
  border: 1px solid ${(props) => props.$col || "var(--bgOutline)"};
  transition: border 0.15s ease-in-out;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: relative;

  height: ${(props) => props.$height};
  width: ${(props) => (props.$width === "column" ? "460px" : props.$width)};
  background: ${(props) => props.$bg};

  &::before {
    content: ${(props) => (props.$title ? `'${props.$title}'` : "")};
    position: absolute;
    top: -5px; // Offset
    transform: translateY(-100%);

    display: flex;
    justify-content: start;
    width: 100%;

    color: ${(props) => props.$textCol || "var(--secondaryDim)"};
    transition: color 0.2s ease-in-out;
  }

  & > p,
  & > ol * {
    width: 100%;
    color: var(--secondaryDim);
  }
`;

import { ReactSession } from "react-client-session";

import { StyledFlexbox } from "styles/util/Flex.styled";

import { NavLogo, StyledNav } from "styles/atoms/NavbarElements.styled";
import { NavExpand } from "components/atoms/NavbarElements";
import { NavProfile } from "_components/features/profile/NavProfile";

import { exploreContents, learnContents } from "js/navContents";

const Topbar = () => {
  const userAccountType = ReactSession.get("useraccounttype");

  return (
    <StyledNav>
      <StyledFlexbox $row $align="center" $justify="start" $gap="30px">
        <NavLogo />
        <NavExpand originAt="20%" contents={exploreContents}>
          Explore
        </NavExpand>
        <NavExpand originAt="0" contents={learnContents}>
          Learn
        </NavExpand>
      </StyledFlexbox>
      <NavProfile />
    </StyledNav>
  );
};

export default Topbar;

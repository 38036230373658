import React, { useEffect } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import { StyledCenteredArea } from "styles/util/CenteredArea.styled";

import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";
import { SimpleIconBtn } from "components/atoms/buttons/IconButtons";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const MESSAGE_LOAD_AMOUNT = 10;

const LoadMoreBtn = ({
  username,
  user1,
  selectedUser,
  messages,
  setMessages,
}) => {
  const accountType = ReactSession.get("useraccounttype");

  const loadMoreContents = async (start1, start2) => {
    axios
      .get(`${ENDPOINT}/api/comm/messages`, {
        params: {
          user2: selectedUser.name,
          type: selectedUser.accountType,
          start1: start1,
          start2: start2,
          length: MESSAGE_LOAD_AMOUNT,
          uid: user1 != "" ? user1 : undefined, // Tells server to return us another user's info as if we're them
        },
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const newMessages = res.data;

        if (start1 === 0 && start2 === 0) {
          if (newMessages.length === 0) {
            setMessages([
              {
                from: "System",
                body: `This is the beginning of your chat history with '${selectedUser.name}'!`,
              },
            ]);
          } else {
            setMessages(newMessages);
          }
        } else {
          setMessages((messages) => [...res.data, ...messages]);
        }
      })
      .catch(console.error);
  };

  const selectedUserObserver = () => {
    if (!selectedUser || username == "") return;
    loadMoreContents(0, 0);
  };

  function onClickHandler() {
    if (selectedUser.accountType === "Group") {
      loadMoreContents(messages.length, messages.length);
      return;
    }

    const user1Messages = messages.filter((msg) => msg.from === username);
    const user2Messages = messages.filter(
      (msg) => msg.from === selectedUser.name
    );
    loadMoreContents(user1Messages.length, user2Messages.length);
  }

  useEffect(selectedUserObserver, [selectedUser]);

  return (
    <SimpleIconBtn
      src="/images/buttons/refresh.png"
      style={{ alignSelf: "center" }}
      onClick={onClickHandler}
    />
  );
};

export default LoadMoreBtn;

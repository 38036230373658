import { useNavigate, useLocation } from "react-router-dom";

import {
  StyledExpandElement,
  StyledExpandCollection,
  StyledExpandHeading,
  StyledNavExpand,
} from "styles/atoms/NavbarElements.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";

const ExpandElement = ({ title, desc, icon, to }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <StyledExpandElement
      $isActive={pathname === to}
      onClick={() => navigate(to)}
    >
      <img src={icon} />
      <StyledFlexbox $gap="5px">
        <p className="heading">{title}</p>
        <p className="item">{desc}</p>
      </StyledFlexbox>
    </StyledExpandElement>
  );
};

export const NavExpand = ({ children: name, contents, originAt, ...props }) => {
  const expandCollections = [];

  for (const [sectionTitle, pagesData] of Object.entries(contents)) {
    const sectionEntries = [];
    for (const [pageTitle, pageData] of Object.entries(pagesData)) {
      const to = pageData[0];
      const desc = pageData[1];
      const icon = pageData[2];
      sectionEntries.push(
        <ExpandElement
          key={pageTitle}
          title={pageTitle}
          desc={desc}
          icon={icon}
          to={to}
        />
      );
    }

    expandCollections.push(
      <StyledExpandCollection key={sectionTitle}>
        <StyledExpandHeading>{sectionTitle}</StyledExpandHeading>
        {sectionEntries}
      </StyledExpandCollection>
    );
  }

  return (
    <StyledNavExpand $originAt={originAt} {...props}>
      {name}
      <div className="container">
        <div className="triangle"></div>
        <div className="box">{expandCollections}</div>
      </div>
    </StyledNavExpand>
  );
};

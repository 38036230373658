import styled from "styled-components";

import { StyledFlexbox, StyledGrid } from "styles/util/Flex.styled";

import OverlayTextImg from "components/molecules/OverlayTextImg";
import Stat from "components/atoms/Stat";

import { CtaInlineText } from "components/atoms/CtaElements";
import { StyledTab } from "_components/molecules/containers/navigation/Sidebar.styled";

export interface OverlayProps {
  overlay: "Inspect" | "Boost";
  title: string;
  studio: string;
}

export interface GameCardProps {
  title: string;
  studio: string;
  boostable?: boolean;
  setOverlayActive: (props: OverlayProps) => void;
}

const GameCard = ({
  title,
  studio,
  boostable,
  setOverlayActive,
}: GameCardProps) => {
  return (
    <StyledFlexbox $width="390px" aria-label="game-card">
      <OverlayTextImg
        $height="150px"
        $offsetX="12px"
        $offsetY="12px"
        $round="5px 5px 0 0"
        Title={styled.h3``}
        Description={styled.p``}
        corner={3}
        title={title}
        description={studio}
        image="/images/astral-gfx.jpg"
      />

      <StyledFlexbox
        $width="100%"
        $align="center"
        $justify="center"
        $gap="20px"
        $pad="20px"
        $bg="var(--bgLightHighlight)"
        $round="0 0 5px 5px"
        aria-label="content-container"
      >
        <StyledGrid
          $width="100%"
          $gap="20px"
          $align="center"
          $justify="center"
          $pad="15px"
          $cols="repeat(auto-fit, minmax(150px, 1fr))"
          aria-label="stats-grid"
        >
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
        </StyledGrid>

        <StyledFlexbox
          $row
          $justify={boostable ? "space-between" : "end"}
          $height="25px"
          $width="100%"
          aria-label="cta-container"
        >
          {boostable && (
            <StyledTab
              style={{ paddingLeft: "0" }}
              $icon="/images/buttons/rocket.png"
              onClick={() =>
                setOverlayActive({
                  overlay: "Boost",
                  title: title,
                  studio: studio,
                })
              }
            >
              Boost
            </StyledTab>
          )}
          <CtaInlineText
            style={{ paddingRight: "0.7rem" }}
            $hover="arrow"
            onClick={() =>
              setOverlayActive({
                overlay: "Inspect",
                title: title,
                studio: studio,
              })
            }
          >
            Inspect
          </CtaInlineText>
        </StyledFlexbox>
      </StyledFlexbox>
    </StyledFlexbox>
  );
};

export default GameCard;

import styled from "styled-components";

export interface DashboardProps {
  $row?: boolean;
}

export const StyledDashboard = styled.div<DashboardProps>`
  display: flex;
  flex-direction: ${(props) => (props.$row ? "row" : "column")};
  gap: 70px;
  flex-grow: 1; // If there is a right sidebar, this keeps the main content full
  padding: 65px 8%;
`;

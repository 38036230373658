import { useState } from "react";
import styled, { css } from "styled-components";

import { StyledFlexbox, StyledSbs } from "styles/util/Flex.styled";

type Stat = { value: number; key: string; unit: string };

type Chart = {
  stats: Stat[];
};

interface StyledBar {
  $active: boolean;
  $value: number;
  $unit: string;
}

interface StyledCompanyStat {
  $active: boolean;
}

function abbrNum(number: number, decPlaces: number) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  let abbreviatedNumber = number.toString();

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      abbreviatedNumber = number.toString() + abbrev[i];

      // We are done... stop
      break;
    }
  }

  return abbreviatedNumber;
}

export const StyledCompanyStat = styled.p<StyledCompanyStat>`
  --size: 30px;

  display: flex;
  align-items: center;
  gap: 12px;

  color: var(--inactive);
  transform-origin: top left;
  transition: all 0.1s linear;
  user-select: none;

  height: 100%;

  &::before {
    content: "";
    height: var(--size);
    width: var(--size);
    position: relative;
    background-image: linear-gradient(
      to bottom,
      var(--bgLightGradientFirst),
      var(--bgLightGradientLast)
    );
    border-radius: 5px;
  }

  ${(props) =>
    props.$active &&
    css`
      color: var(--primary);
      transform: scale(1.1);

      &::before {
        background-image: linear-gradient(
          to bottom,
          var(--actionPrimaryGradientFirst),
          var(--actionPrimaryGradientMiddle),
          var(--actionPrimaryGradientLast)
        );
      }
    `}

  &:hover {
    filter: brightness(1.2);
  }
`;

const StyledChart = styled.div`
  --overlap: 50px;
  --maxHeight: 400px;
  --gap: 50px;

  display: flex;
  gap: var(--gap);
  align-items: end;

  position: relative;
  height: var(--maxHeight);
  width: 700px;

  &::before {
    content: "";
    position: absolute;
    bottom: calc(-1 * var(--overlap));
    left: 0;
    height: calc(100% + var(--overlap));
    width: 0.5px;
    background-color: var(--bgOutline);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(-1 * var(--overlap));
    height: 0.5px;
    width: calc(100% + var(--overlap));
    background-color: var(--bgOutline);
  }

  & > div:first-child {
    margin-left: var(--gap);
  }
`;

const StyledBar = styled.div<StyledBar>`
  width: 40px;
  // Capped max value, and applied multiplier to get values to pixels convertion create longer bars
  height: ${(props) => `min(calc(${props.$value}px * 60), var(--maxHeight))`};
  background-image: ${(props) =>
    props.$active
      ? "linear-gradient(to bottom, var(--actionPrimaryGradientFirst), var(--actionPrimaryGradientMiddle), var(--actionPrimaryGradientLast))"
      : "linear-gradient(to bottom, var(--bgLightGradientFirst), var(--bgLightGradientLast))"};
  position: relative;

  &::before {
    content: ${(props) => `'${abbrNum(props.$value, 2)}'`};
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    color: ${(props) => (props.$active ? "white" : "var(--inactive)")};
  }

  &::after {
    content: ${(props) => (props.$unit ? `'${props.$unit}'` : "")};
    position: absolute;
    text-align: center;
    rotate: -90deg;
    top: 30px;
    left: 0;
    right: 0;
    color: ${(props) => (props.$active ? "white" : "var(--inactive)")};
  }

  &:hover {
    filter: brightness(1.2);
  }
`;

export const Chart = ({ stats }: Chart) => {
  const [selectedStat, setSelectedStat] = useState("Payouts");

  return (
    <StyledSbs>
      <StyledChart>
        {stats.map(({ value, key, unit }) => (
          <StyledBar
            $value={value}
            $unit={unit}
            $active={selectedStat === key}
            onClick={() => setSelectedStat(key)}
          />
        ))}
      </StyledChart>
      <StyledFlexbox $gap="35px">
        {stats.map(({ key }) => (
          <StyledCompanyStat
            $active={selectedStat === key}
            onClick={() => setSelectedStat(key)}
          >
            {key}
          </StyledCompanyStat>
        ))}
      </StyledFlexbox>
    </StyledSbs>
  );
};

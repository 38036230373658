import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledImgAnchor } from "styles/atoms/buttons/IconButtons.styled";

import { CtaInlineText } from "components/atoms/CtaElements";
import { learnContents, exploreContents } from "js/navContents";

type PageLinks = {
  heading: string;
  content: [string, string][];
};

const PageLinks = ({ heading, content }: PageLinks) => {
  return (
    <StyledFlexbox $gap="5px">
      <p style={{ color: "white" }}>{heading}</p>
      {content.map(([name, to]) => (
        <CtaInlineText $hover="highlight" $col="var(--defaultTint)" to={to}>
          {name}
        </CtaInlineText>
      ))}
    </StyledFlexbox>
  );
};

const Footer = () => {
  return (
    <StyledFlexbox
      $row
      $align="end"
      $justify="space-between"
      style={{
        position: "relative",
        padding: "40px 3%",
        backgroundColor: "var(--bgAlt)",
      }}
    >
      <StyledFlexbox $gap="40px">
        <StyledFlexbox $row $gap="95px">
          <PageLinks
            heading="Explore"
            content={Object.entries(exploreContents[""]).map(
              ([name, [to, desc]]) => {
                return [name, to];
              }
            )}
          />
          <PageLinks
            heading="Learn"
            content={Object.entries(learnContents[""]).map(
              ([name, [to, desc]]) => {
                return [name, to];
              }
            )}
          />
        </StyledFlexbox>
        <StyledFlexbox $gap="5px">
          <CtaInlineText
            $hover="highlight"
            $col="var(--defaultTint)"
            to="/contact"
          >
            contact@pushxpull.co
          </CtaInlineText>
          <p style={{ color: "var(--defaultTintDark" }}>
            © 2023-2025 PUSHXPULL. All Right Reserved
          </p>
        </StyledFlexbox>
      </StyledFlexbox>

      <StyledFlexbox $align="center" $gap="20px">
        <img src="/images/brand/logo-alt.png" style={{ height: "50px" }} />
        <StyledFlexbox $row $gap="8px">
          <StyledImgAnchor
            $src="/images/twitter-logo.png"
            href="https://twitter.com/pushxpull"
            target="_blank"
          />
          <StyledImgAnchor
            $src="/images/instagram-logo.png"
            href="https://www.instagram.com/pushxpull.co/"
            target="_blank"
          />
          <StyledImgAnchor
            $src="/images/tiktok-logo.png"
            href="https://www.tiktok.com/@pushxpull"
            target="_blank"
          />
        </StyledFlexbox>
      </StyledFlexbox>
    </StyledFlexbox>
  );
};

export default Footer;

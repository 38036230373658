import { useState } from "react";

import { StyledFormSelect } from "../styles/InputElements.styled";

const FormSelect = ({
  optionsList,
  selection,
  setSelection,
  placeholder,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledFormSelect
      $expanded={expanded}
      $selection={selection}
      onClick={() => setExpanded(!expanded)}
      {...props}
    >
      <div className="heading">
        <p style={{ color: selection ? "white" : "" }}>
          {selection || placeholder}
        </p>
        <img src="/images/common/simple-arrow-active.png" />
      </div>

      {expanded && (
        <div className="list">
          {/* <div> */}
          {optionsList.map((value, index) => (
            <option
              key={index}
              value={value}
              onClick={() => {
                setExpanded(false);
                if (selection === value) {
                  setSelection("");
                  return;
                }
                setSelection(value);
              }}
              style={{
                color: selection === value ? "var(--defaultTint)" : "",
              }}
            >
              {value}
            </option>
          ))}
          {/* </div> */}
        </div>
      )}
    </StyledFormSelect>
  );
};

export default FormSelect;

import React, { useState } from "react";

import { StyledOverlay, GlobalScrollOverride } from "./Overlay.styled";
import { StyledSelectScreen } from "_components/molecules/containers/overlays/styles/SelectScreen.styled";

import { StyledFormTitle } from "_components/features/form/styles/FormStructs.styled";
import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";

const ConfirmSelectScreen = ({
  $width,
  noScrollLock,
  setIsActive,
  info,
  onConfirmed,
  children,
}) => {
  const [confirmed, setConfirmed] = useState(false);

  const handleExitClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) setIsActive(false);
  };

  const handleConfirmClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setConfirmed(true);
      onConfirmed();
    }
  };

  return (
    <StyledOverlay $centered onClick={handleExitClick}>
      {!noScrollLock && <GlobalScrollOverride />}
      <StyledSelectScreen $width={$width}>
        {confirmed ? (
          <>
            <StyledFormTitle
              $noMinHeight
              $width="70%"
              $bottom="0"
              style={{ color: "var(--action-primary)", fontSize: "2rem" }}
            >
              {info.confirmed.title}
            </StyledFormTitle>
            {info.confirmed.description}
            <div className="centered-container">
              <ThinBtn
                onClick={handleExitClick}
                $primary="var(--action-primary)"
              >
                Ok
              </ThinBtn>
            </div>
          </>
        ) : (
          <>
            <StyledFormTitle
              $noMinHeight
              $width="70%"
              $bottom="0px"
              style={{ color: "var(--action-primary)", fontSize: "2rem" }}
            >
              {info.prompt.title}
            </StyledFormTitle>
            {info.prompt.description}
            {children}
            <div className="space-between">
              {info.confirmed && (
                <ThinBtn
                  onClick={handleConfirmClick}
                  $primary="var(--action-primary)"
                >
                  Confirm
                </ThinBtn>
              )}
              <ThinBtn
                onClick={handleExitClick}
                $primary="var(--main-secondary)"
              >
                Cancel
              </ThinBtn>
            </div>
          </>
        )}
      </StyledSelectScreen>
    </StyledOverlay>
  );
};

export default ConfirmSelectScreen;

import styled, { css } from "styled-components";

export const StyledSwitchFlip = styled.div<{
  $enabled?: boolean;
  $text?: string;
}>`
  --radius: 20px;
  --border: 4px;

  border: solid var(--secondary) 1px;
  border-radius: var(--border);
  padding: 7px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;

  &:hover {
    ${(props) =>
      props.$enabled
        ? css`
            &::before {
              opacity: 0.8;
            }
          `
        : css`
            &::before {
              opacity: 0.2;
            }
          `}
  }

  ${(props) =>
    !props.$enabled &&
    css`
      &::before {
        opacity: 0;
      }
    `}

  &::before {
    content: "";
    position: absolute;
    display: block;
    background-color: var(--secondary);
    position: relative;
    width: var(--radius);
    height: var(--radius);
    border-radius: var(--border);
    transition: opacity 0.15s ease-in-out;
  }
`;

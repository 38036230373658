import { useState } from "react";

import { StyledDashboard } from "styles/molecules/Dashboard.styled";
import {
  DashboardStat,
  DashboardSection,
} from "_components/molecules/Dashboard";

import Alerts from "_components/molecules/Alerts";
import TeamMember from "_components/themes/dashboard/TeamMember";
import ActiveProduct, {
  type Product,
} from "_components/themes/dashboard/ActiveProduct";

const Dashboard = () => {
  const [overlayActive, setOverlayActive] = useState<Product | null>(null);

  if (overlayActive) {
    return (
      <Alerts
        productId={overlayActive.productId}
        productTitle={overlayActive.productTitle}
        closeHandler={() => setOverlayActive(null)}
      />
    );
  }

  return (
    <StyledDashboard>
      <DashboardSection>
        <DashboardStat title="Total Game Ownership" value="5%" />
        <DashboardStat title="Total Game Ownership" value="5%" />
        <DashboardStat title="Total Game Ownership" value="5%" />
      </DashboardSection>

      <DashboardSection title="Your Team" note="0 of 3 available">
        <TeamMember username="Molly" />
        <TeamMember username="Molly" />
        <TeamMember username="Molly" />
      </DashboardSection>

      <DashboardSection
        title="Your Team"
        note="0 of 3 available"
        onRefresh={() => console.log("Hello world!")}
      >
        <ActiveProduct
          productId="1"
          productTitle="Man in the attic"
          productType="Video Edit"
          staffUsername="Molly"
          etaDays={2}
          notifications={[
            { message: "Fulfilled (Cancelled)" },
            { message: "Extension Requested", important: true },
          ]}
          setOverlayActive={setOverlayActive}
        />
        <ActiveProduct
          productId="1"
          productTitle="Man in the attic"
          productType="Video Edit"
          staffUsername="Molly"
          etaDays={2}
          notifications={[
            { message: "Request Activated", important: false },
            { message: "Fulfilled (Cancelled)" },
          ]}
          setOverlayActive={setOverlayActive}
        />
      </DashboardSection>
    </StyledDashboard>
  );
};

export default Dashboard;

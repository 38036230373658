import styled from "styled-components";

import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledImgFade } from "styles/atoms/FadedImg.styled";

interface StyledImgStrip {
  src: string;
  $pos?: string;
  $opacity?: string;
}

interface StyledTopBanner {
  $height?: string;
}

type TopBanner = {
  $pos?: string;
  src: string;
  children: any[];
};

export const StyledImgStrip = styled.div<StyledImgStrip>`
  position: relative;

  height: 100%;
  width: 100%;

  background-color: var(--main-background);

  &::after {
    content: "";
    position: absolute;

    background-image: url(${(props) => props.src});
    background-repeat: repeat-x;
    background-clip: content-box;
    background-position: ${(props) =>
      props.$pos ? "50% " + props.$pos : "50% 0"};

    opacity: ${(props) => props.$opacity || 0.4};
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 1370px) {
    background-size: cover;
  }
`;

export const StyledTopBanner = styled.div<StyledTopBanner>`
  position: relative;
  height: ${(props) => props.$height || "320px"};
  width: 100%;
`;

const StyledCenteredHeader = styled(StyledFlexbox)`
  position: absolute;
  bottom: 30px;
  width: 100%;
`;

const TopBanner = ({ $pos, src, children }: TopBanner) => {
  return (
    <StyledTopBanner>
      <StyledImgStrip $pos={$pos} src={src} />
      <StyledImgFade
        $color="rgb(0,0,0,0.85)"
        $position="bottom"
        $direction="to top"
        $height="50%"
      />
      <StyledCenteredHeader $align="center" $text="center">
        {children}
      </StyledCenteredHeader>
    </StyledTopBanner>
  );
};

export default TopBanner;

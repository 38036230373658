import axios from "axios";
import { useState, useRef, useContext, useEffect } from "react";

import { StyledProgressRing } from "styles/atoms/ProgressRing.styled";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

import { DownloadContext } from "components/function/contexts/DownloadContext";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const DownloadBtn = ({ fileId, title }) => {
  const hasLoaded = useRef(false);
  const downloadBtnRef = useRef(null);
  const [fileSize, setFileSize] = useState("Loading...");
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [
    [pendingDownloads, setPendingDownloads],
    [activeDownloads, setActiveDownloads],
  ] = useContext(DownloadContext);

  const downloadUrl = `${ENDPOINT}/api/drive/download/${fileId}`;

  function downloadByUrl(url) {
    downloadBtnRef.current.href = url;
    downloadBtnRef.current.download = title;
    downloadBtnRef.current.click();

    URL.revokeObjectURL(downloadBtnRef.current.href);
    downloadBtnRef.current.href = "";
    downloadBtnRef.current.download = "";
  }

  function resetDownloadState() {
    setIsDownloading(false);
    setDownloadProgress(0);
    setActiveDownloads(
      activeDownloads.filter((thisFileId) => thisFileId !== fileId)
    );
  }

  async function fetchFileSize() {
    axios
      .head(downloadUrl, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((response) => parseInt(response.headers["content-length"], 10))
      .then((size) => {
        // Converts bytes to GB
        let fileSize = size / 1000000000;
        let unit = "GB";
        if (fileSize < 1) {
          // Converts GB to MB
          fileSize = fileSize * 1000;
          unit = "MB";
        }
        fileSize = fileSize.toFixed(2);
        setFileSize(fileSize + unit);
      })
      .catch((error) =>
        console.error("Error while fetching file size:", error)
      );
  }

  async function handleFileDownload() {
    const pendingDownload = pendingDownloads.find(
      ([thisFileId, _blob]) => thisFileId === fileId
    );
    if (isDownloading || pendingDownload) {
      return;
    }
    setIsDownloading(true);
    setActiveDownloads([...activeDownloads, fileId]);
    setDownloadProgress(-1);

    try {
      const accessToken = await getAccessToken();
      const fileDownloadData = await axios.get(downloadUrl, {
        headers: {
          Authorization: accessToken,
        },

        responseType: "blob", // Set the response type to 'blob'
        onDownloadProgress: function (progressEvent) {
          const loaded = progressEvent.loaded;
          const total = progressEvent.lengthComputable
            ? progressEvent.total
            : fileSize;

          // Calculate and display download progress based on loaded and total
          const progressPercentage = Math.ceil((loaded / total) * 100);
          setDownloadProgress(progressPercentage);

          if (progressPercentage === 100) {
            setDownloadProgress(0);
          }
        },
      });

      const blob = fileDownloadData.data;

      const downloadUrl = URL.createObjectURL(blob);
      if (!downloadBtnRef.current) {
        hasLoaded.current = false;
        setPendingDownloads([...pendingDownloads, [fileId, blob]]);
        setIsDownloading(false);
        return;
      }

      downloadBtnRef.current.href = downloadUrl;
      downloadBtnRef.current.download = title;

      resetDownloadState();

      downloadBtnRef.current.setAttribute("target", "_blank");
      downloadBtnRef.current.click();
      URL.revokeObjectURL(downloadBtnRef.current.href);
      downloadBtnRef.current.href = "";
      downloadBtnRef.current.download = "";
    } catch (error) {
      console.error("Download Failed! Error", error);
      resetDownloadState();
    }
  }

  function completePendingDownloads() {
    if (pendingDownloads.length === 0 || hasLoaded.current === true) {
      return;
    }

    const pendingDownload = pendingDownloads.find(
      ([thisFileId, _blob]) => thisFileId === fileId
    );
    if (!pendingDownload) {
      return;
    }

    const downloadUrl = URL.createObjectURL(pendingDownload[1]);
    // Only run once
    hasLoaded.current = true;
    setDownloadProgress(-1);
    setIsDownloading(true);
    downloadByUrl(downloadUrl);

    setTimeout(() => {
      resetDownloadState();
      setPendingDownloads(
        pendingDownloads.filter(([thisFileId]) => thisFileId !== fileId)
      );
    }, 1000);
  }

  function loadUnfinishedDownloadProgress() {
    const activeDownload = activeDownloads.find(
      (thisFileId) => thisFileId === fileId
    );
    if (!activeDownload) return;

    // Indicator for style to change to spinning circle, as we can't get progress
    setIsDownloading(true);
    setDownloadProgress(-1);
  }

  useEffect(() => {
    fetchFileSize();
  }, []);
  useEffect(loadUnfinishedDownloadProgress, [activeDownloads]);
  useEffect(completePendingDownloads, [pendingDownloads, isDownloading]);

  if (isDownloading)
    return (
      <StyledProgressRing
        $alpha={downloadProgress}
        $bg="var(--bgHighlightGradientLast)"
      />
    );

  return (
    <HoverFlagBtn
      $variant="Small"
      title="Download"
      src="/images/buttons/download.png"
      ref={downloadBtnRef}
      onClick={handleFileDownload}
      style={{ position: "relative" }}
    >
      <a
        ref={downloadBtnRef}
        rel="noopener"
        target="_blank"
        style={{ position: "absolute", height: "100%", width: "100%" }}
      />
      {fileSize}
    </HoverFlagBtn>
  );
};

export default DownloadBtn;

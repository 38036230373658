import styled, { css } from "styled-components";

interface LabelledIconProps {
  $icon: string;
  $col?: "active" | "inactive" | "static";
  $flipped?: boolean;
}

export const StyledLabelledIcon = styled.div<LabelledIconProps>`
  --size: 20px;

  position: relative;
  display: flex;
  align-items: center;

  gap: 14px;
  padding-left: 20px;

  color: ${(props) =>
    props.$col === "active"
      ? "var(--primaryDim)"
      : props.$col === "static"
      ? "var(--defaultTint)"
      : "var(--iconInactive)"};

  // Icon
  &::after,
  &::before {
    content: "";
    display: block;
    height: var(--size);
    width: var(--size);
    background: ${(props) => `url(${props.$icon})`} no-repeat center/contain;
  }

  ${(props) =>
    props.$flipped
      ? css`
          &::after {
            content: unset;
          }
        `
      : css`
          &::before {
            content: unset;
          }
        `}
`;

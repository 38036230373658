import styled from "styled-components";

import * as Constraint from "styles/util/Constraint.styled";

type ConstraintType = "parent" | "child";

function constraint(
  Component: React.ComponentType<any>,
  type?: ConstraintType
) {
  return styled(Component)`
    ${type === "child"
      ? Constraint.ChildSizeConstraintStyle
      : Constraint.ParentSizeConstraintStyle}
  `;
}

export default constraint;

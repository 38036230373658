import styled from "styled-components";

interface CompareContainerProps {
  $long?: string;
}

export const StyledCompareContainer = styled.div<CompareContainerProps>`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${(props) => (props.$long ? "250px" : "50px")};
`;

import styled, { css } from "styled-components";
import { NavLink as Link } from "react-router-dom";

import { ScrollElement } from "components/atoms/SpanElements";

const NavElement = css`
  display: flex;
  color: white;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  user-select: none;

  justify-content: center;
`;

const Hoverable = css`
  position: relative;
  transition: color 0.2s, transform 0.2s;

  &::after {
    content: "";
    width: 100%;
    height: 100%;

    display: block;
    position: absolute;

    height: 1px;
    width: 0;
    bottom: 20px;
    background-color: #9278ff;
    transition: width 0.2s;
  }

  &:hover::after {
    width: 40px;
  }

  &:hover {
    color: #9278ff;
  }
`;

export const NavScroll = styled(ScrollElement)`
  ${Hoverable};
  ${NavElement};
`;

export const NavLink = styled(Link)`
  ${Hoverable};
  ${NavElement};
`;

const StyledNavLogo = styled(Link)`
  ${NavElement};

  display: flex;
  align-items: center;
  justify-content: start;
  gap: 18px;

  & > p {
    ${NavElement};
    font-family: "Segoe UI";
    font-size: 20px;
    font-weight: 400;
  }

  & > img {
    height: 25px;
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const NavLogo = () => {
  return (
    <StyledNavLogo to="/">
      <img src="/images/brand/logo-alt.png" />
      <p>PUSHXPULL</p>
    </StyledNavLogo>
  );
};

export const StyledNavMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    & > div {
      padding: 0 30px;
    }
  }
`;

export const StyledNav = styled.nav`
  padding: 0% 8%;

  height: 80px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.2rem calc((100vw - 1000px) / 2); */
  z-index: 12;

  @media screen and (max-width: 700px) {
    justify-content: center;
    padding: 0;
  }
`;

export const StyledNavExpand = styled.div`
  ${NavElement};
  cursor: auto;
  position: relative;
  transition: all 0.05s ease-in-out;
  // Offset for dropdown
  padding: 25px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  filter: drop-shadow(5px 5px 5px rgb(0, 0, 0, 0.3));

  &::after {
    content: "";
    width: 15px;
    height: 10px;
    position: relative;

    background: url("/images/common/simple-arrow.png");
    background-position: 75% 100%;
    background-size: contain;
    background-repeat: no-repeat;

    transition: rotate 0.1s ease-in-out;
    rotate: 90deg;
  }

  .container {
    bottom: 0;
    position: absolute;
    display: block;
    pointer-events: none;

    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  .triangle {
    top: calc(100% - 16px);
    background-color: var(--bgOutline);
    position: absolute;
    display: block;
    height: 16px;
    width: 18px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
  }

  .box {
    /* Not prefixed with $, as we need to global to be accessed up multiple layers of inheritance */
    transform: ${(props) =>
      props.$originAt && `translateX(${props.$originAt})`};

    top: 100%;
    background-image: linear-gradient(
      to bottom,
      var(--bgHighlightGradientFirst),
      var(--bgHighlightGradientLast)
    );
    position: absolute;

    display: flex;
    align-items: start;
    justify-content: start;

    border-radius: 10px;
    gap: 25px;
    padding: 25px;
    padding-top: 15px;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  &:hover {
    color: var(--secondaryDim);

    &::after {
      rotate: -90deg;
    }

    .container {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const StyledExpandCollection = styled.div`
  --collection-width: 221px;
  --icon-size: 21px;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  width: var(--collection-width);
  gap: 15px;
`;

export const StyledExpandHeading = styled.p`
  position: relative;
  display: block;
  font-size: 16px;
  margin: 0 0 10px calc(var(--icon-size) + 10px);
  color: var(--secondaryDim);

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: var(--secondaryDim);
    bottom: -3px;
    height: 0.5px;
    width: 100%;
  }
`;

export const StyledExpandElement = styled.div`
  display: flex;
  gap: 10px;

  cursor: ${(props) => (props.$isActive ? "auto" : "pointer")};
  z-index: 2;

  & > img {
    margin-top: 2px;
    height: var(--icon-size);
  }

  .heading {
    line-height: 21px;
    transition: all 0.05s ease-in-out;
    color: ${(props) => (props.$isActive ? "var(--secondaryDim)" : "white")};
  }

  .item {
    font-size: 12px;
    line-height: 16px;
    color: var(--defaultDim);
  }

  &:hover {
    .heading {
      color: var(--secondaryDim);
    }
  }
`;

import styled from "styled-components";

export const StyledFormPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 150px 20px 300px 20px;
  gap: 130px;
  width: 100%;
`;

export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 70px;
  width: min(800px, 100%);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: 100%;
  }
`;

import { useState } from "react";

import { StyledSpacer } from "styles/util/Spacer.styled";

import TopBanner from "_components/molecules/structure/TopBanner";
import PartnerPlans from "_components/features/checkout/PartnerPlans";
import Header from "_components/molecules/structure/Header";

import SwitchFlip from "components/atoms/buttons/SwitchFlip";
import { LoneCtaSection } from "components/atoms/CtaElements";

const LearnPricing = () => {
  const [isRbxYoutuber, setIsRbxYoutuber] = useState(true);

  return (
    <section>
      <TopBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h2>Pricing</h2>
        <p style={{ maxWidth: "500px" }}>
          View the tiered and alternate package options, differing in pricing
          and rewards
        </p>
      </TopBanner>

      <StyledSpacer $pad="150px" />
      <Header
        $align="start"
        title="Partner Plans"
        description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam"
      >
        <StyledSpacer $pad="44px" />
        <SwitchFlip enabled={isRbxYoutuber} setEnabled={setIsRbxYoutuber}>
          Roblox Youtuber
        </SwitchFlip>
      </Header>

      <StyledSpacer $pad="70px" />
      <PartnerPlans
        isRbxYoutuber={isRbxYoutuber}
        setIsRbxYoutuber={setIsRbxYoutuber}
      />
      <StyledSpacer />

      <LoneCtaSection to="/learn/game" actionText="Discover">
        <h2>Collect Cash-Backs</h2>
        <p>
          Discover how you can earn all of your money back and even make a
          profit through our games. While also having your channel promoted.
        </p>
      </LoneCtaSection>
      <StyledSpacer />
    </section>
  );
};

export default LearnPricing;

import {
  StyledWrappedImg,
  StyledImgFade,
  type WrappedImgProps,
} from "styles/atoms/FadedImg.styled";

export interface FadedImgProps extends WrappedImgProps {
  src: string;
}

const FadedImg = ({
  src = "/images/astral-gfx.jpg",
  ...props
}: FadedImgProps) => {
  return (
    <StyledWrappedImg src={src} {...props}>
      <StyledImgFade
        $color="rgb(0,0,0,0.2)"
        $position="top"
        $direction="to bottom"
        $height="10%"
      />
      <StyledImgFade
        $color="rgb(0,0,0,0.85)"
        $position="bottom"
        $direction="to top"
        $height="50%"
      />
    </StyledWrappedImg>
  );
};

export default FadedImg;

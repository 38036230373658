import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { StyledFlexbox, StyledPairDisplay } from "styles/util/Flex.styled";

import { StyledThinBtn } from "_components/atoms/buttons/styles/ThinButtons.styled";
import {
  StyledHighlightedSpan,
  StyledLinkSpan,
} from "styles/atoms/SpanElements.styled";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";
import { formatDate } from "js/util/formatters";

const defaultSpacing = "20px";

const StyledStaffPayment = styled.div`
  border: dashed 1px ${(props) => props.$col};
  padding: 40px;
  width: 780px;
`;

const StaffPayment = ({ requestId, ...props }) => {
  const [apiBusy, setApiBusy] = useState(false);
  const [data, setData] = useState({});

  const isPaid = data.paidOn != undefined;
  const isFulfilled = data.fulfilledOn != undefined;
  const isAffiliate = data.affiliate != undefined;

  const primaryColor = isPaid ? "var(--sub-info-alt)" : "var(--action-primary)";
  const fulfilledOn = isFulfilled && formatDate(data.fulfilledOn);
  const paidOn = isPaid && formatDate(data.paidOn);

  async function loadData() {
    try {
      const response = await axios.get(`${ENDPOINT}/api/payment/product`, {
        params: {
          type: "payment",
          id: requestId,
        },
        headers: {
          Authorization: await getAccessToken(),
        },
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function updatePaidState(state) {
    try {
      const response = await axios.patch(
        `${ENDPOINT}/api/payment/fulfillment/work`,
        {
          id: data.fulfillmentId,
          state: state,
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      );

      // Reload info to update 'paid' status
      loadData();
    } catch (error) {
      console.log(error);
    }
  }

  async function handleRevert() {
    if (!isPaid || apiBusy) return;
    setApiBusy(true);
    await updatePaidState(false);
    setApiBusy(false);
  }

  async function handleMarkAsPaid() {
    if (isPaid || apiBusy) return;
    setApiBusy(true);
    await updatePaidState(true);
    setApiBusy(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <StyledStaffPayment $col={primaryColor} {...props}>
      <StyledFlexbox $wrap>
        <h2
          style={{
            color: primaryColor,
            textDecoration: "underline",
            marginBottom: defaultSpacing,
          }}
        >
          Staff{" "}
          {isAffiliate && (
            <StyledHighlightedSpan $col="var(--contrast-one)">
              & Affiliate{" "}
            </StyledHighlightedSpan>
          )}
          Payment is {isPaid ? "Fulfilled" : "Due"}
        </h2>

        <StyledFlexbox
          $row
          $wrap
          $gap={defaultSpacing}
          style={{ position: "relative" }} // Contains the 'position: absolute' button
        >
          <StyledPairDisplay $primary="var(--sub-info-alt)">
            <p>Staff Username</p>
            <p>{data.username}</p>
          </StyledPairDisplay>

          <StyledPairDisplay $primary="var(--sub-info-alt)">
            <p>Client Sub Count</p>
            <p>{data.subCount}</p>
          </StyledPairDisplay>

          {data.duration != undefined && (
            <StyledPairDisplay $primary="var(--sub-info-alt)">
              <p>Video Duration</p>
              <p>{data.duration} Minutes</p>
            </StyledPairDisplay>
          )}

          <StyledPairDisplay $primary="var(--sub-info-alt)">
            <p>Net Earnings</p>
            <p>${data.netEarnings}</p>
          </StyledPairDisplay>

          {isAffiliate && (
            <StyledPairDisplay $primary="var(--sub-info-alt)">
              <p>Affiliate</p>
              <p>{data.affiliate}</p>
            </StyledPairDisplay>
          )}

          <StyledPairDisplay
            $primary={
              isAffiliate ? "var(--contrast-one)" : "var(--sub-info-alt)"
            }
            $secondary={isAffiliate && "var(--contrast-one)"}
          >
            <p>{isAffiliate ? "Affiliate's Cut" : "Company's Cut"}</p>
            <p>${data.companyCut}</p>
          </StyledPairDisplay>

          <StyledPairDisplay $primary={primaryColor} $secondary={primaryColor}>
            <p>Staff's Cut</p>
            <p>${data.staffCut}</p>
          </StyledPairDisplay>

          {isFulfilled && (
            <StyledPairDisplay $primary="var(--sub-info-alt)">
              <p>Fulfillment #</p>
              <p>{data.fulfillmentId}</p>
            </StyledPairDisplay>
          )}

          {isFulfilled && (
            <StyledPairDisplay $primary="var(--sub-info-alt)">
              <p>Fulfilled On</p>
              <p>{fulfilledOn}</p>
            </StyledPairDisplay>
          )}

          {isPaid && (
            <StyledPairDisplay $primary="var(--sub-info-alt)">
              <p>Paid On</p>
              <p>{paidOn}</p>
            </StyledPairDisplay>
          )}

          <StyledFlexbox
            $align="end"
            style={{ position: "absolute", right: "0", bottom: "0" }}
          >
            {isPaid && (
              <StyledLinkSpan $inverted onClick={handleRevert}>
                {apiBusy ? "Busy.." : "Undo?"}
              </StyledLinkSpan>
            )}
            <StyledThinBtn
              $inactive={isPaid}
              $primary={primaryColor}
              onClick={handleMarkAsPaid}
            >
              {isPaid ? "Paid" : apiBusy ? "Busy..." : "Mark as Paid"}
            </StyledThinBtn>
          </StyledFlexbox>
        </StyledFlexbox>
      </StyledFlexbox>
    </StyledStaffPayment>
  );
};

export default StaffPayment;

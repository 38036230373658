import React from "react";

interface FadableComponent {
  $opacity: string | number;
  $fadeSpeed?: string;
  [key: string]: any;
}

function fadable(Component: React.ComponentType<any>) {
  const FadableComponent = ({
    $opacity,
    $fadeSpeed = "0.15s",
    children,
    style,
    ...props
  }: FadableComponent) => {
    const fadeStyle = {
      opacity: $opacity,
      transition: `opacity ${$fadeSpeed} ease-in-out`,
    };

    return (
      <Component {...props} style={{ ...style, ...fadeStyle }}>
        {children}
      </Component>
    );
  };

  return FadableComponent;
}

export default fadable;

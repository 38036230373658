import {
  StyledSimpleIconBtn,
  StyledTextFadeIcon,
} from "../../../styles/atoms/buttons/IconButtons.styled";

export interface SimpleIconBtnProps extends StyledSimpleIconBtn {
  src: string;
  [key: string]: any;
}

export interface TextFadeIconProps {
  title: string;
  src: string;
}

export const TextFadeIcon = ({ title, src, ...props }: TextFadeIconProps) => {
  return (
    <StyledTextFadeIcon {...props}>
      <img src={src} />
      <h4>{title}</h4>
    </StyledTextFadeIcon>
  );
};

export const SimpleIconBtn = ({ src, ...props }: SimpleIconBtnProps) => {
  return (
    <StyledSimpleIconBtn {...props}>
      <img src={src} />
    </StyledSimpleIconBtn>
  );
};

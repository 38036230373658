import styled, { createGlobalStyle } from "styled-components";

interface StyledOverlay {
  $position?: string;
  $invisible?: boolean;
  $centered?: boolean;
  $padding?: string;
  $overflow?: string;
}

export const GlobalBackground = createGlobalStyle`
  body {
    background: var(--bgShadow) !important;
  }
`;

export const StyledOverlay = styled.div<StyledOverlay>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  padding: 80px min(300px, 15%);
  z-index: 10;

  width: 100%;
  height: 100%;
`;

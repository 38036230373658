import styled from "styled-components";

interface StyledDirectionalBtn {
  $height?: string;
  $rotate?: string;
}

interface DirectionalBtn {
  $height?: string;
  $rotate?: string;
  text: string;
  src?: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const StyledDirectionalBtn = styled.button<StyledDirectionalBtn>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: var(--size);

  margin-bottom: 10px;

  border: none;
  background-color: unset;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(1.2);
  }

  & > img {
    rotate: ${(props) => props.$rotate || "180deg"};
    height: ${(props) => props.$height || "15px"};
  }

  & > p {
    color: var(--iconInactive);
  }
`;

const DirectionalBtn = ({
  $height,
  $rotate,
  text,
  src = "/images/common/arrow-inactive.png",
  onClick,
  ...props
}: DirectionalBtn) => {
  return (
    <StyledDirectionalBtn
      $rotate={$rotate}
      $height={$height}
      onClick={onClick}
      {...props}
    >
      <img src={src} />
      <p>{text}</p>
    </StyledDirectionalBtn>
  );
};

export default DirectionalBtn;

import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";

import {
  StyledUploadingItem,
  StyledProgressBar,
} from "../styles/FileElements.styled";

import ColumnItem from "components/molecules/ColumnItem";

import RemoveBtn from "../buttons/RemoveBtn";
import CancelBtn from "../buttons/CancelBtn";
import ResumeBtn from "../buttons/ResumeBtn";
import PauseBtn from "../buttons/PauseBtn";

import getUploadID from "js/getUploadID";
import { ENDPOINT } from "js/data/constants";

import { UploadContext } from "components/function/contexts/UploadContext";
import getAccessToken from "js/features/auth/getAccessToken";

const UploadingItem = ({ progressUri, canUpload = true, setCanUpload }) => {
  const [
    [progressUris, setProgressUris],
    [fileDatas, setFileDatas],
    [completedUploads, setCompletedUploads],
  ] = useContext(UploadContext);

  const [isUploading, setIsUploading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(100);
  const initialLoad = useRef(false);

  const nameSuffix =
    fileDatas[progressUri] != undefined &&
    parseInt(fileDatas[progressUri].name.length) > 20
      ? "..."
      : "";
  const videoName =
    fileDatas[progressUri] != undefined
      ? fileDatas[progressUri].name.slice(0, 20) + nameSuffix
      : "Untitled".slice(0, 20) + nameSuffix;

  const progressUpdator = async () => {
    if (uploadProgress === 100) {
      // Upload finished
      return;
    }

    const uploadID = getUploadID(progressUri);
    const res = await axios.get(`${ENDPOINT}/api/drive/progress/${uploadID}`, {
      withCredentials: true,
      headers: {
        Authorization: await getAccessToken(),
      },
    });

    const progress = res.data.uploadProgress;
    if (progress && typeof progress === "number") {
      // Ensures state updates every time we reach this block of code, keeping the recursive looping going until completion
      setUploadProgress(uploadProgress === progress ? progress + 1 : progress);
      return { progress: progress || 0, paused: res.data.paused };
    }
  };

  const loadInitialProgress = async () => {
    // Retry load until progress exists on server and isn't 0 (so state change can update for useEffect)
    const data = await progressUpdator();

    if (!data || data.progress == null || data.progress == 0) {
      setTimeout(loadInitialProgress, 1000);
    } else {
      if (initialLoad.current === false) {
        // Debounce/cooldown for other uploaders to wait on this to start to fix upload overlapping bug
        setCanUpload && setCanUpload(true);
      }
      initialLoad.current = true;
      if (data.paused != null) {
        setIsPaused(data.paused);
      }
    }
  };

  const observeProgress = () => {
    if (uploadProgress === 100) {
      setCompletedUploads([...completedUploads, progressUri]);
    }

    if (!isUploading) {
      setUploadProgress(0);
      return;
    } else if (isPaused) {
      return;
    }
    // Initial setting of progress when loading in
    if (initialLoad.current === false) {
      loadInitialProgress();
      return;
    }
    setTimeout(progressUpdator, 2500);
  };

  // Update progress at set interval
  // useEffect(observeProgress, [
  //   progressUris,
  //   isUploading,
  //   isPaused,
  //   uploadProgress,
  // ]);

  if (uploadProgress === 100) {
    return (
      <ColumnItem
        title={videoName}
        $progress={uploadProgress}
      >
        <RemoveBtn
          setUploadProgress={setUploadProgress}
          setIsUploading={setIsUploading}
          progressUri={progressUri}
        />
      </ColumnItem>
    );
  }

  if (true) {
    return (
      <ColumnItem title={videoName} $progress={uploadProgress}>
        {isPaused ? (
          <ResumeBtn setIsPaused={setIsPaused} progressUri={progressUri} />
        ) : (
          <PauseBtn setIsPaused={setIsPaused} progressUri={progressUri} />
        )}
        <CancelBtn
          setUploadProgress={setUploadProgress}
          setIsUploading={setIsUploading}
          progressUri={progressUri}
        />
      </ColumnItem>
    );
  }

  return (
    <StyledUploadingItem $padSize="small">
      <StyledProgressBar $progress={uploadProgress}></StyledProgressBar>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p className="progress-indicator">{uploadProgress}% Complete</p>
        {isPaused ? (
          <h3>{`Paused: ${videoName}`}</h3>
        ) : (
          <h3>{`Uploading: ${videoName}`}</h3>
        )}
      </div>
      {canUpload && (
        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          {isPaused ? (
            <ResumeBtn setIsPaused={setIsPaused} progressUri={progressUri} />
          ) : (
            <PauseBtn setIsPaused={setIsPaused} progressUri={progressUri} />
          )}
          <CancelBtn
            setUploadProgress={setUploadProgress}
            setIsUploading={setIsUploading}
            progressUri={progressUri}
          />
        </div>
      )}
    </StyledUploadingItem>
  );
};

export default UploadingItem;

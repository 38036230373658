import styled, { css } from "styled-components";

import { StyledGrid } from "styles/util/Flex.styled";

interface StyledDashboardBlock extends StyledGrid {
  children: any[];
  $bg?: string;
  $width?: string;
  $column?: boolean;
  $radius?: string;
  $pad?: string;
  $highlighted?: boolean;
  $highlightCol?: string;
}

export const StyledDashboardBlock = styled(StyledGrid)<StyledDashboardBlock>`
  background: ${(props) => props.$bg || "var(--bgLightHighlight)"};
  border-radius: ${(props) => props.$radius || "5px"};
  padding: ${(props) => props.$pad || "16px"};

  display: grid;
  grid-template-columns: repeat(${(props) => props.$cols || "2"}, 1fr);
  grid-template-rows: repeat(${(props) => props.$rows || "2"}, 1fr);

  width: ${(props) => props.$width};

  & > *:first-child {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: start;
    align-self: start;
  }

  // Overridden then (n=2)
  & > *:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: start;
    align-self: end;
  }

  // Either 2nd (n=2) or 4th (n=4) child (use > 1 child in this block)
  & > *:last-child {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-self: end;
    align-self: start;
  }

  & > *:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: end;
    align-self: end;
  }

  ${(props) =>
    props.$highlighted &&
    css`
      background-color: ${props.$highlightCol || "var(--error)"};

      * {
        color: black !important;
      }
    `}
`;

import styled from "styled-components";

export const StyledTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: #242037;
  border: 1px solid #433e5d;
  border-radius: 20px;

  height: 35px;
  padding: 5px 15px;
`;

export const StyledFadingTag = styled(StyledTag)`
  background-color: unset;
  background-image: ${(props) => `linear-gradient(
    to ${props.$fadeTo},
    rgb(36, 32, 55, 1),
    rgb(36, 32, 55, 0.33),
    rgb(36, 32, 55, 0)
  )`};
  border: unset;
  flex-grow: ${(props) => props.$grow};
  width: ${(props) => props.$width};
`;

import styled from "styled-components";

export interface ProgressRingProps {
  $alpha?: number;
  $bg?: string;
}

export const StyledProgressRing = styled.div<ProgressRingProps>`
  --thickness: 50%;

  position: relative;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background: ${({ $alpha }) =>
    $alpha != undefined
      ? $alpha >= 0 && $alpha < 0.99
        ? `conic-gradient(var(--actionPrimary) ${
            $alpha * 360
          }deg, var(--bgMain) -${(1 - $alpha) * 360}deg)`
        : "var(--error)"
      : ""};

  &::after {
    content: "";
    display: block;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: calc(100% - var(--thickness));
    width: calc(100% - var(--thickness));
    border-radius: 50%;
    background: ${(props) => props.$bg || "var(--bgMain)"};
  }
`;

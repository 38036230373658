import { useRef, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { StyledInputContainer } from "features/form/FormContainers.styled";
import { StyledFormPage } from "features/form/Form.styled";

import Form from "features/form/Form";
import FormItem from "_components/features/form/FormItem";

import { AccountContext } from "components/function/contexts/AccountContext";

import { loginUser } from "js/features/session/userSession";
import { getErrorMessage } from "js/data/formErrorMessages";

function Login() {
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [errorMessages, setErrorMessages] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);
    loginUser(formRef, navigate)
      .then(() => {
        setLoggedIn(true);
      })
      .catch((error) => {
        setErrorMessages([getErrorMessage(error, navigate, pathname)]);
      });
  };

  return (
    <StyledFormPage>
      <Form
        ref={formRef}
        title="Log in"
        description="Manage your dashboard and see your rewards"
        actionText="Enter"
        errorMessages={errorMessages}
        onSubmit={handleSubmit}
      >
        <StyledInputContainer $locked>
          <FormItem
            $title="PUSHXPULL Username or Email"
            placeholder="Enter your PUSHXPULL username or email"
            name="username"
            required
            autoFocus
          />
          <FormItem
            $title="PUSHXPULL Password"
            placeholder="Enter your PUSHXPULL password"
            name="password"
            type="password"
            required
          />
        </StyledInputContainer>
      </Form>
    </StyledFormPage>
  );
}

export default Login;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	:root {
		color-scheme: dark;
	}

	html {
		--action-primary: #00b1ff;
		--action-primary-alt: #066AFF;
		--action-primary-dimmed: #12235A;

		--action-secondary: #6C38FE;
		--action-secondary-alt: #9278ff;

		--main-primary: #3232B4;
		--main-primary-alt: #0A339E;

		--main-secondary: #11111D;
		--main-secondary-dark: #0D0D16;
		--main-secondary-alt: #28283B;
		--main-background: #181828;

		--sub-info-light: #C9C9C9;
		--sub-info-dark: #A2A2A2;
		--sub-info-alt: #8989B1;
		--dark-text: #6b6b6b;

		--contrast-one: #FF00AA;
		--contrast-one-dark: #b53389;
		--contrast-two: #AB00FF;
		--contrast-important: #FF0000;

		--exit-main: #FB5E5E;

		--active-default: #00FFA6;
		--inactive-default: #D6D6D6;
		/* --wrap-maxwidth: 1370px; */



		// Text colours
		--error: #B13276;

		--defaultTintDark: #5E6573;
		--defaultTint: #909AB1;
		--defaultDark: #7E8094;
		--defaultDim: #A4A6B5;
		--default: #fff;

		--inactive: #343966;

		--secondaryDark: #636694;
		--secondaryDim: #5D6492;
		--secondary: #7E87C1;

		--primaryDim: #4E4FB4;
		--primary: #6B6EFF;
		--icon: #4E4FB4;
		--iconInactive: #494E73;


		// SVG colors
		--svgInactive: #31344D;

		// Colours
		--actionPrimary: #3F3FC2;
		--actionPrimaryAlt: #6741DA;
		--actionSecondary: #3F57B4;
		--actionPrimaryDark: #111131;

		--bgDarkAccent: #14162A;
		--bgTintShadow: #121529;
		--bgDarkHighlight: #161A2E;
		--bgMediumHighlight: #191D31;
		--bgLightHighlight: #1E2238;
		--bgDarkShadow: #111326;
		--bgLightShadow: #171a2d;
		--bgShadow: #131626;
		--bgOutline: #272B4D;
		--bgMain: #0C0E1E;
		--bgLight: #1C1E2D;
		--bgAlt: #0E111D;
		--bgShadowDarker: #0D0F1A;

		--bgLightGradientFirst: #1A1D33;
		--bgLightGradientLast:  #131626;
		--bgHighlightGradientFirst: #171B30;
		--bgHighlightGradientLast: #15192D;

		--actionPrimaryGradientFirst: #3F57B4;
		--actionPrimaryGradientMiddle: #5C40CF;
		--actionPrimaryGradientLast: #6741DA;
	}

	body {
		background-color: var(--bgMain);
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		overflow-x: hidden;
	}

	h1 {

	}

	h2 {
		font-size: 40px;
		font-weight: 400;
	}

	h3 {
		font-size: 24px;
		font-weight: 400;
	}

	// Icon
	h4 {
		font-weight: 350;
		font-size: 12px;
	}

	p {
		color: var(--defaultDim);
		font-size: 16px;
	}

	@media only screen and (max-width: 500px) {
		h1 {
		}

		h2 {
		}

		h3 {
		}

		h4 {
		}

		p {

		}
	}

	@media only screen and (min-width: 500px) and (max-width: 700px) {
		h1 {
		}

		h2 {
		}

		h4 {
		}

		p {
		}
	}

	// Global reset!

	/* Box sizing rules */
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	/* Remove default padding */
	ul[class],
	ol[class] {
		padding: 0;
	}

	/* Remove default margin */
	body,
	h1,
	h2,
	h3,
	h4,
	p,
	ul[class],
	ol[class],
	li,
	figure,
	figcaption,
	blockquote,
	dl,
	dd {
		margin: 0;
	}

	/* Set core body defaults */
	body {
		min-height: 100vh;
		scroll-behavior: smooth;
		text-rendering: optimizeSpeed;
		line-height: 1.5;
	}

	/* Remove list styles on ul, ol elements with a class attribute */
	ul[class],
	ol[class] {
		list-style: none;
	}

	/* A elements that don't have a class get default styles */
	a:not([class]) {
		text-decoration-skip-ink: auto;
	}

	/* Make images easier to work with */
	img {
		max-width: 100%;
		display: block;
	}

	/* Natural flow and rhythm in articles by default */
	article > * + * {
		margin-top: 1em;
	}

	/* Inherit fonts for inputs and buttons */
	input,
	button,
	textarea,
	select {
		font: inherit;
	}

	/* Remove all animations and transitions for people that prefer not to see them */
	@media (prefers-reduced-motion: reduce) {
		* {
			animation-duration: 0.01ms !important;
			animation-iteration-count: 1 !important;
			transition-duration: 0.01ms !important;
			scroll-behavior: auto !important;
		}
	}
`;

export default GlobalStyle;

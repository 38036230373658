import styled from "styled-components";

export interface WrappedImgProps {
  $round?: string;
  $height?: string;
  $width?: string;
  src: string;
}

export interface ImgFadeProps {
  $color?: string;
  $direction?: string;
  $height?: string;
  $position?: "bottom" | "top";
}

export const StyledWrappedImg = styled.div<WrappedImgProps>`
  position: relative;

  height: ${(props) => props.$height || "100%"};
  width: ${(props) => props.$width || "100%"};
  overflow: hidden;
  border-radius: ${(props) => props.$round};
  filter: drop-shadow(12px 12px 12px rgb(0, 0, 0, 0.16));

  background-image: ${(props) => `url("${props.src}")`};
  background-size: cover;
  background-position: 50%;
`;

export const StyledImgFade = styled.div<ImgFadeProps>`
  position: absolute;

  top: ${(props) => (props.$position === "top" ? "0" : "unset")};
  bottom: ${(props) => (props.$position === "bottom" ? "0" : "unset")};

  height: ${(props) => props.$height || "50%"};
  width: 100%;

  background-image: linear-gradient(
    ${(props) => props.$direction || "to top"},
    ${(props) => props.$color},
    transparent
  );
`;

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactSession } from "react-client-session";

import {
  StyledScrollContainer,
  StyledContentDisplay,
} from "styles/molecules/ScrollContainer.styled";
import { StyledChatForm } from "../styles/ChatElements.styled";

import ChatBar from "../elements/ChatBar";
// import ChatMessage from "../elements/ChatMessage";
import ChatMessage from "components/atoms/ChatMessage";
import MemberList from "../elements/MemberList";

import LoadMoreBtn from "../buttons/LoadMoreBtn";

const ChatWindow = ({
  client,
  socket,
  selectedUser,
  userState: [users, setUsers],
  messageState: [messages, setMessages],
}) => {
  const username = ReactSession.get("useruid");

  const sentFirstMsg = useRef(false);
  const uiMessagesRef = useRef(null);
  const [messageBody, setMessageBody] = useState("");
  const { search } = useLocation();

  const setupSocketListeners = () => {
    if (!socket) return;

    socket.on("message", (newMessage) => {
      const existUser = users.find((user) => user.name === newMessage.from);

      if (
        newMessage.from === selectedUser.name ||
        (selectedUser.accountType === "Group" &&
          newMessage.to === selectedUser.name)
      ) {
        setMessages((messages) => [...messages, newMessage]);

        if (!existUser) return;
        setUsers(
          users.map((user) =>
            user.name === existUser.name ? { ...user, unread: false } : user
          )
        );
      } else if (existUser) {
        setUsers(
          users.map((user) =>
            user.name === existUser.name ? { ...user, unread: true } : user
          )
        );
      }
    });

    // Absolutely vital, otherwise old references are used and mess up everything
    return () => {
      if (socket) {
        socket.off("message");
      }
    };
  };

  const messageContainerScroll = () => {
    if (uiMessagesRef.current) {
      uiMessagesRef.current.scrollBy({
        top: uiMessagesRef.current.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!messageBody.trim()) {
      return;
    } else {
      const queryParams = new URLSearchParams(search);
      const newMessage = {
        body: messageBody,
        type: selectedUser.accountType,
        from: username,
        to:
          selectedUser.accountType === "Group" && client
            ? client
            : selectedUser.name,
        time: new Date(),
        alias:
          selectedUser.accountType === "Group" && client
            ? selectedUser.name
            : undefined,

        // Only display the 'ReferenceFile' on message on first msg, then continue conversation normally
        referenceFileId:
          sentFirstMsg.current === false && queryParams.get("fileid"),
      };
      console.log(newMessage);
      if (sentFirstMsg.current === false) {
        sentFirstMsg.current = true;
      }
      setMessages((messages) => [...messages, newMessage]);
      socket.emit("onMessage", newMessage);
      setMessageBody("");
    }
  };

  useEffect(setupSocketListeners, [socket, users]);
  useEffect(messageContainerScroll, [selectedUser, messages]);

  return (
    <>
      {/* {selectedUser.members && <MemberList members={selectedUser.members} />} */}
      <StyledScrollContainer ref={uiMessagesRef} className="chat">
        <StyledContentDisplay $align="start">
          <LoadMoreBtn
            user1={client}
            username={username}
            selectedUser={selectedUser}
            messages={messages}
            setMessages={setMessages}
          />

          {messages.map((msg, index) => (
            <ChatMessage
              key={index}
              username={msg.from}
              $date={msg.time}
              $rank="partner"
              $referenceFileId={msg.referenceFileId}
            >
              {msg.body}
            </ChatMessage>
          ))}
        </StyledContentDisplay>
      </StyledScrollContainer>

      <ChatBar
        messageBody={messageBody}
        setMessageBody={setMessageBody}
        submitHandler={submitHandler}
      />
    </>
  );
};

export default ChatWindow;

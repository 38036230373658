import styled, { css } from "styled-components";

export interface BtnStyle {
  $pad?: string;
  $important?: string;
  $imgHeight?: string;
  $flagWidth?: string;
  $offset?: string;
  $flagHoverable?: boolean;
}
export interface BtnHoverStyle {
  $enabled?: boolean;
  $offset?: string;
}

export interface StyledHoverFlagBtn extends BtnHoverStyle, BtnStyle {}
export interface StyledSimpleIconBtn extends StyledHoverFlagBtn {
  $active?: boolean;
  $activeStyle?: string;
}

export interface StyledImgAnchor {
  $src: string;
}

export const btnInactiveStyle = css`
  --flagCol: grey;

  cursor: unset;

  img {
    filter: saturate(0);
    user-select: none;
  }
`;

export const btnHoverStyle = css<BtnHoverStyle>`
  &:hover:not(:active) {
    img {
      filter: ${(props) => props.$enabled !== false && "brightness(1.1)"};
    }

    .hover-flag {
      /* top: ${(props) => props.$offset || "50%"}; */
      opacity: 1;
    }

    &::before {
      height: 20px;
    }
  }
`;

export const btnStyle = css<BtnStyle>`
  --flagCol: var(--bgOutline);
  --width: ${(props) => props.$flagWidth || "300px"};
  --offset: 25px;
  // Counter-acts the offset above the icon (to the right), which keeps this arrow offsetted slightly from edge of hover flag
  --arrowOffset: 5px;

  padding-right: var(--padding);
  display: block;
  position: relative;

  z-index: 2;

  transition: all 0.2s ease-in-out;

  cursor: pointer;

  ${(props) =>
    props.$important &&
    css`
      &::after {
        --important-radius: 5px;
        content: "";
        border-radius: 50%;
        background-color: red;
        height: calc(var(--important-radius) * 2);
        width: calc(var(--important-radius) * 2);
        position: absolute;
        display: block;
        top: calc(var(--important-radius) * -1);
        left: calc(var(--important-radius) * -1);
      }
    `}

  img {
    position: relative;
    height: ${(props) => props.$imgHeight || "100%"};
    transition: all 0.2s ease-in-out;
  }

  .hover-flag {
    display: block;
    position: absolute;

    top: calc(-1 * var(--arrowOffset));
    opacity: 0;

    height: auto;
    width: var(--width);
    background-color: var(--flagCol);
    border-radius: 2px;
    padding: ${(props) => props.$pad || "10px"};

    color: black;
    transform: translate(calc(-100% - var(--offset)), 0);

    transition: all 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    // Prevents flag being able to be hovered to keep it showing. Only hovering button itself keeps flag showing
    pointer-events: ${(props) => !props.$flagHoverable && "none"};

    &::after {
      content: "";
      right: 0;
      top: var(--arrowOffset);
      background-color: var(--bgOutline);
      position: absolute;
      display: block;

      height: 18px;
      width: 13px;
      clip-path: polygon(0 0, 100% 50%, 0 100%);

      transform: translateX(90%);
    }

    & > p:first-child {
      background: linear-gradient(
        to right,
        var(--actionSecondary),
        var(--actionPrimaryGradientMiddle),
        var(--actionPrimaryAlt)
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600; // Semi-bold
    }
  }
`;

export const StyledHoverFlagBtn = styled.a<StyledHoverFlagBtn>`
  ${btnStyle}
  ${btnHoverStyle}
  ${(props) => props.$enabled === false && btnInactiveStyle}
`;

export const StyledSimpleIconBtn = styled(
  StyledHoverFlagBtn
)<StyledSimpleIconBtn>`
  ${(props) => props.$active && props.$activeStyle};
`;

export const StyledTextFadeIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: end;
  gap: 6px;

  & > h4 {
    color: var(--primaryDim);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    user-select: none;
  }

  & > img {
    height: 32px;
  }

  &:hover {
    & > h4 {
      opacity: 1;
    }
  }
`;

export const StyledImgAnchor = styled.a<StyledImgAnchor>`
  --size: 30px;

  position: relative;
  height: var(--size);
  width: var(--size);
  opacity: 0.7;
  transition: opacity 0.1s ease-in-out;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: ${(props) => `url("${props.$src}")`};
    background-size: contain;
  }

  &:hover {
    opacity: 1;
  }
`;

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";

import {
  StyledInputContainer,
  StyledOptionContainer,
} from "features/form/FormContainers.styled";
import { StyledFormPage } from "features/form/Form.styled";
import Form from "features/form/Form";

import FormOption from "_components/features/form/FormOption";
import FormItem from "_components/features/form/FormItem";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

import { ENDPOINT } from "js/data/constants";
import { getErrorMessage } from "js/data/formErrorMessages";

function Join() {
  const [selectedOption, setSelectedOption] = useState("Partner");
  const [errorMessages, setErrorMessages] = useState([]);
  const [canSend, setCanSend] = useState(true);

  const navigate = useNavigate();
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);

    const obj = {
      name: formRef.current.username.value,
      email: formRef.current.email.value,
      username: formRef.current.username.value,
      password: formRef.current.password.value,
      rblxHandle: formRef.current.rblxHandle.value,
      accountType: selectedOption,
      target: formRef.current.target.value,
      via: ReactSession.get("via"),
    };

    if (!canSend) {
      setErrorMessages(["Form send in progress.."]);
      return;
    }

    setCanSend(false);

    axios
      .post(`${ENDPOINT}/api/auth/user/signup`, obj)
      .then((res) => {
        let { hash, email } = res.data;
        if (!hash || !email) {
          throw new Error(
            "Hash or Email missing from signup response on parsing!"
          );
        }
        console.log("successful client signup!");
        // TODO: Change back to /join/verify when auth is added back for clients
        navigate(`/login`);
      })
      .catch((error) => {
        setErrorMessages([getErrorMessage(error)]);
        setCanSend(true);
        console.error(error);
      });
  };

  return (
    <StyledFormPage>
      <ParamTabSet setTab={setSelectedOption} query="type" />

      <Form
        ref={formRef}
        title="Create Your Account"
        description={
          selectedOption === "Affiliate"
            ? "You will not receive a Production Team"
            : "You will receive a Production Team"
        }
        disabled={!canSend}
        actionText="Create Account"
        errorMessages={errorMessages}
        onSubmit={handleSubmit}
      >
        <StyledOptionContainer>
          <FormOption
            value="Partner"
            name="product"
            inputType="checkbox"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          >
            Join as Partner
          </FormOption>
          <FormOption
            value="Affiliate"
            name="product"
            inputType="checkbox"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          >
            Join as Affiliate
          </FormOption>
        </StyledOptionContainer>

        <StyledInputContainer>
          <FormItem
            $title="Your Email for Updates"
            className="full"
            placeholder="example@gmail.com"
            type="email"
            name="email"
            maxLength={320}
            required
            autoFocus
          />
          <FormItem
            $title="Choose Your Username"
            className="full"
            placeholder="Enter new username"
            name="username"
            maxLength={26}
            required
          />
          <FormItem
            $title="Set Account Password (NOT Roblox Password)"
            className="full"
            placeholder="Create a secure password"
            type="password"
            name="password"
            maxLength={50}
            required
          />

          <FormItem
            $title="Roblox Username for Payouts"
            className={selectedOption !== "Partner" ? "full" : ""}
            placeholder="Enter Roblox username"
            name="rblxHandle"
            maxLength={20}
            required
          />
          {selectedOption === "Partner" && (
            <FormItem
              $title="Weekly Video Production Goal"
              placeholder="Enter 2-14 videos"
              type="number"
              name="target"
              max={14}
              min={2}
              required
            />
          )}
        </StyledInputContainer>
      </Form>
    </StyledFormPage>
  );
}

export default Join;

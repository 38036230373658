import { StyledFlexbox } from "styles/util/Flex.styled";

import User from "components/atoms/User";
import { StyledMessage } from "components/atoms/ChatMessage";

import { StyledBeamButtons } from "styles/atoms/buttons/TextButtons.styled";
import { StyledSpacer } from "styles/util/Spacer.styled";

const InteractableAlert = () => {
  return (
    <StyledFlexbox
      $gap="30px"
      style={{
        backgroundColor: "var(--bgDarkHighlight)",
        padding: "25px",
        borderRadius: "5px",
      }}
    >
      <StyledFlexbox $width="100%" $row $justify="space-between">
        <div>
          <p style={{ color: "var(--error)" }}>Time Extension Requested</p>
          <h3>3 More days</h3>
        </div>
        <p>3rd Dec 2024</p>
      </StyledFlexbox>
      <div>
        <User $row>
          <p style={{ color: "white" }}>Molly</p>
        </User>
        <StyledSpacer $pad="15px" />
        <StyledMessage>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam
        </StyledMessage>
        <StyledBeamButtons>
          {/* // TODO. Wait for chat system */}
          <button onClick={() => console.log("Reply")}>Reply</button>
          <button onClick={() => console.log("Accept")}>Accept</button>
          <button onClick={() => console.log("Decline")}>Decline</button>
        </StyledBeamButtons>
      </div>
    </StyledFlexbox>
  );
};

export default InteractableAlert;

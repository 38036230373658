import { PropsWithChildren } from "react";

import { StyledFlexbox } from "styles/util/Flex.styled";

import { SimpleIconBtn } from "components/atoms/buttons/IconButtons";

export interface DashboardHeadingProps {
  title: string;
  note?: string;
  onRefresh?: () => void;
}

export interface DashboardSectionProps extends PropsWithChildren {
  title?: string;
  note?: string;
  onRefresh?: () => void;
}

export interface DashboardStatProps {
  title: string;
  value: string;
}

export const DashboardSection = ({
  title,
  note,
  onRefresh,
  children,
}: DashboardSectionProps) => {
  return (
    <StyledFlexbox>
      {title && (
        <DashboardHeading title={title} note={note} onRefresh={onRefresh} />
      )}
      <StyledFlexbox $wrap $row $gap="35px">
        {children}
      </StyledFlexbox>
    </StyledFlexbox>
  );
};

export const DashboardHeading = ({
  title,
  note,
  onRefresh,
}: DashboardHeadingProps) => {
  return (
    <StyledFlexbox
      $row
      $align="end"
      $gap="15px"
      $pad="25px 0"
      style={{ position: "relative" }}
    >
      {onRefresh && (
        <SimpleIconBtn
          src="/images/buttons/refresh.png"
          onClick={onRefresh}
          style={{
            position: "absolute",
            left: "-30px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      )}
      <h3>{title}</h3>
      <p>{note}</p>
    </StyledFlexbox>
  );
};

export const DashboardStat = ({ title, value }: DashboardStatProps) => {
  return (
    <StyledFlexbox $bg="var(--bgShadow)" $pad="32px" $rad="8px" $width="300px">
      <h2>{value}</h2>
      <p>{title}</p>
    </StyledFlexbox>
  );
};

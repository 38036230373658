import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledBeamButtons } from "styles/atoms/buttons/TextButtons.styled";

export interface BeamButtonProps {
  value: string;
  currentValue?: any;
  setCurrentValue: (value: any) => void;
}

export const BeamButton = ({
  value,
  currentValue,
  setCurrentValue,
}: BeamButtonProps) => {
  return (
    <button
      style={{
        color:
          currentValue && currentValue === value ? "var(--defaultTint)" : "",
      }}
      onClick={() => setCurrentValue(value)}
    >
      {value}
    </button>
  );
};

interface BeamIncrementorProps {
  unlocked?: boolean; // If true, the value can go below 0
  label?: string;
  currentValue: any;
  setCurrentValue: (value: any) => void;
}

export const BeamIncrementor = ({
  unlocked = false,
  label,
  currentValue,
  setCurrentValue,
}: BeamIncrementorProps) => {
  return (
    <StyledFlexbox $align="center">
      <p>{label}</p>
      <StyledBeamButtons>
        <BeamButton
          value="-"
          setCurrentValue={() =>
            setCurrentValue(
              unlocked ? currentValue - 1 : Math.max(currentValue - 1, 0)
            )
          }
        />
        <button className="static">{currentValue}</button>
        <BeamButton
          value="+"
          setCurrentValue={() => setCurrentValue(currentValue + 1)}
        />
      </StyledBeamButtons>
    </StyledFlexbox>
  );
};

import { PropsWithChildren } from "react";

import { StyledFlexbox } from "styles/util/Flex.styled";
import {
  StyledColumnItem,
  StyledArrowColumnItem,
  type ArrowItemProps as StyledArrow,
  type ColumnItemProps as StyledColumn,
} from "styles/molecules/ColumnItem.styled";

import constraint from "styles/function/mods/constraint";
interface ColumnItemProps extends PropsWithChildren, StyledColumn {
  title: string;
  [key: string]: any;
}

interface ArrowItemProps extends PropsWithChildren, StyledArrow {
  title: string;
  [key: string]: any;
}

const ChildConstraintFlexbox = constraint(StyledFlexbox, "child");

export const ArrowColumnItem = ({
  children: text,
  ...props
}: ArrowItemProps) => {
  return (
    <StyledArrowColumnItem {...props}>
      <p>{text}</p>
      <img src="/images/common/simple-arrow.png" />
    </StyledArrowColumnItem>
  );
};

const ColumnItem = ({
  title,
  children: buttons,
  ...props
}: ColumnItemProps) => {
  return (
    <StyledColumnItem {...props}>
      <p>{title}</p>
      <ChildConstraintFlexbox
        $height="22px"
        $row
        $align="center"
        $gap="15px"
        $pad="0 0 10px 0"
        style={{ alignSelf: "end" }}
      >
        {buttons}
      </ChildConstraintFlexbox>
    </StyledColumnItem>
  );
};

export default ColumnItem;

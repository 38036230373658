import { PropsWithChildren } from "react";

import { StyledFlexbox } from "styles/util/Flex.styled";
import {
  StyledOverlay,
  GlobalBackground,
} from "styles/organisms/Overlay.styled";

import IconHeading from "components/atoms/IconHeading";
import { StyledSpacer } from "styles/util/Spacer.styled";

interface Overlay extends PropsWithChildren {
  $gap?: string;
  // TODO: Type with component
  Heading?: any;
  [key: string]: any; // Props of <Heading>
}

const Overlay = ({
  $gap,
  Heading = IconHeading,
  children,
  ...props
}: Overlay) => {
  return (
    <StyledOverlay>
      <GlobalBackground />
      <Heading $margin="0 0 60px 0" {...props} />
      <StyledSpacer $pad="60px" />
      <StyledFlexbox $pad="0 150px" $width="100%" $align="center" $gap={$gap}>
        {children}
      </StyledFlexbox>
    </StyledOverlay>
  );
};

export default Overlay;

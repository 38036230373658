import styled, { css } from "styled-components";

import { StyledLineBorder } from "styles/molecules/LineBorder.styled";

export interface ListProps {
  $icon?: string;
  $gap?: string;
}

export interface ColumnItemProps extends ProgressStyleProps {}

export interface ArrowItemProps {
  $active?: boolean;
}

export interface ProgressStyleProps {
  $progress: number; // Between 0 and 100
}

const ProgressStyle = css<ProgressStyleProps>`
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    width: ${(props) => props.$progress + "%"};
    height: 2px;
    background: linear-gradient(
      to right,
      var(--actionSecondary),
      var(--actionPrimaryGradientMiddle),
      var(--actionPrimaryGradientLast)
    );
  }
`;

export const StyledAppendableList = styled(StyledLineBorder)<ListProps>`
  width: 450px;
  height: 800px;
  background: linear-gradient(
    to bottom right,
    var(--bgHighlightGradientFirst),
    var(--bgHighlightGradientLast)
  );

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.$gap || "15px"};

  &::before {
    content: "";
    background: ${(props) =>
      `url(${
        props.$icon || "/images/buttons/upload-static.png"
      }) no-repeat center/contain`};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 150px;
    width: 150px;

    transition: filter 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      filter: brightness(1.2);
    }
  }
`;

export const StyledColumnItem = styled.div<ColumnItemProps>`
  border: 1px solid var(--bgOutline);
  padding: 8px 16px;

  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px 5px;

  width: 410px;
  min-height: 50px; // Ensures buttons look centered despite their bottom offset

  background: linear-gradient(
    to bottom right,
    var(--bgHighlightGradientFirst),
    var(--bgHighlightGradientLast)
  );

  & > *:first-child {
    width: 75%;
    color: var(--secondaryDim);
    transition: color 0.2s ease-in-out;
  }

  ${(props) => (props.$progress != undefined ? ProgressStyle : "")}
`;

export const StyledArrowColumnItem = styled(StyledColumnItem)<ArrowItemProps>`
  align-items: center;

  & > *:last-child {
    margin-right: 15px;
    height: 16.6px;
    opacity: 0.5; // Makes grey
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    & > *:last-child {
      transform: translateX(6px);
      // TODO: Apply gradient
    }
  }

  ${(props) =>
    props.$active &&
    css`
      background: linear-gradient(
        to bottom right,
        var(--actionSecondary),
        var(--actionPrimaryGradientMiddle),
        var(--actionPrimaryGradientLast)
      );

      & > *:first-child {
        color: white;
      }

      & > *:last-child {
        opacity: 1; // Make not grey (white default)
      }
    `}
`;

import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledSbs } from "styles/util/Flex.styled";

import PackageOption, {
  Pallet,
} from "_components/molecules/displays/PackageOption";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

const PartnerPlans = ({ isRbxYoutuber, setIsRbxYoutuber }) => {
  const dividers = ["Features", "Stats", "Pricing"];

  const basic = [
    [
      ["Game Profits", true],
      ["Free Advertising", isRbxYoutuber],
      ["Collaborations", true],
      ["Personal Production Team", true],
      ["Out-of-game Advertising", isRbxYoutuber],
      ["In-game Advertising", false],
      ["Access To All Special Offers", false],
      ["Access To All Site Features", false],
      ["Priority Privilege", false],
    ],
    [
      ["Minimum Percentage Ownership Per-Game", "1%"],
      ["Maximum Percentage Ownership Per-Game", "1/5"],
      ["Out-of-game Features", isRbxYoutuber ? "All" : "None"],
      ["In-game Features", isRbxYoutuber ? "None" : "None"],
      ["Maximum Games Selectable", "1"],
      ["Maximum Team Members", "2"],
      ["Revisions Entitled", "1"],
      ["Staff Skill Level", "Great"],
    ],
    [
      ["Price Per Edit", "$1.10", "Per minute of running time"],
      ["Price Per Thumbnail", "$5.50"],
    ],
  ];

  const premium = [
    [
      ["Game Profits", true],
      ["Free Advertising", isRbxYoutuber],
      ["Collaborations", true],
      ["Personal Production Team", true],
      ["Out-of-game Advertising", isRbxYoutuber],
      ["In-game Advertising", isRbxYoutuber],
      ["Access To All Special Offers", true],
      ["Access To All Site Features", false],
      ["Priority Privilege", false],
    ],
    [
      ["Minimum Percentage Ownership Per-Game", "1/5"],
      ["Maximum Percentage Ownership Per-Game", "2/5"],
      ["Out-of-game Features", isRbxYoutuber ? "All" : "None"],
      ["In-game Features", isRbxYoutuber ? "Minor" : "None"],
      ["Maximum Games Selectable", "2"],
      ["Maximum Team Members", "3"],
      ["Revisions Entitled", "2"],
      ["Staff Skill Level", "Incredible"],
    ],
    [
      ["Price Per Edit", "$1.90", "Per minute of running time"],
      ["Price Per Thumbnail", "$7.50"],
    ],
  ];

  const pro = [
    [
      ["Game Profits", true],
      ["Free Advertising", isRbxYoutuber],
      ["Collaborations", true],
      ["Personal Production Team", true],
      ["Out-of-game Advertising", isRbxYoutuber],
      ["In-game Advertising", isRbxYoutuber],
      ["Access To All Special Offers", true],
      ["Access To All Site Features", true],
      ["Priority Privilege", true],
    ],
    [
      ["Minimum Percentage Ownership Per-Game", "2/5"],
      ["Maximum Percentage Ownership Per-Game", "3/5"],
      ["Out-of-game Features", isRbxYoutuber ? "All" : "None"],
      ["In-game Features", isRbxYoutuber ? "Major" : "None"],
      ["Maximum Games Selectable", "3"],
      ["Maximum Team Members", "4"],
      ["Revisions Entitled", "3"],
      ["Staff Skill Level", "Exceptional"],
    ],
    [
      ["Price Per Edit", "$2.90", "Per minute of running time"],
      ["Price Per Thumbnail", "$10.00"],
    ],
  ];

  return (
    <>
      <ParamTabSet
        setTab={setIsRbxYoutuber}
        query="rbx"
        parser={(value) => value !== "no"}
      />

      <StyledSpacer $pad="50px" />

      <StyledSbs $align="start" $gap="min(50px, 5%)">
        <PackageOption
          pallet={Pallet.Secondary}
          title="Basic"
          description="Budget Friendly"
          dividers={dividers}
          entries={basic}
          to="/join?package=basic"
        />
        <PackageOption
          pallet={Pallet.Primary}
          title="Premium"
          description="Most Popular"
          dividers={dividers}
          entries={premium}
          to="/join?package=premium"
        />
        <PackageOption
          pallet={Pallet.Tertiary}
          title="Pro"
          description="Best Value"
          dividers={dividers}
          entries={pro}
          to="/join?package=pro"
        />
      </StyledSbs>
    </>
  );
};

export default PartnerPlans;

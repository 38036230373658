import { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactSession } from "react-client-session";

import GlobalStyle from "./styles/App.styled";
// import "scss/app.css";
// import "scss/app.scss";

import Footer from "components/templates/Footer";
import Navbar from "_components/molecules/containers/navigation/Navbar";

import ScrollToTop from "_components/function/handlers/ScrollToTop";
import AuthLoggedIn from "_components/function/handlers/AuthLoggedIn";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

import { QueryContext } from "components/function/contexts/QueryContext";
import { AccountContext } from "components/function/contexts/AccountContext";

import Home from "pages/index";
import Pricing from "pages/learn/Pricing";

import Dashboard from "pages/dashboard/Dashboard";
import ProductionRecieve from "pages/dashboard/production/Receive";
import ProductionSend from "pages/dashboard/production/Send";

import Games from "pages/dashboard/Games";
import Shop from "pages/dashboard/Shop";
import Chat from "pages/dashboard/Chat";
import Settings from "pages/dashboard/Settings";
import Logout from "pages/dashboard/Logout";

// import Content from "pages/explore/Content";
import ExploreProduction from "pages/explore/Production";
// import ForNonRbx from "pages/explore/ForNonRbx";
import ExploreAffiliate from "pages/explore/Affiliate";

import LearnGame from "pages/learn/Game";
import LearnProduction from "pages/learn/Production";
// import Documentation from "pages/Documentation";
// import RulesAndGuidelines from "pages/Documentation/RulesAndGuidelines";
// import TermsOfService from "pages/Documentation/TermsOfService";
// import PrivacyPolicy from "pages/Documentation/PrivacyPolicy";

import Login from "pages/Login";
import Join from "pages/Join";
import JoinReturn from "pages/Join/Return";
import JoinVerify from "pages/Join/Verify";
import JoinVerifyReturn from "pages/Join/Verify/Return";
import JoinProduction from "pages/Join/Production";

// import Scheduler from "pages/comm/Scheduler";
// import ContactUs from "pages/comm/ContactUs";

// import AdminPanel from "pages/admin/Panel";
// import ApAnalytics from "pages/admin/Analytics";
// import ApManage from "pages/admin/Manage";
// import ApReview from "pages/admin/Review";

// import Checkout from "pages/Checkout";
// import CheckoutReturn from "pages/Checkout/Return";

import { FriendsContext } from "_components/function/contexts/FriendsContext";

import queryChangeHandler from "js/queryChangeHandler";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";
import RerouteToChildChandler from "components/function/handlers/RerouteToChildChandler";

function App() {
  const [queryWrap, setQueryWrap] = useState({});
  let wrapQuery = window.matchMedia("(max-width: 1370px)");
  useEffect(() => queryChangeHandler(wrapQuery, setQueryWrap), []);

  const queries = {
    queryWrap: queryWrap,
  };

  const [friends, setFriends] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [storageLoaded, setStorageLoaded] = useState(false);

  const loadFriendships = async () => {
    if (!loggedIn) {
      setFriends([]);
      return;
    }

    axios
      .get(`${ENDPOINT}/api/comm/friendships`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => setFriends(res.data))
      .catch(console.error);
  };

  // useEffect must be () => { load() }, not () => load(). useEffect can't be async
  useEffect(() => {
    loadFriendships();
  }, [loggedIn]);

  // State stays on refresh, react state doesn't, so just re-apply it if state still exists (haven't logged out to remove it)
  useEffect(() => {
    ReactSession.setStoreType("localStorage");
    if (ReactSession.get("useruid") != undefined) {
      setLoggedIn(true);
    }
    setStorageLoaded(true);
  }, []);

  return (
    <div className="App">
      <GlobalStyle />
      <AccountContext.Provider value={[loggedIn, setLoggedIn]}>
        <QueryContext.Provider value={queries}>
          <FriendsContext.Provider value={friends}>
            <BrowserRouter>
              {storageLoaded && (
                <ParamTabSet
                  $autoReset
                  query="via"
                  setTab={(via) => ReactSession.set("via", via)}
                />
              )}
              <ScrollToTop />
              <AuthLoggedIn />
              <Navbar>
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/learn/pricing" element={<Pricing />} />

                  <Route exact path="/dashboard/home" element={<Dashboard />} />
                  <Route
                    exact
                    path="/dashboard/production"
                    element={
                      <RerouteToChildChandler path="/dashboard/production/receive" />
                    }
                  />
                  <Route
                    exact
                    path="/dashboard/production/receive"
                    element={<ProductionRecieve />}
                  />
                  <Route
                    exact
                    path="/dashboard/production/send"
                    element={<ProductionSend />}
                  />

                  <Route exact path="/dashboard/games" element={<Games />} />
                  <Route exact path="/dashboard/shop" element={<Shop />} />
                  <Route exact path="/dashboard/chat" element={<Chat />} />
                  <Route
                    exact
                    path="/dashboard/settings"
                    element={<Settings />}
                  />
                  <Route exact path="/dashboard/logout" element={<Logout />} />

                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/join" element={<Join />} />
                  <Route exact path="/join/return" element={<JoinReturn />} />
                  <Route exact path="/join/verify" element={<JoinVerify />} />
                  <Route
                    exact
                    path="/join/verify/return"
                    element={<JoinVerifyReturn />}
                  />
                  <Route
                    exact
                    path="/join/production"
                    element={<JoinProduction />}
                  />

                  <Route
                    path="/explore/production"
                    element={<ExploreProduction />}
                  />
                  <Route
                    path="/explore/affiliate"
                    element={<ExploreAffiliate />}
                  />

                  <Route exact path="/learn/game" element={<LearnGame />} />
                  <Route
                    exact
                    path="/learn/production"
                    element={<LearnProduction />}
                  />

                  {/* <Route
                    exact
                    path="/dashboard/production/file"
                    element={<FileManager />}
                  /> */}
                  {/* <Route
                    exact
                    path="/dashboard/production/staff"
                    element={<Scheduler />}
                  /> */}

                  {/* <Route path="/explore/content" element={<Content />} />
                  <Route path="/explore/youtuber" element={<ForNonRbx />} />

                  <Route exact path="/contact" element={<ContactUs />} />
                  <Route exact path="/docs" element={<Documentation />} />
                  <Route
                    exact
                    path="/docs/rules-and-guidelines"
                    element={<RulesAndGuidelines />}
                  />
                  <Route
                    exact
                    path="/docs/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route
                    exact
                    path="/docs/privacy-policy"
                    element={<PrivacyPolicy />}
                  />

                  <Route exact path="/game/manager" element={<GameManager />} />
                  <Route
                    exact
                    path="/game/booster"
                    element={<OwnershipBooster />}
                  />


                  <Route exact path="/admin/panel" element={<AdminPanel />} />
                  <Route
                    exact
                    path="/admin/analytics"
                    element={<ApAnalytics />}
                  />
                  <Route exact path="/admin/manage" element={<ApManage />} />
                  <Route exact path="/admin/review" element={<ApReview />} />

                  <Route exact path="/dashboard/shop/checkout" element={<Checkout />} />
                  <Route
                    exact
                    path="/dashboard/shop/review"
                    element={<CheckoutReturn />}
                  /> */}
                </Routes>
              </Navbar>
              <Footer />
            </BrowserRouter>
          </FriendsContext.Provider>
        </QueryContext.Provider>
      </AccountContext.Provider>
    </div>
  );
}

export default App;

import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledInfoBlock } from "styles/molecules/InfoBlock.styled";
import {
  StyledPackageOption,
  StyledPlanEntry,
} from "_components/molecules/displays/PackageOption";
import { StyledCompareContainer } from "styles/molecules/CompareContainer.styled";

import TopBanner from "_components/molecules/structure/TopBanner";
import GlassSection from "components/organisms/GlassSection";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";
import { LoneCtaSection } from "components/atoms/CtaElements";
import { LinkSpan } from "components/atoms/SpanElements";

const LearnProduction = () => {
  return (
    <section>
      <TopBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h2>Production Benefits</h2>
        <p style={{ maxWidth: "500px" }}>
          Everything about the benefits from working with our production teams
        </p>
      </TopBanner>

      <StyledSpacer $pad="150px" />
      <StyledInfoBlock $align="start" $width="short">
        <h2>Intro</h2>
        <p>
          When we say 'Production Teams' we are referring to the teams of video
          editors and the teams of thumbnails artists who are created for each
          client in order to help produce their Youtube videos.
        </p>
        <p>
          You can chat individually with each editor or artist, chat with
          editors only, chat with artists only, or you can chat with all members
          of the team in one chat room. All of this can be done through the '
          <LinkSpan to="/dashboard/chat">Chat</LinkSpan>' page.
        </p>
        <p>
          This half of our brand's identity can be described as a 'Roblox
          Editing & Thumbnail production Agency'. You can view and understand
          the other half of our brand identity in '
          <LinkSpan to="/learn/game">Game Benefits</LinkSpan>' .
        </p>
      </StyledInfoBlock>

      <StyledSpacer $pad="150px" />
      <GlassSection>
        <StyledFlexbox $row $align="center" $justify="center" $gap="50px">
          <HoverFlagBtn
            title="Game Manager"
            src="/images/buttons/game-icon.png"
          >
            View, select and monitor the progress of all of our upcoming games
          </HoverFlagBtn>
          <HoverFlagBtn title="Live Chat" src="/images/buttons/chat-icon.png">
            Message admins, your production team, and any contact from our sit
          </HoverFlagBtn>
          <HoverFlagBtn
            title="In-built Checkout"
            src="/images/icons/active/cart.png"
          >
            Quickly and easily purchase as many edits and thumbnails in advance
            as you wish
          </HoverFlagBtn>
          <HoverFlagBtn
            title="Staff Scheduler"
            src="/images/icons/active/calendar.png"
          >
            Your production team can efficiently co-ordinate their work using
            our scheduler
          </HoverFlagBtn>
          <HoverFlagBtn
            title="Ownership Booster"
            src="/images/icons/active/chart.png"
          >
            Quickly boost your ownership percentage of games by filling out a
            simple form
          </HoverFlagBtn>
          <HoverFlagBtn
            title="File Manager"
            src="/images/buttons/upload-icon.png"
          >
            Send, receive and manage files between you and your team quickly
          </HoverFlagBtn>
        </StyledFlexbox>

        <StyledSpacer />
        <StyledCompareContainer>
          <StyledPackageOption $pallet="secondary">
            <div className="heading">
              <h2>Individual Editors & Artists</h2>
              <p>Freelancers found on other sites</p>
            </div>

            <div className="content">
              <StyledPlanEntry $value={false}>
                Can charge whatever price they want, charging differently from
                any others you hire
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Can have many other clients, making you not a priority & very
                replaceable
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Don't produce work in the same style or speed as any others you
                hire
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                No co-ordination with others you hire, partly due to them being
                used to an "every man for themselves" mindset
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Their working schedule doesn't match up well with others you
                hire, hurting your upload schedule massively
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                You must create a whole team of editors and artists all by
                yourself with all of the issues mentioned
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Prices of all other freelance sites cannot beat our rates
              </StyledPlanEntry>
            </div>
          </StyledPackageOption>

          <StyledPackageOption $pallet="primary">
            <div className="heading">
              <h2>Our Production Teams</h2>
              <p>Teams of Editors & Artists</p>
            </div>

            <div className="content">
              <StyledPlanEntry $value={true}>
                All individuals of a team work for the same price
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                You are a first priority, with the team dedicated to you giving
                you their full attention and focus
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                All team members adapt to your video style and keep their styles
                all consistent with one another through regular practice and
                training working with one another
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                Full co-ordination between members of each team as they are
                trained together regularly to all be consistent with one another
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                Individuals of a team organise workload between themselves to
                match your upload schedule exactly
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                Production Teams are pre-designed for you and you don't need you
                to make them up yourself
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                We are the{" "}
                <LinkSpan to="/learn/pricing">lowest prices</LinkSpan> in the
                industry
              </StyledPlanEntry>
            </div>
          </StyledPackageOption>
        </StyledCompareContainer>
      </GlassSection>

      <LoneCtaSection to="/learn/pricing" actionText="Discover">
        <h2>See our Pricing</h2>
        <p>
          Our rates beat all competitors, and we offer bundled rewards like no
          other service.
        </p>
      </LoneCtaSection>
      <StyledSpacer />
    </section>
  );
};

export default LearnProduction;

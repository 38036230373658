import { css, styled } from "styled-components";
import { StyledFlexbox } from "styles/util/Flex.styled";

export const QuotedStyle = css`
  --size: 1.25rem;
  --offset: 20px;

  position: relative;

  &::before {
    content: "";
    background-image: url("/images/common/quote-left.png");
    opacity: 0.1;
    background-size: contain;
    height: var(--size);
    width: var(--size);
    position: absolute;
    top: var(--offset);
    left: var(--offset);
    z-index: 1;
  }

  &::after {
    content: "";
    background-image: url("/images/common/quote-right.png");
    opacity: 0.1;
    background-size: contain;
    height: var(--size);
    width: var(--size);
    position: absolute;
    bottom: var(--offset);
    right: var(--offset);
    z-index: 1;
  }
`;

export const StyledQuotedText = styled.div`
  --size: 1.25rem;
  --offset: 7px;

  .quoted::before {
    content: "";
    background-image: url("/images/common/quote-left.png");
    opacity: 0.5;
    background-size: contain;
    height: var(--size);
    width: var(--size);
    position: absolute;
    transform: translate(calc(-100% - var(--offset)), 0);
  }

  .quoted::after {
    content: "";
    background-image: url("/images/common/quote-right.png");
    opacity: 0.5;
    background-size: contain;
    height: var(--size);
    width: var(--size);
    position: absolute;
    transform: translate(var(--offset), 100%);
  }
`;

export const StyledQuotedFlexbox = styled(StyledFlexbox)`
  ${QuotedStyle}
`;

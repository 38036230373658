import HomeHeader from "components/templates/HomeHeader";
import AboutUs from "components/templates/AboutUs";
import OurBrand from "components/templates/OurBrand";
import CompanyStatView from "components/templates/CompanyStatView";
import Faq from "components/organisms/Faq";

import { LinkSpan } from "components/atoms/SpanElements";

// Faq
const questions = [
  "Can I get a refund on purchases of video edits or thumbnails?",
  "In simple terms, what do I get and what do I pay for it?",
  "What is planned for PUSHXPULL in order to constantly improve and scale it’s value to the Roblox Community?",
  <span>
    What does <i>‘PUSHXPULL’</i> mean?"
  </span>,
];
const answers = [
  <p>
    Yes! If you purchased an edit or thumbnail service, and then requested your
    editor/artist to provide that service, it will use up that purchased service
    in your account and they will start fulfilling the request. If the
    editor/artist fails to provide the product within your set number of days
    allocated, then the request will fail and you will receive a refund of that
    edit/thumbnail service back to your account along with an email confirming
    cancellation of the request and the refunded service back into your account.
  </p>,
  <p>
    Our business model may seem complicated or daunting but in reality it’s
    extremely simple.
    <br />
    <br />
    <b>You receive</b> a personal team of editors and/or thumbnail artists to
    help you produce your youtube videos according to your schedule and style.
    That is what you are paying for, a professional, dedicated and collaborative
    team which surpasses any collection of freelancers, and for the same price!
    See our pricing <LinkSpan to="/pricing">here</LinkSpan>.
    <br />
    <br />
    <b>You also receive, as a free gift</b>,{" "}
    <LinkSpan to="/benefits/game">free advertising</LinkSpan> for your channel
    via our Roblox Games, and a{" "}
    <LinkSpan to="/benefits/game">percentage ownership</LinkSpan> of all profits
    from many games at once, which you can select instantly upon signing up from
    the quickly growing list of our games which we manage.
    <br />
    <br />
    This is our philosophy and what outweights even all the other benefits we
    offer through our company. This is what massively sets us apart from you
    choosing normal freelancer editors/artists over us. We offer an improved
    version of what they offer, and we throw in free marketing and earning
    potential for your channel.
    <br />
    <br />
    Put simply, we are <b>the only</b> all-in-one, full, pre-bulit team you need
    to <b>manage, monitize, and scale</b> your Roblox Youtube channel.
  </p>,

  <ol>
    <li>
      <b>Constantly improve the skills of our production staff, your team</b>
      <br />
      <p>
        This will be done through regular training, innovative ideas, and
        inclusion of individuals with varying skillsets to help us effectively
        stay ahead of trends, production quality, marketing techniques and
        keeping constant growth coming to your channel.
      </p>
    </li>
    <li>
      <b>Produce more games, faster, and better than before</b>
      <br />
      <p>
        We are constantly building new teams of game developers of better
        quality to work on new game ideas we have decided to publish. This
        process shall continue to get faster and of higher quality, gaurenteeing
        that we will bring in more game profits across all of our studios over
        time. This is great news for you, because you get a piece of that pie.
        You do no work on these games, and yet you will still reap their{" "}
        <LinkSpan to="/benefits/game">profits</LinkSpan> and{" "}
        <LinkSpan to="/benefits/game">advertising</LinkSpan> potential!
      </p>
    </li>
    <li>
      <b>
        Expand on the benefits offered through our website, trying to constantly
        give you more value which we can provide
      </b>
      <br />
      <p>
        Your experience with your company is our highest priority. This is why
        we don’t want to stay the same for too long. We get bored easily. That’s
        why we have a team of innovators who actively analyse markets and
        constantly engineer new solutions and ideas for how we can scale our
        company to be even more valuable than it already is, offering you new
        and greater potential. In other words,{" "}
        <b>improving your experience in our company!</b>
      </p>
    </li>
  </ol>,
  <p>
    <i>”For every push there is a pull”</i>. This is our motto. It is also what{" "}
    <i>‘PUSHXPULL’</i> means. For every benefit you <i>push</i> our way (e.g.
    paying for our production services), we <i>pull</i> benefits of at least
    twice the value you provided us, back in your direction, empahsised by the 2{" "}
    <i>‘L’</i>s in the word pull. (e.g. we provide a full production team, and
    advertise your channel, and give you direct profits from our games)
    <br />
    <br />
    The <i>'X'</i> in-between <i>'PUSH'</i> and <i>'PULL'</i> helps emphasise
    our unity regarding this dynamic, denoted by the two crossing lines of the
    letter <i>'X'</i>. Where you as our partner and us as your team, isn't a
    formal and blunt relationship. But instead, should become a personal and
    connected group of real, skilled, ambitious people who can help eachother
    grow in skill and success!
  </p>,
];

const Home = () => {
  return (
    <>
      <HomeHeader />
      <AboutUs />
      <OurBrand />
      <CompanyStatView />
      <Faq questions={questions} answers={answers} />
    </>
  );
};

export default Home;

import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledInfoBlock } from "styles/molecules/InfoBlock.styled";
import { StyledImgStrip } from "styles/molecules/StripBanner.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledHighlightedSpan } from "styles/atoms/SpanElements.styled";

import TopBanner from "_components/molecules/structure/TopBanner";
import Faq from "components/organisms/Faq";
import { LoneCtaSection } from "components/atoms/CtaElements";
import { LinkSpan, ScrollElement } from "components/atoms/SpanElements";
import { StyledArrowIndentP } from "_components/atoms/inline/styles/Decorations.styled";

const questions = [
  "Why can’t I just join when the games are released and reap the benefit instantly?",
];
const answers = [
  <p>
    <b>Competition</b>. Other partners also select our games to become a
    part-owner of them, and there are limited slots, usually less than you can
    count on one hand. That means that the games you see announced on our
    website, made available for selection to partners, which look the most
    appealing and promising to you, will also look appealing to other partners.
    And so, they will select the game quickly, and you will not be able to
    secure a spot unless someone cancels their involvement in the game in
    preference of selecting a different game instead.
    <br />
    <br />
    To summarize, you must be <LinkSpan to="/join">registered</LinkSpan> and
    active with our company if you want to be notified for new game
    announcements, and be applicable for selecting our most anticipated games!
  </p>,
];

const LearnGame = () => {
  return (
    <section>
      <TopBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h2>Game Benefits</h2>
        <p style={{ maxWidth: "500px" }}>
          Everything about the benefits you get from our games
        </p>
      </TopBanner>

      <StyledSpacer $pad="150px" />
      <StyledInfoBlock $align="start" $width="medium">
        <h3>Intro</h3>
        <p>
          When we say 'our Game Studios' we are referring to our Roblox Game
          Studios which we constantly build and actively maintain.
        </p>
        <p>
          When we say 'our Games' we are referring to our Roblox Games which our
          Roblox Game Studios made or are making, while constantly coming up
          with new game ideas to build.
        </p>
        <p>
          This half of our brand's identity can be described as a constantly
          growing 'Empire of Roblox Game Studios'. You can view and understand
          the other half of our brand identity in '
          <LinkSpan to="/learn/production">Production Benefits</LinkSpan>
          '.
        </p>

        <h3>Your responsabilities</h3>
        <div name="requirements">
          <p className="no-arrow">
            <StyledHighlightedSpan>Requirements</StyledHighlightedSpan> for
            being eligible to receiving benefits listed on this page:
          </p>
          <p>
            You must have uploaded the minimum amount of monthly videos, from
            which you paid us to help produce.
          </p>
          <p>
            You must have selected to be involved in the game via the '
            <LinkSpan to="/dashboard/game">Game Manager</LinkSpan>
            '.
          </p>
        </div>

        <div name="factors">
          <p className="no-arrow">
            <StyledHighlightedSpan>Factors</StyledHighlightedSpan> dictating the
            scale of the advertising received from us:
          </p>
          <p>
            Your number of produced videos over the minimum upload requirement
            in the month, which you paid us to help produce.
          </p>
          <p>
            The amount of <LinkSpan to="/dashboard/game">advertising</LinkSpan>{" "}
            you have done for the game(s).
          </p>
        </div>
      </StyledInfoBlock>

      <StyledSpacer $pad="150px" />
      <StyledFlexbox
        $gap="100px"
        $align="center"
        $pad="0 0 200px 0"
        $bg="linear-gradient(to bottom left, var(--bgShadow), var(--bgShadowDarker))"
      >
        <StyledImgStrip $pos="50%" src="/images/astral-gfx.jpg" />
        <StyledInfoBlock $align="center" $width="full">
          <h2>Free Advertising</h2>
          <p>
            With the hundreds and thousands of players who will be playing or
            are already playing and following all of our games across all of our
            studios, we will, through creative marketing techniques, advertise
            you and your channel in-game and out-of-game. Directing hundreds to
            thousands of Robloxians to your Youtube channel constantly.
          </p>
          <p>
            Our 'marketing techniques' which we constantly develop and create
            can be applied in-game and out-of-game. Here are some examples..
          </p>

          <p>
            <StyledHighlightedSpan>In-game Examples</StyledHighlightedSpan>
            <br />
            <StyledArrowIndentP $shape="bullet" $indent="30px">
              Player has to go to your channel and find a hidden code in one of
              your videos to use in-game for rewards.
            </StyledArrowIndentP>
            <StyledArrowIndentP $shape="bullet" $indent="30px">
              Custom skin, weapon, item, storyline, enemy, etc.. dedicated to
              you in-game.
            </StyledArrowIndentP>
          </p>

          <p>
            <StyledHighlightedSpan>Out-of-game Examples</StyledHighlightedSpan>
            <br />
            <StyledArrowIndentP $shape="bullet" $indent="30px">
              Shout your channel out on our Game Studio's social media accounts
              or Roblox group.
            </StyledArrowIndentP>
            <StyledArrowIndentP $shape="bullet" $indent="30px">
              Displaying your channel in the description, thumbnail, logo of our
              game(s).
            </StyledArrowIndentP>
          </p>

          <p>
            You don't need to know all of the marketing techniques, as they are
            always changing anyways. You just need to know that they work and
            they work well. With us, you have nothing to lose, this is a free
            gift from us after all. No strings attached.
          </p>
        </StyledInfoBlock>
      </StyledFlexbox>

      <StyledFlexbox
        $gap="100px"
        $align="center"
        $pad="0 0 200px 0"
        $bg="linear-gradient(to bottom left, var(--bgShadow), var(--bgShadowDarker))"
      >
        <StyledImgStrip $pos="50%" src="/images/astral-gfx.jpg" />
        <StyledInfoBlock $align="center" $width="full">
          <h2>Profit Earning</h2>
          <p>
            <StyledHighlightedSpan>Summary</StyledHighlightedSpan>
            <br />
            <p>
              For every game you select to be involved in, you will be entitled
              to a percentage of ownership from each game based on the{" "}
              <ScrollElement to="factors" inline>
                Factors
              </ScrollElement>{" "}
              mentioned later and the scale at which you utilized them.
            </p>
          </p>

          <p>
            Having a percentage of ownership from a game means that when a
            player purchases a game-pass, makes an in-game purchase or purchases
            access to the game, you will then receive that percentage out of the
            profit we receive from that transaction.
          </p>

          <p>
            <StyledHighlightedSpan>Functionality</StyledHighlightedSpan>
            <br />
            <StyledArrowIndentP $indent="30px">
              The percentage ownership you gained entitlement to in a particular
              month from your efforts toward the{" "}
              <ScrollElement to="factors" inline>
                Factors
              </ScrollElement>
              , return you that percentage of the game's profits, for the next
              month, not the current one. This means whatever percentage you are
              increasing or are sustaining in the month, is for the next month's
              benefit. This system just makes sense from a technical
              perspective.
            </StyledArrowIndentP>
            <StyledArrowIndentP $indent="30px">
              To gain a higher percentage you must satisfy the{" "}
              <ScrollElement to="factors" inline>
                Factors
              </ScrollElement>{" "}
              at a higher capacity.
            </StyledArrowIndentP>
            <StyledArrowIndentP $indent="30px">
              Your percentage ownership decreases slowly over the course of the
              month. This means you must satisfy the{" "}
              <ScrollElement to="factors" inline>
                Factors
              </ScrollElement>{" "}
              to at least a minimum capacity to sustain your percentage for the
              next month. The higher percentage you own, the higher capacity you
              must a act toward the{" "}
              <ScrollElement to="factors" inline>
                Factors
              </ScrollElement>{" "}
              to sustain it.
            </StyledArrowIndentP>
            <StyledArrowIndentP $indent="30px">
              You only keep your percentage ownership across all games if you
              have met the{" "}
              <ScrollElement to="requirements" inline>
                Requirements
              </ScrollElement>{" "}
              listed above. (Reviewed at the end of each month)
            </StyledArrowIndentP>
          </p>

          <p>
            <StyledHighlightedSpan>Example Scenarios</StyledHighlightedSpan>
            <br />
            To gain a perspective, if we turned over R$100,000 (Robux) a month
            from one game (avg. of 500 con-current players), and if you owned
            just 10% of that, you would receive R$ 10,000. All with practically
            zero effort from you, holding no risk which we do hold, and without
            having a single skill toward game development, you own as much of
            the game as a full-time developer working on it.
          </p>

          <p>
            <StyledHighlightedSpan>Conclusion</StyledHighlightedSpan>
            <br />
            If that doesn't seem to be a lot to you (even though 80% of
            full-time developers work for less than that), that was just an
            example of 3 games which didn't do as well as we wanted (500
            con-current players). Just imagine the amount you will be receiving
            when involved in successful games of ours, front-page games, and
            imagine even multiple of these! Imagine earning R$ 50,000 to R$
            150,000 a month between however many games (could cash that out into
            $525 if you wanted). All of that from simply choosing our trained
            production teams to help produce your videos instead of random
            freelancers (or by joining us through other means).
          </p>
        </StyledInfoBlock>
      </StyledFlexbox>
      <Faq $bg="unset" questions={questions} answers={answers} />

      <LoneCtaSection to="/learn/production" actionText="Discover">
        <h2>Overhauling your Channel</h2>
        <p>
          Learn how we can help you with your channel. From a full editing &
          thumbnail team, to full marketing and management. All for the same
          effective cost!
        </p>
      </LoneCtaSection>
      <StyledSpacer />
    </section>
  );
};

export default LearnGame;

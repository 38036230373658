import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import NotePage from "components/organisms/NotePage";
import { Cta } from "components/atoms/CtaElements";
import { StyledHighlightedSpan } from "styles/atoms/SpanElements.styled";

import { ENDPOINT } from "js/data/constants";

const JoinVerify = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const verifyUser = () => {
    const urlParams = new URLSearchParams(search);
    const hash = urlParams.get("hash");

    // Hash verification
    if (hash) {
      axios
        .post(`${ENDPOINT}/api/auth/verify/hash`, { hash: hash })
        .then((res) => {
          console.log(res);
          navigate("/login");
        })
        .catch(console.error);
    } else {
      // Auth verification
      axios
        .post(`${ENDPOINT}/api/auth/youtube`)
        .then((res) => {
          console.log(res);
          const location = res.data.url;
          if (location) {
            window.location.href = location;
          }
        })
        .catch(console.error);
    }
  };

  return (
    <NotePage>
      <div>
        <h1>Authorize Account</h1>
        <p>
          Click <StyledHighlightedSpan>authorize</StyledHighlightedSpan> to
          verify your identity and gain access into your account.
        </p>
      </div>
      <Cta onClick={verifyUser}>Authorize</Cta>
    </NotePage>
  );
};

export default JoinVerify;

import { StyledFlexbox } from "styles/util/Flex.styled";
import {
  StyledInfoBlockH2,
  StyledInfoBlockP,
} from "styles/molecules/InfoBlock.styled";

import FadedImg from "components/atoms/FadedImg";

interface OverlayTextImgProps {
  $height?: string;
  $width?: string;
  $offsetX?: string;
  $offsetY?: string;
  $round?: string;

  // TODO: Correctly type this
  Title: any;
  Description: any;

  corner?: number;
  title: string;
  description?: string;
  image: string;
}

const OverlayTextImg = ({
  $height = "100%",
  $width = "100%",
  $offsetX = "60px",
  $offsetY = "48px",
  $round = "15px",
  Title = StyledInfoBlockH2,
  Description = StyledInfoBlockP,
  corner = 1,
  title,
  description,
  image,
}: OverlayTextImgProps) => {
  const offsetStyles: { [corner: number]: any } = {
    1: {
      align: "start",
      left: $offsetX,
      top: $offsetY,
    },
    2: {
      align: "end",
      right: $offsetX,
      top: $offsetY,
    },
    3: {
      align: "start",
      left: $offsetX,
      bottom: $offsetY,
    },
    4: {
      align: "end",
      right: $offsetX,
      bottom: $offsetY,
    },
  };

  const style = offsetStyles[corner];

  return (
    <div style={{ position: "relative", height: $height, width: $width }}>
      <StyledFlexbox
        $align={style.align}
        style={{
          ...{
            position: "absolute",
            zIndex: 1,
          },
          ...style,
        }}
      >
        <Title style={{ alignText: style.align }}>{title}</Title>
        {description && (
          <Description style={{ alignText: style.align }}>
            {description}
          </Description>
        )}
      </StyledFlexbox>
      <FadedImg $round={$round} src={image} />
    </div>
  );
};

export default OverlayTextImg;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import NotePage from "components/organisms/NotePage";
import { Cta } from "components/atoms/CtaElements";

import { ENDPOINT, SITE_URL } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const JoinReturn = () => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const hash = params.get("hash");

  useEffect(() => {
    // Given what you have to pass here via url, it can used to get the rest of user data on server
    const uidValue = params.get("email") || params.get("username");
    const uidName = params.get("email") ? "Email" : "Username";
    if (hash && uidValue) {
      getAccessToken().then((token) => {
        axios.put(
          `${ENDPOINT}/api/auth/verify/hash`,
          {
            uidName: uidName,
            uidValue: `'${uidValue}'`,
            // Last param is just to keep hash ending in characters like '.' from being excluded from the hyperlink (bug)
            link: `${SITE_URL}/join/verify?hash=${hash}&verify=true`,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
    }
  }, []);

  return (
    <NotePage>
      <div>
        <h3>Verification Email Sent</h3>
        <p>
          Check your email for a verification link we{" "}
          {hash ? "just sent you" : "sent you upon signing up"}. Clicking that
          link will activate your account, allowing you to then login.
        </p>
      </div>
      <Cta to="/login">Ok</Cta>
    </NotePage>
  );
};

export default JoinReturn;

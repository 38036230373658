import styled, { createGlobalStyle } from "styled-components";

interface StyledOverlay {
  $position?: string;
  $invisible?: boolean;
  $centered?: boolean;
  $padding?: string;
  $overflow?: string;
}

export const StyledOverlay = styled.div<StyledOverlay>`
  position: ${(props) => props.$position || "fixed"};
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => !props.$invisible && "rgba(0, 0, 0, 0.3)"};
  z-index: 10;

  padding: ${(props) => props.$padding || "50px"};

  align-items: ${(props) => (props.$centered ? "center" : "start")};
  justify-content: center;

  overflow-y: ${(props) => props.$overflow || "scroll"};
`;

export const StyledContainer = styled.div`
  background-color: var(--bgShadow);
  border-radius: 8px;
  padding: 55px 90px;
  width: min(80%, 1000px);
`;

export const GlobalScrollOverride = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

// TODO: Name change? It's just a background right now (blur didn't work)
export const StyledBlurOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;

  background-image: linear-gradient(
    to bottom right,
    var(--bgHighlightGradientFirst),
    var(--bgHighlightGradientLast)
  );
  /* backdrop-filter: blur(100px); */
  opacity: 0.21;
`;

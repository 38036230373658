import styled from "styled-components";

export const StyledChat = styled.div`
  width: 100%;
  height: 1000px; // TODO: When upgrading whole page to grid, this will take up area instead

  display: grid;
  grid-template-areas:
    "sidebar topbar"
    "sidebar chat"
    "sidebar send";
  grid-template-columns: 250px 1fr;
  grid-template-rows: 60px 1fr 60px;

  .sidebar {
    grid-area: sidebar;
    background: var(--bgDarkAccent);
    padding: 10px;

    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 15px;
    position: relative;

    .tab {
      padding: 10px;
      border-radius: 3px;
      width: 100%;

      background: var(--bgLightHighlight);

      &:hover {
        filter: brightness(1.1);
      }

      &:active {
        background: linear-gradient(
          var(--actionPrimaryGradientFirst),
          var(--actionPrimaryGradientMiddle),
          var(--actionPrimaryGradientLast)
        );

        p {
          color: white;
        }
      }
    }

    & > div {
      display: flex;
      align-items: inherit;
      flex-direction: column;
      gap: 5px;
      width: 100%;

      & > p {
        color: var(--defaultDark);
      }
    }
  }

  .topbar {
    background: var(--bgDarkAccent);
    padding: 0 60px;

    display: flex;
    align-items: center;
    justify-content: start;
    grid-area: topbar;
  }

  .chat {
    padding: 20px 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    grid-area: chat;
  }

  .chatbar {
    padding: 0 60px;
    grid-area: send;
  }
`;

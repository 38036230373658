import { useContext } from "react";
import axios from "axios";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

import getUploadID from "js/getUploadID";
import { ENDPOINT } from "js/data/constants";

import { UploadContext } from "components/function/contexts/UploadContext";
import getAccessToken from "js/features/auth/getAccessToken";

const CancelBtn = ({ setUploadProgress, setIsUploading, progressUri }) => {
  const [[progressUris, setProgressUris], [fileDatas, setFileDatas]] =
    useContext(UploadContext);

  const cancelUpload = async () => {
    const uploadID = getUploadID(progressUri);
    axios
      .get(`${ENDPOINT}/api/drive/cancel/${uploadID}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .catch((err) => {
        console.warn(`Upload cancellation failed!`, err);
      });
    setUploadProgress(0);
    setIsUploading(false);

    const updatedProgressUris = progressUris.filter(
      (uri) => uri !== progressUri
    );
    setProgressUris(updatedProgressUris);

    const newVideoNames = Object.fromEntries(
      Object.entries(fileDatas).filter(([uri]) => uri !== progressUri)
    );
    setFileDatas(newVideoNames);
  };

  return (
    <HoverFlagBtn
      $variant="Small"
      title="Cancel"
      src="/images/buttons/cancel.png"
      onClick={cancelUpload}
    />
  );
};

export default CancelBtn;

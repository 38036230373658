import { StyledFlexbox, StyledGrid } from "styles/util/Flex.styled";

import { BeamIncrementor } from "components/atoms/buttons/TextButtons";
import FadedImg from "components/atoms/FadedImg";

export interface BasketItemProps {
  currentValue: number;
  setCurrentValue: (value: number) => void;
}

const BasketItem = ({ currentValue, setCurrentValue }: BasketItemProps) => {
  return (
    <StyledFlexbox
      $width="350px"
      $row
      $height="120px"
      $bg="var(--bgLightHighlight)"
      $round="5px"
      aria-label="basket-item"
    >
      <FadedImg $height="100%" $width="120px" $round="5px 0 0 5px" />

      <StyledFlexbox
        $row
        $justify="space-between"
        $width="100%"
        $height="100%"
        $pad="10px"
        aria-label="content"
      >
        <StyledFlexbox $justify="space-between" $height="100%">
          <h3>Video Edit</h3>
          <StyledFlexbox
            $row
            $align="center"
            $width="100%"
            aria-label="quantity-buttons"
          >
            <BeamIncrementor
              label="Duration"
              currentValue={currentValue}
              setCurrentValue={setCurrentValue}
            />
          </StyledFlexbox>
        </StyledFlexbox>

        <StyledGrid $align="end" $height="100%" aria-label="item-math">
          <p style={{ lineHeight: "1rem", textAlign: "end" }}>$1/min</p>
          <p style={{ lineHeight: "1rem", textAlign: "end" }}>x 3</p>
          <p style={{ lineHeight: "1rem", textAlign: "end" }}>x 3</p>
          <h3
            style={{
              fontSize: "20px",
              textAlign: "end",
              justifySelf: "end",
            }}
          >
            $9
          </h3>
        </StyledGrid>
      </StyledFlexbox>
    </StyledFlexbox>
  );
};

export default BasketItem;

import { forwardRef, useEffect, useRef, useState } from "react";

import {
  StyledFormInput,
  StyledFormTextArea,
} from "./styles/InputElements.styled";
import { StyledLineBorder } from "styles/molecules/LineBorder.styled";

const FormInput = forwardRef(function (props, ref) {
  if (props.type === "text") {
    return <StyledFormTextArea ref={ref} {...props} />;
  }
  return <StyledFormInput ref={ref} {...props} />;
});

const FormItem = ({ placeholder, initialValue, style, ...props }) => {
  const formInputRef = useRef(null);

  const [isFocussed, setIsFocused] = useState(initialValue != undefined);
  const [isValid, setIsValid] = useState(initialValue != undefined);

  useEffect(() => {
    if (formInputRef.current && initialValue)
      formInputRef.current.value = initialValue;
  }, [formInputRef]);

  return (
    <StyledLineBorder
      $pad="18px"
      $textCol={isFocussed ? "var(--primary)" : ""}
      $col={isFocussed ? "var(--actionPrimary)" : ""}
      style={{
        ...style,
        ...{ height: props.type === "text" && props.$large ? "200px" : "" },
      }}
      {...props}
    >
      <FormInput
        ref={formInputRef}
        placeholder={isFocussed ? placeholder : ""}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => {
          setIsValid(e.target.value != "");
        }}
        {...props}
      />
    </StyledLineBorder>
  );
};

export const EmbeddedFormItem = forwardRef(function (
  { $width, style, ...props },
  ref
) {
  return (
    <form ref={ref}>
      <br />
      <FormItem
        $large={true}
        type="text"
        required
        style={{ ...{ width: $width }, ...style }}
        {...props}
      />
      <br />
    </form>
  );
});

export default FormItem;

import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledInfoBlock } from "styles/molecules/InfoBlock.styled";
import { StyledCompareContainer } from "styles/molecules/CompareContainer.styled";
import { StyledImgStrip } from "styles/molecules/StripBanner.styled";

import TopBanner from "_components/molecules/structure/TopBanner";
import GlassSection from "components/organisms/GlassSection";
import PackageOption from "_components/molecules/displays/PackageOption";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";
import { LinkSpan } from "components/atoms/SpanElements";

const dividers = ["You Want"];

const partner = [
  [
    ["To grow your channel"],
    ["To collaborate with larger YouTubers"],
    ["To upload more frequently"],
    ["To have higher quality videos"],
    ["To have higher quality thumbnails"],
    [
      "To earn passive income from multiple Roblox games of your choice, constantly (larger cuts available)",
    ],
    [
      "To receive effective, free advertising and marketing for your brand across Roblox, and social media constantly",
    ],
    ["To have a personal team of video editors and/or thumbnail artists"],
    [
      "To have access to our entire site, rewards, special offers and new features that are introduced",
    ],
    [
      "To be directly associated with our brand as we grow, promoting your brand where ever our brand appears",
    ],
    ["To be partnered with each Roblox game you choose"],
  ],
];

const affiliate = [
  [
    [
      "To earn passive income from a Roblox game of your choice, constantly (smaller cut)",
    ],
    [
      "To earn passive income from sales made on our site by Partners who joined via your Affiliate Link",
    ],
  ],
];

const affiliateDividers = ["Features", "Stats", "Requirements"];
const affiliatePlan = [
  [
    ["Game Profits", true],
    ["Free Advertising", false],
    ["Collaborations", false],
    ["Personal Production Team", false],
    ["Out-of-game Advertising", false],
    ["In-game Advertising", false],
    ["Access To All Special Offers", false],
    ["Access To All Site Features", false],
    ["Priority Privilege", false],
    ["Site Profits", true],
  ],
  [
    ["Minimum Percentage Ownership Per-Game", "1%"],
    ["Maximum Percentage Ownership Per-Game", "1/5"],
    ["Maximum Games Selectable", "1"],
    ["Percentage Cut Of Site Sale Profits", "20%"],
  ],
  [["Minimum YouTube Subscriber Count", "100,000"]],
];

const ExploreAffiliate = () => {
  return (
    <section>
      <TopBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h2>The Affiliate Program</h2>
        <p style={{ maxWidth: "500px" }}>
          All information regarding being an Affiliate with our company
        </p>
      </TopBanner>

      <StyledSpacer $pad="150px" />
      <StyledInfoBlock $align="start" $width="short">
        <h2 style={{ margin: "0 0 30px 0" }}>Overview</h2>
        <h3 style={{ margin: "0" }}>What It Is</h3>
        <p>
          Like you can guess from the word "Affiliate", you will be "affiliated"
          with our company by signing up as an Affiliate. This differs from a
          normal signup on our site as a "Partner".
        </p>
        <h3 style={{ margin: "0" }}>How It Works</h3>
        <p>
          Upon creation of your "Affiliate" account. You receive a personal
          "affiliate link" on your dashboard. You are then able to share this
          link and promote it in order to get aspiring and upcoming youtubers to
          signup to our site as a "Partner", so that we can then help them to
          grow their brand by providing them with our many services.
        </p>
      </StyledInfoBlock>

      <StyledSpacer $pad="150px" />
      <StyledFlexbox
        $gap="100px"
        $align="center"
        $pad="0 0 200px 0"
        $bg="linear-gradient(to bottom left, var(--bgShadow), var(--bgShadowDarker))"
      >
        <StyledImgStrip $pos="50%" src="/images/astral-gfx.jpg" />
        <StyledInfoBlock $align="center" $width="full">
          <h2>Commission Payouts</h2>
          <p>
            Each Partner that signs up using your "affiliate link" will earn you
            passive income by making purchases on our site. Whenever they
            purchase a video edit or a video thumbnail, you will receive a
            percentage commission from each sale made, paid into whatever
            payment account you linked in{" "}
            <LinkSpan to="/dashboard/settings">settings</LinkSpan>.
          </p>
        </StyledInfoBlock>
      </StyledFlexbox>

      <StyledFlexbox
        $gap="100px"
        $align="center"
        $pad="0 0 200px 0"
        $bg="linear-gradient(to bottom left, var(--bgShadow), var(--bgShadowDarker))"
      >
        <StyledImgStrip $pos="50%" src="/images/astral-gfx.jpg" />
        <StyledInfoBlock $align="center" $width="full">
          <h2>Game Profits</h2>
          <p>
            Secondly, just like our Partners do, you get access to the{" "}
            <LinkSpan to="/dashboard/game">game manager</LinkSpan>. This allows
            you select from a list our constantly growing collection of games we
            either own, manage or own shares of. From these games, you can
            select which one you want to be involved in, or change your
            selection monthly.
          </p>
          <p>
            This selection of involvement will make you an instant, official
            shareholder in the game's monthly profits. The amount of percentage
            you own of the game's monthly profits can be increased by
            advertising our brand, but in a slightly different way! We review
            your advertising of our brand when you submit it manually into the{" "}
            <LinkSpan to="/dashboard/game">ownership booster</LinkSpan>. We then
            choose whether the advertising matches one of the{" "}
            <LinkSpan to="/dashboard/game">advertising options</LinkSpan>{" "}
            available, and either accept or reject your request. If your request
            is accepted, your percentage ownership of the selected game will
            increase, earning you more money from the game's success!
          </p>
        </StyledInfoBlock>
      </StyledFlexbox>

      <GlassSection>
        <StyledCompareContainer>
          <PackageOption
            title="Partner"
            description="When a production team is needed"
            dividers={dividers}
            entries={partner}
          />
          <PackageOption
            title="Affiliate"
            description="When a production team is not needed"
            dividers={dividers}
            entries={affiliate}
          />
        </StyledCompareContainer>
      </GlassSection>

      <StyledSpacer $pad="150px" />
      <StyledCompareContainer $long>
        <StyledInfoBlock $width="head">
          <h2>Join for Free</h2>
          <p>
            Get your link instantly upon signing up, share it, and start earning
            instantly!
          </p>
        </StyledInfoBlock>
        <PackageOption
          title="Affiliate Plan"
          description="Earn without spending"
          dividers={affiliateDividers}
          entries={affiliatePlan}
          to="/join?type=Affiliate"
          alt
        />
      </StyledCompareContainer>

      <StyledSpacer $pad="150px" />
    </section>
  );
};

export default ExploreAffiliate;

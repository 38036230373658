import styled from "styled-components";

export const StyledAlertNote = styled.p`
  padding: 18px 40px;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: var(--bgDarkAccent);
  color: var(--error);
  width: 100%;
  min-height: 40px;
`;

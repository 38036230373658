import React from "react";

import { StyledFormOption } from "./styles/InputElements.styled";

const FormOption = ({
  children: text,
  value,
  name,
  inputType,
  selectedOption,
  setSelectedOption,
  ...props
}) => {
  return (
    <StyledFormOption>
      <input
        value={value}
        onClick={(e) => {
          if (setSelectedOption) setSelectedOption(value);
        }}
        type={inputType || "radio"}
        // onChange & checked in combination mean for controlling manually. (defaultChecked alone would be automatic)
        onChange={() => {
          if (setSelectedOption) setSelectedOption(value);
        }}
        checked={
          typeof selectedOption === "boolean"
            ? selectedOption
            : selectedOption === value
        }
        name={name}
        {...props}
      />
      {text}
    </StyledFormOption>
  );
};

export default FormOption;

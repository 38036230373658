import styled from "styled-components";

import { StyledFlexbox, StyledGrid } from "styles/util/Flex.styled";

interface StyledMessage {
  $alt?: boolean;
}

interface ChatMessage {
  altLayout?: boolean;
}

export const StyledMessage = styled.div<StyledMessage>`
  background-color: ${(props) =>
    props.$alt ? "var(--bgDarkShadow)" : "var(--bgTintShadow)"};
  padding: 15px;

  & > p {
    color: white;
  }
`;

const ChatMessage = ({ altLayout }: ChatMessage) => {
  return (
    <StyledGrid $gap="10px">
      <StyledFlexbox
        $reversed={altLayout}
        $row
        $justify="space-between"
        style={{ gridArea: "1 / 2 / 2 / 3" }}
      >
        <p style={{ color: "var(--defaultTint)" }}>Molly</p>
        <p style={{ color: "var(--defaultTintDark)" }}>1:30 AM, 3rd Dec 2024</p>
      </StyledFlexbox>

      <img
        src="/images/icons/user.png"
        style={{ gridArea: altLayout ? "2 / 3 / 3 / 4" : "2 / 1 / 3 / 2" }}
      />
      <StyledMessage $alt={altLayout} style={{ gridArea: "2 / 2 / 3 / 3" }}>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      </StyledMessage>
    </StyledGrid>
  );
};

export default ChatMessage;

import { StyledHoverFlagBtn } from "../../../styles/atoms/buttons/IconButtons.styled";

interface HoverFlagBtn extends StyledHoverFlagBtn {
  $variant?: "Small";
  title: string;
  src: string;
  children?: string;
  [key: string]: any;
}

const variants = {
  Small: {
    $flagWidth: "auto",
    $pad: "2px 8px 2px 8px",
  },
};

const HoverFlagBtn = ({
  $variant,
  title,
  src,
  children: description,
  ...props
}: HoverFlagBtn) => {
  const variant = $variant ? variants[$variant] : {};

  return (
    <StyledHoverFlagBtn {...variant} {...props}>
      <div className="hover-flag">
        <p>{title}</p>
        {description && <p>{description}</p>}
      </div>
      <img src={src} />
    </StyledHoverFlagBtn>
  );
};

export default HoverFlagBtn;

import { StyledFlexbox } from "styles/util/Flex.styled";

import PackageOption, {
  Pallet,
} from "_components/molecules/displays/PackageOption";
import { StyledCompareContainer } from "styles/molecules/CompareContainer.styled";

const ProductionPlans = () => {
  const dividers = ["Earnings"];
  const basic = [
    [
      ["Edit", "$10", "10 minutes of running time"],
      ["Thumbnail", "$5.00"],
    ],
  ];
  const premium = [
    [
      ["Edit", "$15", "10 minutes of running time"],
      ["Thumbnail", "$5.00"],
    ],
  ];
  const pro = [
    [
      ["Edit", "$20", "10 minutes of running time"],
      ["Thumbnail", "$5.00"],
    ],
  ];

  return (
    <StyledCompareContainer>
      <PackageOption
        pallet={Pallet.Secondary}
        title="Tier 1"
        description="Beginner Skill Required"
        entries={basic}
        dividers={dividers}
      />
      <PackageOption
        pallet={Pallet.Primary}
        title="Tier 2"
        description="Intermediate Skill Required"
        entries={premium}
        dividers={dividers}
      />
      <PackageOption
        pallet={Pallet.Tertiary}
        title="Tier 3"
        description="Advanced Skill Required"
        entries={pro}
        dividers={dividers}
      />
    </StyledCompareContainer>
  );
};

export default ProductionPlans;

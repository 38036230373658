import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "_components/molecules/structure/Header";

import { Chart } from "components/molecules/CompanyStats";

import { ENDPOINT } from "js/data/constants";
import { StyledSpacer } from "styles/util/Spacer.styled";

const CompanyStatView = () => {
  const [companyStats, setCompanyStats] = useState([]);

  function loadCompanyStats() {
    axios
      .get(`${ENDPOINT}/api/global/drive/stats?name=CompanyStats`, {
        responseType: "blob", // Set the response type to 'blob'
      })
      .then(async ({ data: blob }) =>
        setCompanyStats(JSON.parse(await blob.text()))
      )
      .catch((error) => {
        console.error("Download Failed! Error", error);
      });
  }

  useEffect(() => {
    loadCompanyStats();
  }, []);

  return (
    <section style={{ position: "relative", padding: "110px 0 160px 0" }}>
      <Header
        $align="start"
        title="The Charts"
        description="Here you can see the stats of the company from September 2023."
      />

      <StyledSpacer />
      <Chart stats={companyStats} />
    </section>
  );
};

export default CompanyStatView;

import { StyledFlexbox, StyledSbs } from "styles/util/Flex.styled";
import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledInfoBlock } from "styles/molecules/InfoBlock.styled";
import { StyledWrappedImg } from "styles/atoms/FadedImg.styled";

import { XPattern } from "_components/molecules/structure/Patterns";
import Header from "_components/molecules/structure/Header";

import { CtaInlineText } from "components/atoms/CtaElements";

const OurBrand = () => {
  return (
    <section
      style={{
        position: "relative",
        padding: "110px 0 160px 0",
        backgroundImage:
          "linear-gradient(to bottom right, var(--bgShadow), var(--bgShadowDarker))",
      }}
    >
      {/* Rises main content above pattern */}
      <div style={{ position: "relative", zIndex: 1 }}>
        <Header
          $align="center"
          title="Our Services"
          description="Lorem ipsum dolor sit amet, consetetur"
        />

        <StyledSpacer $pad="200px" />

        <StyledFlexbox $gap="210px" $align="center">
          <StyledSbs $align="center" $gap="120px">
            <StyledWrappedImg
              style={{ height: "489px", width: "703px" }}
              src="/content/games/astral/stairs-scene.jpg"
            />
            <StyledInfoBlock $width="long">
              <h3>We hand you a full production team</h3>
              <p>
                Let us remove the headache for you and let us just hand you a
                full team of any number of artists and/or editors.
              </p>
              <p>
                Your designated production team can adapt to your video style
                through the team training routines conducted between themselves,
                done in order to achieve your desired style accurately.
              </p>
              <p>
                Each editor/artist on the team charges the same amount
                per-video, costing you the same amount for every video.
              </p>
              <CtaInlineText $hover="arrow" to="/learn/production">
                View a full breakdown of all benefits of our production teams
              </CtaInlineText>
            </StyledInfoBlock>
          </StyledSbs>
          <StyledSbs $reversed $align="center" $gap="120px">
            <StyledWrappedImg
              style={{ height: "489px", width: "703px" }}
              src="/content/games/labyrinth/boss-fight.jpg"
            />
            <StyledInfoBlock $width="long">
              <h3>We advertise your channel for free</h3>
              <p>
                By joining us, you become a partner. Through our constantly
                increasing game studios and games, we will advertise your
                channel and your internet personality across these games for
                free. Using our developer connections, we can also have your
                channel advertised across other studios.
              </p>
              <p>
                Our marketing strategies are highly effective and unique, while
                requiring zero effort or intervention from you.
              </p>
              <p>
                Stay with us for a while, and soon enough you could appear in
                many front-page games, all across Roblox.
              </p>
              <p>
                In return for this, all you have to do is promote these games to
                your audience on the rare occasion that we request it, like on
                release day. Completely optionally.
              </p>
              {/* <p>
                You benefit from us advertising your channel, and you also benefit
              from promoting the games, as the more success these games
              experience, the more players will be directed to your Youtube
              channel.
                </p> */}
              <CtaInlineText $hover="arrow" to="/learn/game">
                View a full breakdown of all benefits from our games
              </CtaInlineText>
            </StyledInfoBlock>
          </StyledSbs>
          <StyledSbs $align="center" $gap="120px">
            <StyledWrappedImg
              style={{ height: "489px", width: "703px" }}
              src="/content/games/listen/duo.jpg"
            />
            <StyledInfoBlock $width="long">
              <h3>You earn a percentage of entire profits from our games</h3>
              <p>
                By agreeing to promote the games in order to be advertised
                in-game etc. You also gain a percentage share of those game’s
                total profits. (You can be involved in multiple games at once).
              </p>
              <p>
                This means that whenever a player purchases an item in game, you
                get a percentage of that purchase’s profit.
              </p>
              <p>
                Your percentage ownership of the game is calculated based on how
                much you have promoted the game as well as how many other
                youtuber clients who have also agreed to promote it just like
                you.
              </p>
              <CtaInlineText $hover="arrow" to="/learn/game">
                View a full breakdown of all benefits from our games
              </CtaInlineText>
            </StyledInfoBlock>
          </StyledSbs>
        </StyledFlexbox>
      </div>

      <XPattern $top="50px" $left="15%" $origin="-50%, -50%" />
      <XPattern $top="65%" $right="200px" $origin="50%, -50%" />
      <XPattern $bottom="100px" $left="15%" $origin="-50%, 50%" />
    </section>
  );
};

export default OurBrand;

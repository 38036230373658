import styled from "styled-components";

interface StyledPattern {
  $origin?: string;
  $top?: string;
  $right?: string;
  $bottom?: string;
  $left?: string;
}
interface StyledBackdropElipse extends StyledPattern {
  $radius?: string;
  $sizeX?: string;
  $sizeY?: string;
  $color: string;
  $opacity?: string;
  $blurRadius?: string;
}

const StyledPattern = styled.img<StyledPattern>`
  height: 537px;
  position: absolute;
  z-index: 0;
  // Centers the image relative to where you want it to move toward
  transform: translate(${(props) => props.$origin});
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  left: ${(props) => props.$left};
`;

export const StyledBackdropElipse = styled(StyledPattern)<StyledBackdropElipse>`
  height: ${(props) => props.$radius || props.$sizeX};
  width: ${(props) => props.$radius || props.$sizeY};
  background-color: ${(props) => props.$color || "white"};
  border-radius: 50%;
  z-index: -2;
  filter: ${(props) =>
    props.$blurRadius ? `blur(${props.$blurRadius})` : "blur(100px)"};
  opacity: ${(props) => props.$opacity || "0.4"};
`;

export const XPattern = (props: StyledPattern) => {
  return <StyledPattern {...props} src="/images/pattern/x-pattern.png" />;
};

import styled, { keyframes, css } from "styled-components";
import { NavLink as Link, useNavigate } from "react-router-dom";
import { StyledFlexbox } from "styles/util/Flex.styled";

const flowGradientAnim = keyframes`
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 200% 0;
  }
`;

const glimmerAnim = keyframes`
  0%, 100% {
    box-shadow: 0 0 0 0 var(--actionPrimary);
  }

  50% {
    box-shadow: 0 0 10px 0 var(--actionPrimary);
  }
`;

export const CtaStyle = css`
  --height: ${(props) =>
    props.$size === "long" ? "60px" : props.$size === "end" ? "41px" : "50px"};
  --width: ${(props) =>
    props.$size === "long"
      ? "450px"
      : props.$size === "end"
      ? "100%"
      : "217px"};
  --radius: ${(props) => (props.$size === "end" ? "5px" : "8px")};

  display: block;
  position: relative;
  height: var(--height);
  width: var(--width);
  cursor: pointer;
  user-select: none;
  transition: transform 0.09s;
  text-align: center;

  // Remove styling from <Link>
  text-decoration: none;

  .txt {
    cursor: pointer;
    height: 100%;
    width: 100%;

    color: white;

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    z-index: 1;
    transition: all 0.1s ease-in-out;
  }

  .box {
    cursor: pointer;
    display: block;
    position: absolute;
    border-radius: var(--radius);

    top: 0;
    height: 100%;
    width: 100%;

    border: solid 0px var(--actionPrimary);
    transition: all 0.1s;

    overflow: hidden;
    /* animation: 2.5s ease-in-out infinite ${glimmerAnim}; */
  }

  .box::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
    background-size: 200%;
    background-image: linear-gradient(
      to right,
      var(--actionPrimaryGradientFirst),
      var(--actionPrimaryGradientMiddle),
      var(--actionPrimaryGradientLast),
      var(--actionPrimaryGradientMiddle),
      var(--actionPrimaryGradientFirst)
    );

    animation: 5s linear infinite ${flowGradientAnim};

    opacity: 1;
    transition: all 0.1s ease-in-out;
  }

  ${(props) =>
    props.$disabled &&
    css`
      .box::before {
        background: var(--bgOutline);
      }
    `}

  .box::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
    background-size: 300%;
    background-color: var(--bgOutline);

    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    .box {
      animation: unset;
    }

    .box::before {
      opacity: 0;
    }

    .box::after {
      opacity: 1;
    }
  }

  &:active {
    transform: scale(0.99);
  }

  @media screen and (max-width: 700px) {
    height: calc(var(--height) * 0.9);
    width: calc(var(--width) * 0.9);

    .txt {
      font-size: 0.95rem;
    }
  }
`;

export const CtaElement = ({ children: text, ...props }) => {
  return (
    <Link {...props}>
      <p className="txt">{text}</p>
      <div className="box"></div>
    </Link>
  );
};

export const StyledCta = styled.div`
  ${CtaStyle};
`;

export const Cta = styled(CtaElement)`
  ${CtaStyle};
`;

export const StickyCta = styled(Cta)`
  top: 50px;
  right: 50px;
  position: fixed;
  z-index: 10;

  transition: 0.2s transform ease-in-out;

  transform: ${({ $isEnabled }) =>
    $isEnabled ? "translateY(0)" : "translateY(-300%)"};
`;

export const CtaAlt = styled(Cta)`
  .box {
    border: solid 2px var(--actionPrimary);
    animation: unset;
  }

  .box::before {
    opacity: 0;
  }

  &:hover .box {
    border: solid 2px var(--bgOutline);
  }
`;

export const CtaStatic = styled(Cta)`
  .txt {
    color: ${(props) => props.$textCol || props.$hoverCol};
  }

  &:hover {
    .txt {
      color: ${(props) => props.$textHoverCol || props.$col};
    }
  }

  .box::before {
    background: ${(props) => props.$col};
  }

  .box::after {
    background: ${(props) => props.$hoverCol};
  }
`;

const SquareCtaStyle = css`
  height: calc(var(--height) - 15px);

  .txt {
    font-size: 1rem;
  }

  .box {
    border-radius: 5px;
    animation: unset;
  }

  .box::after {
    border-radius: 5px;
  }

  .box::before {
    border-radius: 5px;
    animation: ${(props) => !props.$animated && "unset"};
  }
`;

export const SquareCtaAlt = styled(CtaAlt)`
  ${SquareCtaStyle}
`;

export const SquareCta = styled(Cta)`
  ${SquareCtaStyle}
`;

const StyledCtaInlineText = styled.a`
  color: ${(props) => props.$col || "var(--primaryDim)"};
  position: relative;

  cursor: pointer;
  user-select: none;

  &:hover {
    ${(props) => {
      switch (props.$hover) {
        case "arrow":
          return css`
            &::after {
              --x-offset: 30%;
            }
          `;
        case "highlight":
          return css`
            filter: brightness(1.2);
            transition: filter 0.1s ease-in-out;
          `;
        default:
          return css`
            text-decoration: underline;
          `;
      }
    }}
  }

  ${(props) =>
    props.$hover === "arrow" &&
    css`
      &::after {
        --x-offset: 0px;

        content: "";
        display: inline-block;
        position: relative;

        transform: translate(var(--x-offset), 15%) rotate(90deg);
        right: -0.7rem;

        width: 0.7rem;
        height: 0.7rem;
        background-color: var(--actionPrimary);

        clip-path: polygon(
          0 100%,
          50% 0,
          100% 100%,
          90% 100%,
          50% 50%,
          10% 100%
        );

        transition: all 0.2s ease-in-out;
      }
    `}
`;

export const CtaInlineText = ({ to, children: text, ...props }) => {
  const navigate = useNavigate();

  return (
    <StyledCtaInlineText onClick={() => navigate(to)} {...props}>
      {text}
    </StyledCtaInlineText>
  );
};

export const LoneCtaSection = ({ actionText, children, ...props }) => {
  return (
    <StyledFlexbox
      $bg="linear-gradient(to bottom right, var(--bgShadow), var(--bgShadowDarker))"
      $pad="100px min(100px, 10%)"
      $row
      $align="center"
      $width="100%"
      $justify="center"
      $gap="100px"
      $wrap
    >
      <div style={{ maxWidth: "500px" }}>{children}</div>
      <Cta {...props}>{actionText}</Cta>
    </StyledFlexbox>
  );
};

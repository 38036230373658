import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";

import Overlay from "components/organisms/Overlay";

import InteractableAlert from "_components/molecules/InteractableAlert";
import { CtaStatic, CtaInlineText } from "components/atoms/CtaElements";

interface AlertsProps {
  productId: number | string;
  productTitle: string;
  closeHandler: () => void;
}

const Alerts = ({ productId, productTitle, closeHandler }: AlertsProps) => {
  const alerts = [{}];

  return (
    <Overlay
      heading="Alerts"
      title={productTitle}
      src="/images/buttons/notification-static.png"
    >
      <p
        style={{
          width: "100%",
          textAlign: "end",
          color: "var(--error)",
          marginBottom: "15px",
        }}
      >
        Action Needed
      </p>
      <StyledFlexbox $gap="35px">
        {alerts.map(() => (
          <InteractableAlert />
        ))}
      </StyledFlexbox>

      <StyledSpacer $pad="60px" />

      <StyledFlexbox
        $width="100%"
        $row
        $gap="40px"
        $align="center"
        $justify="center"
      >
        <CtaInlineText
          $col="var(--secondaryDim)"
          to={`/dashboard/production/receive?active=${productId}`}
        >
          Track Progress
        </CtaInlineText>
        <CtaStatic
          $textCol="white"
          $col="var(--bgOutline)"
          $textHoverCol="var(--secondaryDim)"
          $hoverCol="var(--bgLightShadow)"
          onClick={closeHandler}
        >
          Done
        </CtaStatic>
      </StyledFlexbox>
    </Overlay>
  );
};

export default Alerts;

import { PropsWithChildren } from "react";
import { css } from "styled-components";

export interface HeightConstraintStyle
  extends PropsWithChildren<{ [key: string]: any }> {
  $height?: string;
  $width?: string;
}

export const ParentSizeConstraintStyle = css<HeightConstraintStyle>`
  height: ${(props) => props.$height || "100%"};
  width: ${(props) => props.$width};

  & > * {
    height: ${(props) => (!props.$width ? "100%" : "")};
    width: ${(props) => props.$width};
  }
`;

export const ChildSizeConstraintStyle = css<HeightConstraintStyle>`
  & > * {
    height: ${(props) => props.$height};
    width: ${(props) => props.$width};
  }
`;

import { useState } from "react";

import {
  StyledFlexbox,
  StyledGrid,
  StyledPairDisplay,
} from "styles/util/Flex.styled";
import { StyledBeamButtons } from "styles/atoms/buttons/TextButtons.styled";
import { StyledQuotedFlexbox } from "styles/atoms/Quoted.styled";

import Overlay from "components/organisms/Overlay";
import Header from "_components/molecules/structure/Header";
import { BeamButton } from "components/atoms/buttons/TextButtons";
import { Cta, CtaInlineText } from "components/atoms/CtaElements";

import StandaloneGallery from "components/organisms/StandaloneGallery";
import Stat from "components/atoms/Stat";

import gameDatas from "js/data/gameDatas";
import User from "components/atoms/User";

export interface InspectOverlayProps {
  title: string;
  studio: string;
  closeHandler: () => void;
}

const InspectOverlay = ({
  title,
  studio,
  closeHandler,
}: InspectOverlayProps) => {
  const [currentValue, setCurrentValue] = useState("Overview");

  const content = gameDatas.map((data) => {
    return {
      title: data.game,
      description: data.studio,
      image: data.preview[0].original,
    };
  });

  return (
    <Overlay
      $align="center"
      $gap="40px"
      Heading={Header}
      title={title}
      description={studio}
    >
      <StandaloneGallery content={content}></StandaloneGallery>
      <StyledBeamButtons>
        <BeamButton
          value="Overview"
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
        />
        <BeamButton
          value="Stats"
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
        />
        <BeamButton
          value="Testimonials"
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
        />
      </StyledBeamButtons>

      {currentValue === "Overview" && (
        <StyledGrid
          $width="min(715px, 100%)" // TODO: Same width as StandaloneGallery, make them share it (non-magic)
          $cols="repeat(auto-fit, minmax(300px, 1fr))"
          $gap="25px"
          $align="center"
          $justify="center"
          $text="start"
          aria-label="overview"
        >
          <StyledPairDisplay $width="100%">
            <p>Lorem ipsum</p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </p>
          </StyledPairDisplay>
          <StyledPairDisplay $width="100%">
            <p>Lorem ipsum</p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </p>
          </StyledPairDisplay>
          <StyledPairDisplay $width="100%">
            <p>Lorem ipsum</p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </p>
          </StyledPairDisplay>
          <StyledPairDisplay $width="100%">
            <p>Lorem ipsum</p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </p>
          </StyledPairDisplay>
        </StyledGrid>
      )}

      {currentValue === "Stats" && (
        <StyledGrid
          $width="min(715px, 100%)" // TODO: Same width as StandaloneGallery, make them share it (non-magic)
          $gap="20px"
          $align="center"
          $justify="center"
          $pad="15px"
          $cols="repeat(auto-fit, minmax(200px, 1fr))"
          aria-label="stats-grid"
        >
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
          <Stat
            icon="/images/buttons/helmet.png"
            value={3}
            label="Developers"
          />
        </StyledGrid>
      )}

      {currentValue === "Testimonials" && (
        <StyledFlexbox $row $gap="25px" $justify="center" $align="start">
          <StyledQuotedFlexbox
            $pad="50px"
            $gap="15px"
            $width="300px"
            $justify="center"
            $bg="var(--bgMediumHighlight)"
          >
            <User $row>
              <StyledPairDisplay $width="100%" $flipped>
                <p>Molly</p>
                <p>Partner</p>
              </StyledPairDisplay>
            </User>
            <p style={{ color: "white" }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore nonumy eirmod ut
              labore et dolore labore et dolore
            </p>
          </StyledQuotedFlexbox>
        </StyledFlexbox>
      )}

      <StyledFlexbox
        $width="100%"
        $row
        $gap="50px"
        $align="center"
        $justify="center"
        style={{ marginTop: "40px" }}
      >
        <CtaInlineText $col="var(--secondaryDim)" onClick={closeHandler}>
          Cancel
        </CtaInlineText>
        <Cta onClick={() => console.log(`Boosted '${title}'!`)}>Boost</Cta>
      </StyledFlexbox>
    </Overlay>
  );
};

export default InspectOverlay;

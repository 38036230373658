import { StyledFlexbox } from "styles/util/Flex.styled";
import {
  StyledInfoBlockH2,
  StyledInfoBlockP,
} from "styles/molecules/InfoBlock.styled";

import { centeredComponent } from "_components/function/modifiers/areaPositions";
import { PropsWithChildren } from "react";

interface IndentedFlexbox extends PropsWithChildren {
  $align: string;
}

interface Header extends PropsWithChildren {
  $align: string;
  title: string;
  description: string;
}

const CenteredFlexbox = centeredComponent(StyledFlexbox);
const indentSize = "12%";

const IndentedFlexbox = ({ $align, children }: IndentedFlexbox) => {
  return (
    <StyledFlexbox
      style={{
        marginLeft: $align === "start" ? indentSize : undefined,
        marginRight: $align === "end" ? indentSize : undefined,
      }}
    >
      {children}
    </StyledFlexbox>
  );
};

const Header = ({ $align, title, description, children }: Header) => {
  const Flexbox = $align === "center" ? CenteredFlexbox : IndentedFlexbox;

  return (
    <Flexbox $align={$align}>
      <StyledInfoBlockH2 $align={$align}>{title}</StyledInfoBlockH2>
      <StyledInfoBlockP $align={$align}>{description}</StyledInfoBlockP>
      {children}
    </Flexbox>
  );
};

export default Header;

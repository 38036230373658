import styled from "styled-components";
import { ScrollElement } from "../../../components/atoms/SpanElements";

export const ScrollToBtn = styled(ScrollElement)`
  height: 50px;
  width: 50px;
  position: relative;
  z-index: 1;

  background-image: linear-gradient(
    to bottom right,
    var(--bgHighlightGradientFirst),
    var(--bgHighlightGradientLast),
    var(--bgHighlightGradientFirst),
    var(--bgHighlightGradientLast),
    var(--bgHighlightGradientFirst)
  );
  transition: background-position 0.3s ease-in-out, scale 0.1s ease-in-out;
  background-size: 300%;
  border-radius: 10px;
  border: solid 1px var(--actionPrimary);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &::after {
    height: 100%;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/images/common/arrow.png");
    background-size: 26px 13px;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  &:hover {
    background-position: 50% 0;
  }

  &:active {
    scale: 0.98;
  }
`;

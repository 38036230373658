import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import {
  StyledPackageOption,
  StyledPlanEntry,
} from "_components/molecules/displays/PackageOption";
import { StyledCompareContainer } from "styles/molecules/CompareContainer.styled";

import GlassSection from "components/organisms/GlassSection";
import TopBanner from "_components/molecules/structure/TopBanner";
import Header from "_components/molecules/structure/Header";
import RotCircle from "_components/molecules/displays/circle/RotCircle";
import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";
import { LoneCtaSection } from "components/atoms/CtaElements";

import ProductionPlans from "features/shop/ProductionPlans";

import { productionInfoList } from "js/data/arrays/rotCircleInfoLists";

const ExploreProduction = () => {
  return (
    <section>
      <TopBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h2>Join the Production Teams</h2>
        <p style={{ maxWidth: "500px" }}>
          All information regarding video editors & thumbnails artists
          interested in joining us
        </p>
      </TopBanner>

      <StyledSpacer $pad="150px" />
      <StyledFlexbox
        $row
        $width="100%"
        $justify="space-around"
        $align="center"
        $pad="0 min(5%, 100px)"
        $gap="50px"
        $wrap
      >
        <Header
          $align="start"
          title="Our Company"
          description="Our production teams are the backbone of our company. We are always looking for new talent to join our teams."
        />

        <RotCircle infoList={productionInfoList} />
      </StyledFlexbox>
      <StyledSpacer $pad="150px" />

      <GlassSection>
        <StyledFlexbox $row $align="center" $justify="center" $gap="50px">
          <HoverFlagBtn
            title="Client Assigning"
            src="/images/buttons/team-icon.png"
          >
            You are assigned higher paying clients as you make a better
            impression across our site
          </HoverFlagBtn>
          <HoverFlagBtn
            title="Request More Time"
            src="/images/buttons/chat-icon.png"
          >
            If you can't fulfill work in time, you can ask for more time from
            your client
          </HoverFlagBtn>
          <HoverFlagBtn title="Time Off" src="/images/icons/active/sun.png">
            Request time off from all work for a period of time, and no one will
            bother you
          </HoverFlagBtn>
          <HoverFlagBtn
            title="Direct Payment"
            src="/images/icons/active/cart.png"
          >
            Upon completion of work, we send your earnings straight to your
            PayPal or other wallet
          </HoverFlagBtn>
        </StyledFlexbox>

        <StyledSpacer />

        <StyledCompareContainer>
          <StyledPackageOption $pallet="secondary">
            <div className="heading">
              <h2>Working on other Sites</h2>
              <p>As a Video Editor or Thumbnail Artist</p>
            </div>

            <div className="content">
              <StyledPlanEntry $value={false}>
                Places you in an ocean of competition, making you just another
                number in their system to fend for yourself
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Missing a deadline results in negative reviews from clients and
                massive stress when trying to also fulfill even more orders
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Your pricing is fixed. You have to charge low to match your
                competition and you can't increase it by much almost ever
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Work can be either quiet or noisy. It's inconsistent and can
                leave you with no money coming in if you aren't getting clients
                yourself
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                Hard to gain real connections if any, with big youtubers as you
                are usually not their full-time editor/artist
              </StyledPlanEntry>
              <StyledPlanEntry $value={false}>
                You have to jump from different games constantly with your
                production, not honing your skills into becoming exceptional at
                any one in particular
              </StyledPlanEntry>
            </div>
          </StyledPackageOption>

          <StyledPackageOption $pallet="primary">
            <div className="heading">
              <h2>Working with Us</h2>
              <p>As a Video Editor or Thumbnail Artist</p>
            </div>

            <div className="content">
              <StyledPlanEntry $value={true}>
                We don't need over 50 staff. You get complete attention and have
                any needs met from us directly. You're in a quiet pond of fish
                with no competition, when working with us
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                You can work by your schedule. Other staff can fill in for you
                when you can't meet a deadline
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                Your income only goes up. It is bound to increase over time by
                working with us through our unique system
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                Never be stuck without work. There will almost always be work to
                do for any of our clients, available for you to take on whenever
                you want
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                Gain permanent, real connections with upcoming and large
                youtubers who work with you through our company which could help
                you in the future
              </StyledPlanEntry>
              <StyledPlanEntry $value={true}>
                You only have to focus on Roblox related production. Making you
                excel at it over time through practice and not require you to
                jump from game to game
              </StyledPlanEntry>
            </div>
          </StyledPackageOption>
        </StyledCompareContainer>

        <StyledSpacer />

        <ProductionPlans />
      </GlassSection>

      <StyledSpacer />

      <LoneCtaSection to="/join/production" actionText="Get Started">
        <h2>Join the Teams</h2>
        <p>
          Start your journey and see if it's for you or not. No contracts.
          Whenever you want. Leave or return any time.
        </p>
      </LoneCtaSection>

      <StyledSpacer />
    </section>
  );

  // return (
  //   <StyledStandardEndSection>
  //     <TopHeadingBanner $pos="50%" src="/images/barrumble-gfx.jpg">
  //       <h1>Join the Production Teams</h1>
  //       <p>
  //         All information regarding video editors & thumbnails artists
  //         interested in joining us
  //       </p>
  //     </TopHeadingBanner>

  //     <StyledSpacer $pad="50px" />
  //     <ProductionRotCircle />

  //     <StyledSpacer />
  //     <ComparisonContainer title="Brand Comparison">
  //       <BrandComparisonPrimary />
  //       <BrandComparisonSecondary />
  //     </ComparisonContainer>

  //     <StyledSpacer $pad="400px" />
  //     <ProdSystemsOverview />

  //     <StyledSpacer $pad="400px" />
  //     <ProductionPlans />

  //     <StyledSpacer $pad="400px" />
  //     <LoneCtaSection to="/join/production/access-key">
  //       Join Production
  //     </LoneCtaSection>
  //   </StyledStandardEndSection>
  // );
};

export default ExploreProduction;

import styled from "styled-components";

export interface StyledProps {
  $alignSelf?: "start" | "center" | "end";
  $margin?: string;
  $minWidth?: string;
}

export interface IconHeadingProps extends StyledProps {
  heading: string;
  title: string;
  src: string;
}

const iconWidth = "100px";

const StyledIcon = styled.img`
  width: ${iconWidth};
  z-index: -1;

  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-60%, -30px);
`;

const StyledIconHeading = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-self: ${(props) => props.$alignSelf || "start"};
  position: relative;
  margin: ${(props) => props.$margin};
  // img won't scale proportionally if it's parent doesn't support it's size
  min-width: ${(props) => props.$minWidth};
`;

const IconHeading = ({ heading, title, src, ...props }: IconHeadingProps) => {
  return (
    <StyledIconHeading {...props}>
      <p>{heading}</p>
      <h2>{title}</h2>
      <StyledIcon src={src} />
    </StyledIconHeading>
  );
};

export default IconHeading;

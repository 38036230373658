import React from "react";
import axios from "axios";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

import getUploadID from "js/getUploadID";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const ResumeBtn = ({ setIsPaused, progressUri }) => {
  const resumeUpload = async () => {
    const uploadID = getUploadID(progressUri);
    axios
      .get(`${ENDPOINT}/api/drive/resume/${uploadID}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then(() => setIsPaused(false))
      .catch(console.log);
  };

  return (
    <HoverFlagBtn
      $variant="Small"
      title="Resume"
      src="/images/buttons/play.png"
      onClick={resumeUpload}
    />
  );
};

export default ResumeBtn;

import styled from "styled-components";

interface StyledNotification {
  $col?: "important" | "alt";
}

export const StyledNotification = styled.p<StyledNotification>`
  --color: ${(props) =>
    props.$col === "important"
      ? "white"
      : props.$col === "alt"
      ? "black"
      : "var(--secondaryDim)"};
  --size: 10px;

  color: var(--color) !important;
  position: relative;

  &::before {
    content: "";
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    background-color: var(--color) !important;

    position: absolute;
    display: block;
    left: -15px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

import User, { Status } from "components/atoms/User";

import { StyledDashboardBlock } from "_components/molecules/containers/DashboardBlock.styled";
import { StyledFlexbox, StyledPairDisplay } from "styles/util/Flex.styled";
import { StyledLabelledIcon } from "styles/atoms/LabelledIcon.styled";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

type TeamMember = {
  username: string;
};

const TeamMember = ({ username = "Molly" }: TeamMember) => {
  // TODO: Get user status (with video id if 'busy')
  const profession = "Editor";
  const status = "Busy";
  const productTrackId = 1;
  const editSpeed = 3;
  const thumbnailSpeed = 3;

  return (
    <StyledDashboardBlock $width="350px">
      <User $row>
        <StyledPairDisplay $width="auto" $flipped>
          <p>{username}</p>
          <p>{profession}</p>
        </StyledPairDisplay>
      </User>

      <Status
        $status={status}
        productTrackId={productTrackId}
        path={`/dashboard/production/receive?active=${productTrackId}`}
      />

      <HoverFlagBtn
        $variant="Small"
        title="Chat"
        src="/images/buttons/chat-button.png"
        style={{ marginTop: "25px" }}
      />

      <StyledFlexbox $align="end" $gap="5px">
        <StyledLabelledIcon $icon="/images/buttons/video.png">
          {editSpeed} Days
        </StyledLabelledIcon>
        <StyledLabelledIcon $icon="/images/buttons/thumbnail.png">
          {thumbnailSpeed} Days
        </StyledLabelledIcon>
      </StyledFlexbox>
    </StyledDashboardBlock>
  );
};

export default TeamMember;

import styled from "styled-components";

import { StyledSidebar } from "_components/molecules/containers/navigation/Sidebar.styled";

export const StyledBasket = styled(StyledSidebar)`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 50px;
  width: 450px;

  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    height: 100%;
    gap: 50px;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .summary-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .summary-total {
    color: white;
    align-self: end;
    text-align: end;
    margin-top: 10px;
    padding-top: 10px;

    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid ${(props) => props.$col || "var(--bgOutline)"};
    }
  }
`;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { StyledBottomNote } from "_components/features/file/styles/FileStruct.styled";
import { StyledContentDisplay } from "styles/molecules/ScrollContainer.styled";
import TabbedScroller from "components/molecules/TabbedScroller";

import IncomingFile from "_components/features/file/download/IncomingFile";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const DownloadSystem = ({ setFiles }) => {
  const { search } = useLocation();

  const [videoData, setVideoData] = useState([]);
  const [thumbnailData, setThumbnailData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const isDataLoaded = useRef(null);

  const [highlightedFileId, setHighlightedFileId] = useState(null);

  const fetchReceivedData = async () => {
    // Only run once in application, unless logout (which takes you off page anyways to refresh)
    if (!isDataLoaded.current) {
      isDataLoaded.current = true; // Debounce
      setIsLoading(true);

      axios
        .get(`${ENDPOINT}/api/payment/fulfillment/requests?type=fulfilled`, {
          headers: {
            Authorization: await getAccessToken(),
          },
        })
        .then((res) => {
          setVideoData(res.data.filter((data) => data.type === "Edit"));
          setThumbnailData(
            res.data.filter((data) => data.type === "Thumbnail")
          );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error while making the GET request:", error);
        });
    }
  };

  const fileHighlightObserver = () => {
    const queryParams = new URLSearchParams(search);
    const fileId = queryParams.get("fileid");
    if (fileId) {
      const matchingEdit = videoData.find((file) => file.id === fileId);
      const matchingThumbnail = thumbnailData.find(
        (file) => file.id === fileId
      );
      const matchingFile = matchingEdit || matchingThumbnail;
      if (matchingFile) {
        setHighlightedFileId(matchingFile.id);
      } else {
        setHighlightedFileId(null);
      }
    }
  };

  useEffect(() => {
    // fetchReceivedData();
    const res = {
      data: [
        {
          requestId: 1,
          type: "Edit",
          title: "Edit",
          message: "Edit 1",
          createdTime: "2021-09-01",
          id: "1oc8nI7t029Yh3q8qAJLSBKX-FeuCbhzl",
          isRevision: false,
          author: "a",
        },
        {
          requestId: "1",
          type: "Thumbnail",
          title: "Edit 1",
          message: "Edit 1",
          createdTime: "2021-09-01",
          id: "1oc8nI7t029Yh3q8qAJLSBKX-FeuCbhzl",
          isRevision: false,
          author: "User 1",
        },
      ],
    };

    setVideoData(res.data.filter((data) => data.type === "Edit"));
    setThumbnailData(res.data.filter((data) => data.type === "Thumbnail"));

    if (setFiles)
      setFiles({
        videos: res.data.filter((data) => data.type === "Edit"),
        thumbnails: res.data.filter((data) => data.type === "Thumbnail"),
      });
  }, []);
  useEffect(fileHighlightObserver, [videoData, thumbnailData]);

  if (true) return <></>;

  return (
    <>
      <TabbedScroller
        $tab1="Edits"
        $tab2="Thumbnails"
        style={{
          borderRadius: "10px 10px 0 0",
        }}
      >
        <StyledContentDisplay $align="center">
          {videoData.map((data, index) => (
            <IncomingFile
              key={index}
              data={data}
              $highlighted={highlightedFileId === data.id}
            />
          ))}
          <LoadingStatus status={isLoading ? 0 : videoData.length > 0} />
        </StyledContentDisplay>
        <StyledContentDisplay $align="center">
          {thumbnailData.map((data, index) => (
            <IncomingFile
              key={index}
              data={data}
              $highlighted={highlightedFileId === data.id}
            />
          ))}
          <LoadingStatus status={isLoading ? 0 : thumbnailData.length > 0} />
        </StyledContentDisplay>
      </TabbedScroller>

      <StyledBottomNote>
        <p style={{ color: "var(--exit-main)" }}>
          <b>Warning:</b> If you exit the <b>File Manager</b>, your downloads
          and uploads will be cancelled!
        </p>
      </StyledBottomNote>
    </>
  );
};

export default DownloadSystem;

import { StyledFlexbox } from "styles/util/Flex.styled";

interface StatProps {
  icon: string;
  value: number;
  label: string;
}

const Stat = ({ icon, value, label }: StatProps) => {
  return (
    <StyledFlexbox
      $row
      $gap="10px"
      $justify="center"
      $align="end"
      style={{ position: "relative", height: "30px" }}
    >
      <img src={icon} style={{ height: "80%", alignSelf: "center" }} />
      <p style={{ fontSize: "20px", color: "white" }}>{value}</p>
      <p>{label}</p>
    </StyledFlexbox>
  );
};

export default Stat;

import { styled, css } from "styled-components";

import { StyledLineBorder } from "styles/molecules/LineBorder.styled";

const ActiveLabelPositioningStyle = css`
  font-size: 1rem;
  transform: translateY(calc(-100% - 10px));
  padding-left: 0;
`;

const InputBlankStyle = css`
  color: white;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  height: ${(props) => (props.$large ? "200px" : "100%")};

  &::placeholder {
    color: var(--secondaryDim);
  }
`;

export const StyledFormItem = styled.div`
  --input-text-size: 0.95rem;

  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  justify-content: start;
  height: ${(props) => (props.$large ? "200px" : "40px")};

  & > label {
    color: #8989b1;
  }

  &::before {
    display: flex;
    align-items: center;
    justify-content: start;

    pointer-events: none;
    user-select: none;

    color: #8989b1;
    font-size: var(--input-text-size);
    padding-left: 10px;

    position: absolute;
    z-index: 1;

    transition: all 0.1s ease-in-out;

    content: ${({ $label }) => `"${$label}"`};
    transform: translateY(50%);

    ${({ $focus, $valid }) => {
      if ($focus) {
        return css`
          ${ActiveLabelPositioningStyle};
          color: #00b1ff;
        `;
      } else if ($valid) {
        return css`
          ${ActiveLabelPositioningStyle};
        `;
      }
    }};
  }
`;

export const StyledFormInput = styled.input`
  ${InputBlankStyle}
`;

export const StyledFormTextArea = styled.textarea`
  ${InputBlankStyle}
  resize: none;
`;

export const StyledInputItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => props.$column && "column"};
  gap: ${(props) => !props.$column && "30px"};
  padding-bottom: ${(props) => props.$column && "20px"};
  width: ${(props) => props.$width || "100%"};

  & > input {
    background-color: #2d2d46;
    border-radius: 5px;
    border-style: none;
    text-align: center;

    color: #e7e7f8;
    font-size: var(--input-text-size);

    height: 100%;
    width: 70px;
    position: relative;
    bottom: 0;

    padding: 5px;

    &::placeholder {
      color: #4d4d64;
    }
  }
`;

export const StyledFormOption = styled.label`
  --radius: 5px;

  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;

  text-align: left;

  color: var(--secondaryDim);

  & > input {
    position: relative;
    cursor: pointer;
    appearance: none;
    border: solid 2px var(--bgOutline);
    border-radius: var(--radius);
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      border-radius: var(--radius);
      position: absolute;
      height: 65%;
      width: 65%;

      background-image: linear-gradient(
        to bottom,
        var(--actionPrimaryGradientFirst),
        var(--actionPrimaryGradientMiddle),
        var(--actionPrimaryGradientLast)
      );

      transition: all 0.2s ease-in-out;
      opacity: 0;
    }

    &:checked::before {
      opacity: 1;
    }
  }
`;

export const StyledFormSelect = styled(StyledLineBorder)`
  --padding: 18px;

  position: relative;
  background: linear-gradient(
    to bottom left,
    var(--bgHighlightGradientFirst),
    var(--bgHighlightGradientLast)
  );

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  border-bottom: ${(props) => (props.$expanded ? "none" : "")};
  transition: unset;

  padding: var(--padding);

  option {
    position: relative;
    width: 100%;
    padding: 10px 20px;
    z-index: 3;

    user-select: none;

    color: var(--defaultTintDark);
    transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;

    &:hover {
      color: var(--defaultTint);
    }
  }

  option:not(:last-child) {
    border-bottom: solid 1px var(--bgOutline);
  }

  .heading {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    user-select: none;

    & > p {
      color: var(--secondaryDim);
    }

    & > img {
      height: 18px;
      rotate: ${(props) => (props.$expanded ? "-90deg" : "90deg")};
      user-select: none;
      transition: rotate 0.2s ease-in-out;
    }
  }

  .list {
    position: absolute;
    // If the font-size of <p> changes, the first value must change to match it
    // Inspect it. font-size is not it's actual height.
    top: calc(24px + var(--padding) * 2);
    // Matches thickness of parent border offset
    width: calc(2px + 100%);

    border: 1px solid ${(props) => props.$col || "var(--bgOutline)"};
    border-top: none;
    background: linear-gradient(
      to bottom left,
      var(--bgHighlightGradientFirst),
      var(--bgHighlightGradientLast)
    );
    z-index: 2;
  }
`;

import { useState } from "react";

import ConfirmSelectScreen from "_components/molecules/containers/overlays/ConfirmSelectScreen";
import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

const IconPopupBtn = ({
  $width,
  title,
  src,
  enabled,
  setEnabled,
  confirmSelectScreenInfo,
  onConfirmed,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleSelect = (event) => {
    event.preventDefault();
    if (!enabled) {
      setIsActive(false);
      return;
    }
    setIsActive(true);
  };

  const confirmed = async () => {
    onConfirmed();
    setEnabled(false);
  };

  return (
    <>
      {isActive && (
        <ConfirmSelectScreen
          $width={$width}
          noScrollLock={true}
          setIsActive={setIsActive}
          info={confirmSelectScreenInfo}
          onConfirmed={confirmed}
        />
      )}
      <HoverFlagBtn
        $enabled={enabled}
        $variant="Small"
        title={title}
        src={src}
        onClick={handleSelect}
        {...props}
      />
    </>
  );
};

export default IconPopupBtn;

import { useContext, useEffect } from "react";
import styled, { css } from "styled-components";

import { CtaElement, CtaStyle } from "../../../components/atoms/CtaElements";

import { NavExpand } from "../../../components/atoms/NavbarElements";

import { AccountContext } from "../../../components/function/contexts/AccountContext";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { useNavigate } from "react-router-dom";

export const NavProfileCta = styled(CtaElement)`
  ${CtaStyle};
  --height: 32px;
  --width: 92px;
  --radius: 8px;

  ${(props) =>
    props.$noBg &&
    css`
      .box::before {
        background: unset;
      }
    `}

  .box,
  .box::before {
    animation: unset;
  }
`;

const ProfileExpand = styled(NavExpand)`
  height: 100%;
  width: 80px;
  padding: 0;

  display: flex;
  flex-direction: row;
  gap: 5px;

  & > img {
    height: 20px;
    opacity: 0.7;
  }
`;

const HoverableIcon = styled.img`
  height: 20px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const NavProfileIcon = () => {
  const navigate = useNavigate();

  // Auto navigate to dashboard if user is already logged in
  // Doesn't update on location change, as we only want to catch upon the first render of the user on the site
  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/dashboard/home");
    }
  }, []);

  return (
    <HoverableIcon
      onClick={() => navigate("/dashboard/home")}
      src="/images/nav/user-profile.png"
    />
  );
};

export const NavProfile = () => {
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  if (loggedIn) {
    return <NavProfileIcon />;
  } else {
    return (
      <StyledFlexbox $row $gap="5px">
        <NavProfileCta $noBg to="/login">
          Log in
        </NavProfileCta>
        <NavProfileCta to="/join">Sign up</NavProfileCta>
      </StyledFlexbox>
    );
  }
};

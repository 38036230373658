import { useRef, useContext, useEffect } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import { ENDPOINT } from "js/data/constants";

import { UploadContext } from "components/function/contexts/UploadContext";
import getAccessToken from "js/features/auth/getAccessToken";
import styled from "styled-components";

const StyledInputOverlay = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

const UploadBtn = ({ directory, acceptTypes, setCanUpload, method }) => {
  const [[progressUris, setProgressUris], [fileDatas, setFileDatas]] =
    useContext(UploadContext);
  const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const userId = ReactSession.get("userid");
    if (!userId) {
      console.warn("no userid");
      return;
    }

    const file = e.target.files[0]; // Assuming a single file is selected
    if (!file) {
      console.warn("no file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    // Assign file name, as default file name if not custom name is given in directory
    if (!directory.split("/")[3]) {
      directory = directory.split("/");
      directory.push(file.name);
      directory = directory.join("/");
    }

    // Debounce for controlled uploading
    setCanUpload && setCanUpload(false);

    axios
      .post(
        `${ENDPOINT}/api/drive/upload/${directory}?type=${file.type}&method=${method}`,
        formData,
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then((res) => {
        const uri = res.data.uri;
        setProgressUris([...progressUris, uri]);
        setFileDatas({ ...fileDatas, [uri]: file });
      });

    e.target.value = null;
  };

  return (
    <StyledInputOverlay
      ref={inputRef}
      type="file"
      accept={acceptTypes}
      name="file"
      onChange={handleFileUpload}
    />
  );
};

export default UploadBtn;

import React, { useEffect, useRef, useState } from "react";

import { StyledGallery } from "_components/molecules/displays/gallery/Gallery.styled";

import OverlayTextImg from "components/molecules/OverlayTextImg";
import NodeNav from "components/molecules/NodeNav";

interface Content {
  title: string;
  description: string;
  image: string;
}

export interface StandaloneGalleryProps {
  content: Content[];
}

const StandaloneGallery = ({ content }: StandaloneGalleryProps) => {
  const [index, setIndex] = useState(0);
  const { title, description, image } = content[index];
  const currentTimeout: React.MutableRefObject<NodeJS.Timeout | null> =
    useRef(null);

  useEffect(() => {
    // Keep one instance always
    if (currentTimeout.current) {
      clearTimeout(currentTimeout.current);
      currentTimeout.current = null;
    }
    currentTimeout.current = setTimeout(() => {
      setIndex((index + 1) % content.length);
    }, 4000);
  }, [index]);

  return (
    <StyledGallery>
      <NodeNav
        nodeCount={content.length}
        selectedIndex={index}
        setSelectedIndex={setIndex}
      />
      <OverlayTextImg
        corner={4}
        title={title}
        description={description}
        image={image}
      />
    </StyledGallery>
  );
};

export default StandaloneGallery;

import styled from "styled-components";

export const StyledLinkSpan = styled.span`
  color: ${(props) => props.$col || "var(--primary)"} !important;
  text-decoration: ${(props) =>
    props.$inverted ? "none !important" : "underline !important"};
  cursor: pointer !important;
  transition: all 0.3s ease-in-out !important;

  &:hover {
    text-decoration: ${(props) =>
      props.$inverted ? "underline !important" : "none !important"};
  }
`;

export const StyledHoverSpan = styled.span`
  color: ${(props) => props.$col || "var(--primary)"};
  position: relative;
  display: inline-block;
  text-align: ${(props) => props.$align};
  padding-right: 8px;
`;

export const StyledInlineBlockSpan = styled.span`
  display: inline-block;
  padding-right: 25px;
`;

export const StyledHighlightedSpan = styled.span`
  color: ${(props) => props.$col || "var(--primary)"};
  font-style: ${(props) => props.$sty};
  font-weight: ${(props) => props.$weight};
`;

import { useState } from "react";

import { StyledFlexbox, StyledGrid } from "styles/util/Flex.styled";
import { StyledLineBorder } from "styles/molecules/LineBorder.styled";
import { StyledBeamButtons } from "styles/atoms/buttons/TextButtons.styled";
import { ArrowColumnItem } from "components/molecules/ColumnItem";

import { BeamButton } from "components/atoms/buttons/TextButtons";
import ProductTracker from "components/organisms/ProductTracker";

import DownloadSystem from "_components/features/file/download/DownloadSystem";
import IncomingFile from "_components/features/file/download/IncomingFile";

import constraint from "styles/function/mods/constraint";

const yGap = "60px";
const constraintHeight = "1300px";

const HeightConstraintFlexbox = constraint(StyledFlexbox);
const HeightConstraintGrid = constraint(StyledGrid);

const Receive = () => {
  const [currentView, setCurrentView] = useState("Inspect");

  const [files, setFiles] = useState({ videos: [], thumbnails: [] });

  return (
    <StyledFlexbox
      $width="100%"
      $pad="200px 10px"
      $gap="150px 50px"
      $wrap
      $row
      $justify="center"
      $align="start"
    >
      <DownloadSystem setFiles={setFiles} />

      <StyledFlexbox $wrap $justify="center" $row $gap={yGap}>
        <HeightConstraintGrid
          $height={constraintHeight}
          $gap={yGap}
          $rows="3fr 1fr"
        >
          <StyledLineBorder $title="Received Edits">
            <ArrowColumnItem>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </ArrowColumnItem>
          </StyledLineBorder>
          <StyledLineBorder $title="Pending Edits">
            <ArrowColumnItem>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </ArrowColumnItem>
          </StyledLineBorder>
        </HeightConstraintGrid>

        <HeightConstraintGrid
          $height={constraintHeight}
          $gap={yGap}
          $rows="3fr 1fr"
        >
          <StyledLineBorder $title="Received Thumbnails">
            <ArrowColumnItem>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </ArrowColumnItem>
            <ArrowColumnItem>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </ArrowColumnItem>
          </StyledLineBorder>
          <StyledLineBorder $title="Pending Thumbnails">
            <ArrowColumnItem>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </ArrowColumnItem>
          </StyledLineBorder>
        </HeightConstraintGrid>
      </StyledFlexbox>

      <div
        style={{
          position: "relative",
          flexGrow: currentView === "Track" ? 1 : 0,
          maxWidth: "1100px",
        }}
      >
        <StyledBeamButtons $offsetStyle="top: -85px">
          <BeamButton
            value="Inspect"
            currentValue={currentView}
            setCurrentValue={setCurrentView}
          />
          <BeamButton
            value="Track"
            currentValue={currentView}
            setCurrentValue={setCurrentView}
          />
        </StyledBeamButtons>

        {currentView === "Inspect" && (
          <HeightConstraintFlexbox $height={constraintHeight} $gap={yGap}>
            <StyledLineBorder $width="column" $title="Message from @editor">
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod
              </p>
            </StyledLineBorder>
            <StyledLineBorder $title="Received Files">
              {files.videos.map((data, index) => (
                <IncomingFile key={index} data={data} />
              ))}
            </StyledLineBorder>
          </HeightConstraintFlexbox>
        )}
        {currentView === "Track" && (
          <HeightConstraintFlexbox $height={constraintHeight}>
            <StyledLineBorder $title="Product Progress" $width="100%">
              {/* // TODO: Fill 'data' from server */}
              <ProductTracker />
            </StyledLineBorder>
          </HeightConstraintFlexbox>
        )}
      </div>
    </StyledFlexbox>
  );
};

export default Receive;

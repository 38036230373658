import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledBlurOverlay } from "_components/molecules/containers/overlays/Overlay.styled";
import { StyledBackdropElipse } from "_components/molecules/structure/Patterns";

const GlassSection = ({ $padTop, $padBottom, children, ...props }) => {
  return (
    <section
      style={{
        position: "relative",
        overflow: "hidden",
      }}
      {...props}
    >
      <StyledBlurOverlay />
      <StyledBackdropElipse
        $color="var(--actionPrimaryAlt)"
        $sizeX="500px"
        $sizeY="600px"
        $top="50px"
        $left="50px"
        $origin="-50%, -50%"
      />
      <StyledBackdropElipse
        $color="var(--actionPrimary)"
        $sizeX="500px"
        $sizeY="600px"
        $bottom="500px"
        $left="350px"
        $origin="-50%, 50%"
      />
      <StyledBackdropElipse
        $color="var(--actionPrimaryAlt)"
        $sizeX="500px"
        $sizeY="600px"
        $top="50%"
        $left="20%"
        $origin="50%, -50%"
      />
      <StyledBackdropElipse
        $color="var(--actionPrimary)"
        $sizeX="500px"
        $sizeY="600px"
        $bottom="100px"
        $right="350px"
        $origin="50%, 50%"
      />

      <StyledSpacer $pad={$padTop} />

      {children}

      <StyledSpacer $pad={$padBottom} />
    </section>
  );
};

export default GlassSection;

import styled from "styled-components";

import GlassSection from "components/organisms/GlassSection";
import { StyledFlexbox } from "styles/util/Flex.styled";
import {
  StyledInfoBlockH2,
  StyledInfoBlockP,
} from "styles/molecules/InfoBlock.styled";
import { StyledArrowIndentSpan } from "_components/atoms/inline/styles/Decorations.styled";
import { StyledHighlightedSpan } from "styles/atoms/SpanElements.styled";

import {
  StyledTag,
  StyledFadingTag,
} from "_components/molecules/displays/TagsPreview.styled";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

const AboutUs = () => {
  return (
    <GlassSection $padTop="200px" name="about-us">
      <StyledFlexbox $align="center" $gap="100px">
        <StyledFlexbox $row $align="center" $justify="center" $gap="558px">
          <StyledFlexbox $gap="29px">
            <StyledInfoBlockH2 $width="long" style={{ marginBottom: "11px" }}>
              About Us
            </StyledInfoBlockH2>
            <StyledInfoBlockP $width="long">
              PUSHXPULL is an agency which aims to help Roblox YouTubers
              specifically, to grow their channel using our many methods and
              services.
            </StyledInfoBlockP>
            <StyledInfoBlockP $width="long">
              We connect the Roblox Game Development industry with the Roblox
              Youtube Content Creation industry. This benefits both the game
              studios, and the Youtubers involved.
            </StyledInfoBlockP>
            <StyledInfoBlockP $width="long">
              <StyledHighlightedSpan $col="white">
                We create & manage..
              </StyledHighlightedSpan>
              <StyledArrowIndentSpan $shape="bullet" $col="white">
                Game Studios which all develop new games regularly.
              </StyledArrowIndentSpan>
              <StyledArrowIndentSpan $shape="bullet" $col="white">
                Production Teams of video editors and thumbnail artists, who
                work with you directly, through our company.
              </StyledArrowIndentSpan>
            </StyledInfoBlockP>
            <StyledInfoBlockP>
              We build new game studios and form new production teams very
              frequently to grow our company quickly which massively benefits
              you, which you can see below..
            </StyledInfoBlockP>
          </StyledFlexbox>

          <StyledFlexbox $align="center" $justify="center" $gap="50px">
            <HoverFlagBtn
              title="Game Manager"
              src="/images/buttons/game-icon.png"
            >
              View, select and monitor the progress of all of our upcoming games
            </HoverFlagBtn>
            <HoverFlagBtn title="Live Chat" src="/images/buttons/chat-icon.png">
              Message admins, your production team, and any contact from our sit
            </HoverFlagBtn>
            <HoverFlagBtn
              title="In-built Checkout"
              src="/images/icons/active/cart.png"
            >
              Quickly and easily purchase as many edits and thumbnails in
              advance as you wish
            </HoverFlagBtn>
            <HoverFlagBtn
              title="Staff Scheduler"
              src="/images/icons/active/calendar.png"
            >
              Your production team can efficiently co-ordinate their work using
              our scheduler
            </HoverFlagBtn>
            <HoverFlagBtn
              title="Ownership Booster"
              src="/images/icons/active/chart.png"
            >
              Quickly boost your ownership percentage of games by filling out a
              simple form
            </HoverFlagBtn>
            <HoverFlagBtn
              title="File Manager"
              src="/images/buttons/upload-icon.png"
            >
              Send, receive and manage files between you and your team quickly
            </HoverFlagBtn>
          </StyledFlexbox>
        </StyledFlexbox>

        <StyledFlexbox $gap="5px" $width="80%">
          <StyledFlexbox $row $gap="5px" $width="100%" $wrap>
            <StyledFadingTag $width="60px" $fadeTo="left" />
            <StyledTag>RBX Thumbnail Artists</StyledTag>
            <StyledTag>RBX Editors</StyledTag>
            <StyledTag>24/7 Support</StyledTag>
            <StyledTag>Reliable Team</StyledTag>
            <StyledTag>Connected Community</StyledTag>
            <StyledFadingTag $grow="1" $fadeTo="right" />
          </StyledFlexbox>
          <StyledFlexbox $row $gap="5px" $width="100%" $wrap>
            <StyledFadingTag $width="60px" $fadeTo="left" />
            <StyledTag>RBX Games</StyledTag>
            <StyledTag>RBX Studios</StyledTag>
            <StyledTag>RBX Developers</StyledTag>
            <StyledTag>Game Shareholding</StyledTag>
            <StyledTag>SMMA</StyledTag>
            <StyledFadingTag $grow="1" $fadeTo="right" />
          </StyledFlexbox>
        </StyledFlexbox>
      </StyledFlexbox>
    </GlassSection>
  );
};

export default AboutUs;

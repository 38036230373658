import { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import socketIOClient from "socket.io-client";

import {
  StyledFlexbox,
  StyledGrid,
  StyledPairDisplay,
  StyledSbs,
} from "styles/util/Flex.styled";
import { StyledChat } from "features/chat/Chat.styled";

import ChatSidebar from "./elements/ChatSidebar";
import ChatWindow from "./elements/ChatWindow";

import { ENDPOINT } from "js/data/constants";
import ParamTabSet from "_components/function/handlers/ParamTabSet";
import User from "components/atoms/User";

const ChatSystem = ({ client, userState: [users, setUsers] }) => {
  const [socket, setSocket] = useState(null);

  const [selectedUser, setSelectedUser] = useState({});

  const [messages, setMessages] = useState([
    {
      from: "System",
      body: "Hello there! Please select a user to start chatting with.",
    },
  ]);

  const setupSockets = () => {
    const username = ReactSession.get("useruid");

    if (!socket) {
      const sk = socketIOClient(ENDPOINT);
      setSocket(sk);
      sk.emit("onLogin", { name: username });
    }

    return () => {
      // ! Not working
      // TODO: Figure out how to detech page leave, and run this then
      // if (socket) socket.emit("disconnect");
    };
  };

  useEffect(setupSockets, [socket]);

  // return (
  //   <>
  //     <ParamTabSet
  //       $autoReset
  //       setTab={setSelectedUser}
  //       query="user"
  //       parser={(name) => users.find((x) => x.name === name)}
  //       // Chat members are only ready to connect to chat's of when socket is loaded
  //       dependancies={[socket]}
  //     />

  //     <StyledSbs
  //       $gap="20px"
  //       $shadowed
  //       style={{
  //         alignItems: "start",
  //         padding: "0 5%",
  //       }}
  //     >
  // <ChatSidebar
  //   socket={socket}
  //   selectedState={[selectedUser, setSelectedUser]}
  //   userState={[users, setUsers]}
  // />
  // <ChatWindow
  //   client={client}
  //   socket={socket}
  //   selectedUser={selectedUser}
  //   userState={[users, setUsers]}
  //   messageState={[messages, setMessages]}
  // />
  //     </StyledSbs>
  //   </>
  // );

  return (
    <StyledChat>
      <div className="topbar">
        <User $row>
          <StyledPairDisplay $flipped>
            <p>Molly</p>
            <p>Editor</p>
          </StyledPairDisplay>
        </User>
      </div>
      <ChatSidebar
        socket={socket}
        selectedState={[selectedUser, setSelectedUser]}
        userState={[users, setUsers]}
      />
      <ChatWindow
        client={client}
        socket={socket}
        selectedUser={selectedUser}
        userState={[users, setUsers]}
        messageState={[messages, setMessages]}
      />
    </StyledChat>
  );
};

export default ChatSystem;

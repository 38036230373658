import { PropsWithChildren } from "react";

import { StyledCenteredArea } from "styles/util/CenteredArea.styled";
import {
  StyledStandaloneNote,
  type StandaloneNoteProps,
} from "styles/atoms/StandaloneNote.styled";

export interface NotePageProps extends StandaloneNoteProps, PropsWithChildren {}

const NotePage = ({ children, ...props }: NotePageProps) => {
  return (
    <section
      style={{
        marginBottom: "300px",
        height: "1000px",
        width: "100%",
        position: "relative",
      }}
    >
      <StyledCenteredArea>
        <StyledStandaloneNote {...props}>{children}</StyledStandaloneNote>
      </StyledCenteredArea>
    </section>
  );
};

export default NotePage;

import React, { useRef, useState } from "react";
import axios from "axios";

import {
  StyledSelectP,
  StyledSelectFlexbox,
} from "_components/molecules/containers/overlays/styles/SelectScreen.styled";

import { EmbeddedFormItem } from "_components/features/form/FormItem";
import IconPopupBtn from "../../../atoms/buttons/IconPopupBtn";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const RevisionBtn = ({ $revisions, $revisionPending, fileId }) => {
  const formRef = useRef(null);

  const [enabled, setEnabled] = useState(!$revisionPending);
  const [revisionCount, setRevisionCount] = useState($revisions);

  const confirmSelectScreenInfo = {
    confirmed: {
      title: "Revision Activated!",
      description: (
        <>
          <p style={{ marginBottom: "0" }}>
            The same editor will re-edit the video and try to return the result
            in the same or less time then the edit originally took.
          </p>
          <p style={{ textAlign: "center", marginTop: "0" }}>
            <span style={{ color: "var(--action-primary)" }}>
              {revisionCount} revision(s) remaining
            </span>
          </p>
        </>
      ),
    },
    prompt: {
      title: "Request Revision?",
      description: (
        <StyledSelectFlexbox>
          <div>
            <StyledSelectP $width="280px">
              Are you sure you want to request a revision for this edit?
              Requesting a{" "}
              <span style={{ color: "var(--action-primary)" }}>revision </span>
              will result in this video being re-edited.
            </StyledSelectP>
            <StyledSelectP $centered $width="280px">
              <span style={{ color: "var(--action-primary)" }}>
                {revisionCount} revision(s) remaining
              </span>
            </StyledSelectP>
          </div>
          <EmbeddedFormItem
            ref={formRef}
            $width="300px"
            $label="Message"
            name="message"
            placeholder="Let production team know what to do differently this time (max 400 characters)"
            maxLength={400}
          />
        </StyledSelectFlexbox>
      ),
    },
  };

  async function onConfirmed() {
    axios.post(
      `${ENDPOINT}/api/drive/update/revisions/${fileId}`,
      {
        message: formRef.current.message.value,
      },
      {
        headers: {
          Authorization: await getAccessToken(),
        },
      }
    );
    setRevisionCount(revisionCount - 1);
    setEnabled(false);
  }

  return (
    <IconPopupBtn
      $width="large"
      title="Revision"
      src="/images/buttons/revision.png"
      enabled={enabled}
      setEnabled={setEnabled}
      confirmSelectScreenInfo={confirmSelectScreenInfo}
      onConfirmed={onConfirmed}
    />
  );
};

export default RevisionBtn;

import React from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

import getUploadID from "js/getUploadID";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const PauseBtn = ({ setIsPaused, progressUri }) => {
  const pauseUpload = async () => {
    const uploadID = getUploadID(progressUri);
    axios
      .get(ENDPOINT + `/api/drive/pause/${uploadID}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then(() => {
        setIsPaused(true);
      })
      // Tried resume before upload started, do nothing
      .catch(console.log);
  };

  return (
    <HoverFlagBtn
      $variant="Small"
      title="Pause"
      src="/images/buttons/pause.png"
      onClick={pauseUpload}
    />
  );
};

export default PauseBtn;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { logoutUser } from "js/features/session/userSession";

import { AccountContext } from "components/function/contexts/AccountContext";
import { StyledFlexbox } from "styles/util/Flex.styled";

const Logout = (props) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  const handleLogout = () => {
    logoutUser(navigate);
    setLoggedIn(false);
  };

  handleLogout();

  return (
    <StyledFlexbox
      $height="1000px"
      $width="100%"
      $align="center"
      $justify="center"
    >
      <h2 style={{ color: "var(--secondaryDim)" }}>Logging out..</h2>
    </StyledFlexbox>
  );
};

export default Logout;

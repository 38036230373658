import styled from "styled-components";

export const StyledSettings = styled.div`
  width: 100%;
  max-width: 1200px;

  display: flex;
  flex-direction: column;
  gap: 40px;

  & > section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .label {
      width: 300px;
      height: 150px;
      z-index: -1;

      display: flex;
      align-items: center;
      justify-content: center;

      background: var(--bgShadow);
    }

    .list {
      flex-grow: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-auto-rows: min-content;

      & > * {
        background: var(--bgShadow);

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;

        & > img {
          height: 21px;
        }
      }

      & > *:nth-child(2n) {
        background: var(--bgLightHighlight);
      }
    }
  }
`;

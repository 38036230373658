import { StyledDashboardBlock } from "_components/molecules/containers/DashboardBlock.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledNotification } from "styles/atoms/Notification.styled";

import User from "components/atoms/User";
import HoverFlagBtn from "components/atoms/buttons/HoverFlagBtn";

type Notification = {
  message: string;
  important?: boolean;
};

export interface Product {
  productId: number | string;
  productTitle: string;
}

interface ActiveProducts {
  productId: string;
  productTitle: string;
  productType: string;
  staffUsername: string;
  etaDays: number;
  notifications?: Notification[];
  setOverlayActive: (props: Product) => void;
}

const ActiveProduct = ({
  productId,
  productTitle,
  productType,
  staffUsername,
  etaDays,
  notifications = [],
  setOverlayActive,
}: ActiveProducts) => {
  // When 1 important notification is found in product.. We highlight the whole div
  const highlighted =
    notifications.find(({ important }) => important === true) != undefined;

  return (
    <StyledDashboardBlock $highlighted={highlighted} $width="450px">
      <StyledFlexbox>
        <StyledFlexbox $row $align="center" $gap="10px">
          <h3>{productTitle}</h3>
          <p style={{ color: "var(--defaultTintDark)" }}>#{productId}</p>
        </StyledFlexbox>
        <p style={{ color: "var(--defaultTintDark)" }}>{productType}</p>
      </StyledFlexbox>

      <StyledFlexbox>
        <User
          $row
          $gap="10px"
          $icon={
            highlighted
              ? "/images/buttons/user-dark.png"
              : "/images/buttons/user-light.png"
          }
        >
          <p style={{ color: "var(--defaultTint)" }}>{staffUsername}</p>
        </User>
        <p style={{ color: "var(--defaultDark)" }}>{etaDays} Days Left</p>
      </StyledFlexbox>

      <StyledFlexbox style={{ alignSelf: "end" }}>
        {notifications.map(({ message, important }) => (
          <StyledNotification
            $col={important ? "important" : highlighted ? "alt" : undefined}
          >
            {message}
          </StyledNotification>
        ))}
      </StyledFlexbox>

      <HoverFlagBtn
        $variant="Small"
        title="Alerts"
        src={
          highlighted
            ? "/images/buttons/notification-active.png"
            : "/images/buttons/notification.png"
        }
        onClick={() => setOverlayActive({ productId, productTitle })}
      />
    </StyledDashboardBlock>
  );
};

export default ActiveProduct;

import { forwardRef, PropsWithChildren } from "react";

import { StyledForm } from "./Form.styled";
import { StyledCtaSection } from "./FormContainers.styled";
import { StyledGroupWrapper } from "_components/features/form/styles/FormStructs.styled";

import Header from "_components/molecules/structure/Header";
import FormCta from "_components/features/form/FormCta";
import FormErrorMessage from "_components/features/form/elements/FormErrorMessage";

interface FormProps extends PropsWithChildren {
  title: string;
  description: string;
  disabled?: boolean;
  actionText: string;
  errorMessages: any;
  onSubmit: (e: any) => void;
  [key: string]: any;
}

const Form = forwardRef(function (
  {
    title,
    description,
    disabled,
    actionText,
    errorMessages = [],
    onSubmit,
    children,
    ...props
  }: FormProps,
  ref
) {
  return (
    <StyledForm
      ref={ref}
      onSubmit={
        !disabled
          ? onSubmit
          : (e) => {
              e.preventDefault();
              console.warn("Submit form overriden. Send in progress..");
            }
      }
      {...props}
    >
      <Header $align="center" title={title} description={description} />

      <div className="content">{children}</div>

      <StyledCtaSection>
        <StyledGroupWrapper>
          {errorMessages.map((message, index) => (
            <FormErrorMessage message={message} key={index} />
          ))}
        </StyledGroupWrapper>
        <FormCta $disabled={disabled} $size="long">
          {!disabled ? actionText : "Sending.."}
        </FormCta>
      </StyledCtaSection>
    </StyledForm>
  );
});

export default Form;

import styled from "styled-components";

import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledCenteredArea } from "styles/util/CenteredArea.styled";

interface StyledNode {
  $active: boolean;
}

type NodeNav = {
  nodeCount: number;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
};

const StyledNode = styled.button<StyledNode>`
  --size: 8px;

  height: var(--size);
  width: var(--size);
  border-radius: 2px;
  background-color: ${(props) =>
    props.$active ? "var(--actionPrimary)" : "var(--bgOutline)"};
  appearance: none;
  border: none;
`;

const NodeNav = ({ nodeCount, selectedIndex, setSelectedIndex }: NodeNav) => {
  const nodes = [];

  for (let i = 0; i < nodeCount; i++) {
    const node = (
      <StyledNode
        $active={selectedIndex === i}
        onClick={() => setSelectedIndex(i)}
      />
    );
    nodes.push(node);
  }

  return (
    <StyledCenteredArea
      $absolute="16px"
      style={{
        zIndex: 1,
      }}
    >
      <StyledFlexbox $row $gap="10px">
        {nodes}
      </StyledFlexbox>
    </StyledCenteredArea>
  );
};

export default NodeNav;

import { styled } from "styled-components";

// Necessary for when there is more then just heading, like sub-heading
export const StyledStripWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledHeadingStrip = styled.h1`
  text-align: center;
  user-select: none;
  margin: 0;
  z-index: 1;
`;

export const StyledSubStrip = styled.h4`
  color: ${(props) =>
    props.$actionCol ? "var(--action-primary)" : "var(--sub-info-light)"};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 20px 0 0 0;
  z-index: 1;
`;

export const StyledImgStrip = styled.div`
  position: relative;

  height: 220px;
  width: 100%;

  background-color: var(--main-background);

  &::after {
    content: "";
    position: absolute;

    background-image: url(${(props) => props.src});
    background-repeat: repeat-x;
    background-clip: content-box;
    background-position: ${(props) =>
      props.$pos ? "50% " + props.$pos : "50% 0"};

    opacity: ${(props) => props.$opacity || 0.4};
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 1370px) {
    background-size: cover;
  }
`;

export const StyledTopBanner = styled.div`
  position: relative;
  height: ${(props) => props.$height || "320px"};
  width: 100%;
`;

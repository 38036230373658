import styled, { css } from "styled-components";

import { StyledFlexbox } from "styles/util/Flex.styled";
import { CtaStatic } from "components/atoms/CtaElements";

export enum Pallet {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
}

type Entry = [string, boolean | string | number, string];

interface Theme {
  title: string;
  description: string;
  action: string;
  hover: string;
  text?: string;
  textHover?: string;
}

interface PackageOptionProps {
  pallet?: Pallet;
  title: string;
  description: string[];
  dividers?: string[];
  entries: Entry[][];
  to?: string;
}

interface StyledProps {
  $pallet?: Pallet;
}

interface PlanEntryProps {
  $value: boolean | string | number;
  $description?: string;
}

const pallets: { [pallet: string]: Theme } = {
  primary: {
    title: "var(--primary)",
    description: "var(--primaryDim)",
    action: "var(--actionPrimary)",
    hover: "white",
    textHover: "black",
  },
  secondary: {
    title: "var(--secondary)",
    description: "var(--secondaryDim)",
    action: "var(--bgOutline)",
    hover: "var(--actionPrimary)",
    text: "white",
    textHover: "white",
  },
  tertiary: {
    title: "white",
    description: "var(--defaultDim)",
    action: "white",
    hover: "var(--bgOutline)",
  },
};

const StyledDivider = styled(StyledFlexbox)`
  position: relative;
  color: var(--defaultDim);
  padding-bottom: 15px;
  width: 100%;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgb(54, 55, 71, 0.6);
    bottom: 10px;
    position: absolute;
    display: block;
  }
`;

export const StyledPlanEntry = styled.p<PlanEntryProps>`
  --size: 16px;

  display: flex;
  align-items: start;
  justify-content: start;
  gap: ${(props) => (typeof props.$value !== "boolean" ? "5px" : "10px")};

  position: relative;
  width: 100%;

  &::before {
    display: block;
    position: relative;
    color: var(--secondaryDark);
    margin-top: ${(props) => (typeof props.$value === "boolean" ? "5px" : "")};

    min-width: var(--size);
    min-height: var(--size);

    ${(props) =>
      typeof props.$value !== "boolean"
        ? css`
            content: ${(props) => `"${props.$value}"`};
          `
        : css`
            content: "";
            background: no-repeat center/contain
              ${(props) =>
                typeof props.$value === "boolean" &&
                (props.$value
                  ? `url("/images/design/checkmark.png")`
                  : `url("/images/design/x-symbol.png")`)};
            height: var(--size);
            width: var(--size);
          `};
  }

  margin-bottom: ${(props) => props.$description && "var(--size)"};

  &::after {
    content: ${(props) => props.$description && `"${props.$description}"`};
    display: block;
    position: absolute;

    left: 0;
    width: 100%;
    transform: translate(0, 100%);

    color: var(--defaultDim);
    opacity: 0.8;
  }
`;

export const StyledPackageOption = styled(StyledFlexbox)<StyledProps>`
  padding: 40px;
  width: 500px;
  height: 100%;
  border-radius: 10px;
  background-image: linear-gradient(
    to bottom right,
    rgb(26, 28, 41, 0.25),
    rgb(15, 17, 32, 0.7)
  );

  .heading {
    padding-bottom: 50px;

    & > h2 {
      color: ${(props) => (props.$pallet ? pallets[props.$pallet].title : "")};
    }

    & > p {
      color: ${(props) =>
        props.$pallet ? pallets[props.$pallet]?.description : ""};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
`;

const PackageOption = ({
  pallet = Pallet.Primary,
  title,
  description,
  dividers,
  entries,
  to,
}: PackageOptionProps) => {
  const theme = pallets[pallet];

  const Content = ({ list }) => {
    return (
      <div className="content">
        {list.map(([name, value, description]) => (
          <StyledPlanEntry
            $value={value != undefined ? value : true}
            $description={description}
          >
            {name}
          </StyledPlanEntry>
        ))}
      </div>
    );
  };

  return (
    <StyledPackageOption $pallet={pallet}>
      <div className="heading">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>

      {dividers &&
        dividers.map((dividerTitle, i) => (
          <StyledFlexbox style={{ width: "100%", marginBottom: "30px" }}>
            {dividerTitle && <StyledDivider>{dividerTitle}</StyledDivider>}
            <Content list={entries[i]} />
          </StyledFlexbox>
        ))}

      {!dividers && <Content list={entries} />}

      {to && (
        <CtaStatic
          $col={theme.action}
          $textCol={theme.text}
          $textHoverCol={theme.textHover}
          $hoverCol={theme.hover}
          $size="end"
          to={to}
          style={{ marginTop: "30px" }}
        >
          Select
        </CtaStatic>
      )}
    </StyledPackageOption>
  );
};

export default PackageOption;

import styled from "styled-components";

interface InputContainerProps {
  $flex?: boolean; // If true, 1 column are used instead of 2
  $locked?: boolean; // If true, width is clamped by px
}

export const StyledOptionContainer = styled.div`
  /* display:grid;
  width:min(400px,100%);
  align-items:center;
  justify-content:center;
  gap:30px;
  grid-template-columns:repeat(auto-fit,minmax(130px, 1fr)); */

  display: flex;
  width: 100%;
  justify-content: center;
  gap: 65px;
`;

export const StyledInputContainer = styled.div<InputContainerProps>`
  display: grid;
  width: 100%;
  gap: 50px;
  grid-template-columns: ${(props) =>
    !props.$flex ? "repeat(auto-fit, minmax(300px, 1fr))" : "1fr"};
  max-width: ${(props) => (props.$locked ? "500px" : "")};

  .full {
    grid-column: 1 / -1;
  }
`;

export const StyledCtaSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 15px;
  justify-content: center;
  align-items: center;

  & > p {
    text-align: center;
    color: var(--error);
  }
`;

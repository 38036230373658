import React from "react";

import { StyledMilestoneElement } from "styles/atoms/MilestoneTracker.styled";

const MilestoneElement = ({ children, ...props }) => {
  return (
    <StyledMilestoneElement {...props}>
      <div className="circle"></div>
      <div>{children}</div>
    </StyledMilestoneElement>
  );
};

export default MilestoneElement;

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  StyledInputContainer,
  StyledOptionContainer,
} from "features/form/FormContainers.styled";
import { StyledFormPage } from "features/form/Form.styled";
import Form from "features/form/Form";

import FormOption from "_components/features/form/FormOption";
import FormItem from "_components/features/form/FormItem";

import { ENDPOINT } from "js/data/constants";
import { getErrorMessage } from "js/data/formErrorMessages";

function JoinProduction() {
  const [errorMessages, setErrorMessages] = useState([]);

  const navigate = useNavigate();
  const formRef = useRef(null);

  const [canSendForm, setCanSendForm] = useState(true);

  const [isEditor, setIsEditor] = useState(false);
  const [isArtist, setIsArtist] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);

    if (!canSendForm) {
      const errMessage = "Form send in progress..";
      setErrorMessages([errMessage]);
      return;
    }

    const obj = {
      email: formRef.current.email.value,
      username: formRef.current.username.value,
      password: formRef.current.password.value,
      accessKey: formRef.current.access_key.value,
      accountType: "Production",
      isEditor: isEditor,
      isArtist: isArtist,
      // Can't be 'NULL' by how db is setup currently
      editSpeed: isEditor ? formRef.current.edit_speed.value : 0,
      thumbnailSpeed: isArtist ? formRef.current.thumbnail_speed.value : 0,
    };

    setCanSendForm(false);

    axios
      .post(`${ENDPOINT}/api/auth/user/signup`, obj)
      .then((res) => {
        let { hash } = res.data;
        if (!hash) {
          throw new Error(
            "Hash missing from production signup response on parsing!"
          );
        }
        console.log("successful production signup!");
        navigate(`/join/return?hash=${hash}&email=${obj.email}`);
      })
      .catch((error) => {
        setErrorMessages([getErrorMessage(error)]);
        setCanSendForm(true);
        console.error(error);
      });
  };

  return (
    <StyledFormPage>
      <Form
        ref={formRef}
        title="Join the Production Teams"
        description="Start earning instantly"
        disabled={!canSendForm}
        actionText="Create Account"
        errorMessages={errorMessages}
        onSubmit={handleSubmit}
      >
        <StyledOptionContainer>
          <FormOption
            value={!isEditor}
            name="isEditor"
            inputType="checkbox"
            selectedOption={isEditor}
            setSelectedOption={setIsEditor}
          >
            Join as a Video Editor
          </FormOption>
          <FormOption
            value={!isArtist}
            name="isArtist"
            inputType="checkbox"
            selectedOption={isArtist}
            setSelectedOption={setIsArtist}
          >
            Join as a Thumbnail Artist
          </FormOption>
        </StyledOptionContainer>

        <StyledInputContainer>
          <FormItem
            $title="Email"
            className="full"
            placeholder="example@gmail.com"
            type="email"
            name="email"
            maxLength={320}
            required
            autoFocus
          />
          <FormItem
            $title="Username"
            className="full"
            placeholder="username"
            name="username"
            maxLength={26}
            required
          />
          <FormItem
            $title="Password"
            placeholder="password"
            type="password"
            name="password"
            maxLength={50}
            required
          />
          <FormItem
            $title="Access Key"
            placeholder="abc123#!"
            name="access_key"
            required
          />

          {isEditor && (
            <FormItem
              $title="Edit Fulfillment Speed"
              className={!isArtist ? "full" : ""}
              placeholder="1-5 (days) (on average)"
              type="number"
              name="edit_speed"
              minLength={1}
              min={1}
              maxLength={5}
              max={5}
              required
            />
          )}
          {isArtist && (
            <FormItem
              $title="Thumbnail Fulfillment Speed"
              className={!isEditor ? "full" : ""}
              placeholder="1-3 (days) (on average)"
              type="number"
              name="thumbnail_speed"
              minLength={1}
              min={1}
              maxLength={3}
              max={3}
              required
            />
          )}
        </StyledInputContainer>
      </Form>
    </StyledFormPage>
  );
}

export default JoinProduction;

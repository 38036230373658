import styled from "styled-components";

interface StyledSpacer {
  $pad?: string;
}

export const StyledSpacer = styled.section<StyledSpacer>`
  position: relative;
  padding-top: ${(props) => props.$pad || "100px"};
`;

import styled, { css } from "styled-components";

// TODO: Override this with the block
export const StyledInfoBlockH2 = styled.h2`
  text-align: ${(props) => props.$align || "start"};
  max-width: ${(props) =>
    props.$width == "long" // Long content
      ? "560px"
      : props.$width == "head" // Heading
      ? "342px"
      : props.$width == "short" // Short content
      ? "663px"
      : "663px"};

  ${(props) =>
    props.$responsive &&
    css`
      @media screen and (max-width: 500px) {
        text-align: center;
        width: 90%;
      }

      @media screen and (min-width: 500px) and (max-width: 1370px) {
        text-align: center;
        width: 80%;
      }
    `}
`;

// TODO: Override this with the block
export const StyledInfoBlockP = styled.p`
  text-align: ${(props) => props.$align || "start"};
  max-width: ${(props) =>
    props.$width == "long" // Long content
      ? "560px"
      : props.$width == "head" // Heading
      ? "342px"
      : props.$width == "short" // Short content
      ? "496px"
      : "496px"};

  ${(props) =>
    props.$responsive &&
    css`
      @media screen and (max-width: 500px) {
        text-align: center;
        width: 80%;
      }

      @media screen and (min-width: 500px) and (max-width: 1370px) {
        text-align: center;
        width: 60%;
      }
    `}
`;

export const StyledInfoBlock = styled.div`
  display: flex;
  position: relative;
  align-items: ${(props) => props.$align || "start"};
  flex-direction: column;
  text-align: start;

  gap: ${(props) => props.$gap || "20px"};
  // TODO add width for this (standole heading)
  padding-left: ${(props) =>
    (props.$width == "short" || props.$width == "medium") &&
    props.$align == "start"
      ? "20%"
      : ""};

  p {
    text-align: start;
    max-width: ${(props) =>
      props.$width == "long" // Long content
        ? "560px"
        : props.$width == "head" // Heading
        ? "342px"
        : props.$width == "short" // Short content
        ? "496px"
        : props.$width == "medium" // Heading
        ? "700px"
        : props.$width == "full"
        ? "850px"
        : "496px"};

    ${(props) =>
      props.$responsive &&
      css`
        @media screen and (max-width: 500px) {
          text-align: center;
          width: 80%;
        }

        @media screen and (min-width: 500px) and (max-width: 1370px) {
          text-align: center;
          width: 60%;
        }
      `}
  }

  h1,
  h2,
  h3 {
    text-align: ${(props) => (props.$width == "full" ? "center" : "start")};
    max-width: ${(props) =>
      props.$width == "long" // Long content
        ? "560px"
        : props.$width == "head" // Heading
        ? "342px"
        : props.$width == "short" // Short content
        ? "663px"
        : "663px"};

    ${(props) =>
      props.$responsive &&
      css`
        @media screen and (max-width: 500px) {
          text-align: center;
          width: 90%;
        }

        @media screen and (min-width: 500px) and (max-width: 1370px) {
          text-align: center;
          width: 80%;
        }
      `}
  }
`;

import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import NotePage from "components/organisms/NotePage";
import { Cta } from "components/atoms/CtaElements";
import { LinkSpan } from "components/atoms/SpanElements";
import { StyledHighlightedSpan } from "styles/atoms/SpanElements.styled";

import { ENDPOINT } from "js/data/constants";

const JoinVerify = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const receivedError = urlParams.get("error");
  const hash = urlParams.get("hash");

  useEffect(() => {
    if (hash) {
      axios
        .post(`${ENDPOINT}/api/auth/verify/hash`, { hash })
        // Redirect back to this page without token param to display 'verified' screen
        .then(() => navigate("/join/verify/return"))
        .catch(console.error);
    }
  }, []);

  if (receivedError === "not_eligible") {
    return (
      <NotePage>
        <div>
          <h1>Not Eligible</h1>
          <p>
            Your youtube account doesn't meet the minimum subscriber count of{" "}
            <StyledHighlightedSpan>50,000</StyledHighlightedSpan> to join our
            company. Sorry!
          </p>
        </div>
        <Cta to="/">Go Home</Cta>
      </NotePage>
    );
  }

  if (receivedError === "no_email") {
    return (
      <NotePage $align="start">
        <div>
          <h1>Email Not Found!</h1>
          <p>
            No registered account in our system has an email address that
            matches the one you selected in the youtube verification process!
          </p>
          <p>
            If you did sign up but with a different email to the one your{" "}
            <StyledHighlightedSpan>preferred email</StyledHighlightedSpan>{" "}
            youtube account is linked to, then you can contact us to change your
            accounts email address to your{" "}
            <StyledHighlightedSpan>preferred email</StyledHighlightedSpan> via
            our <LinkSpan to="/contact">contact form</LinkSpan> (topic to select
            is <StyledHighlightedSpan>"Account Issue"</StyledHighlightedSpan>).
            Give us the email you want to change to and why and you will receive
            a success/failure response within the next 1-3 working days
          </p>
        </div>
        <Cta to="/contact">Contact Us</Cta>
      </NotePage>
    );
  }

  if (receivedError === "not_found") {
    return (
      <NotePage $align="start">
        <div>
          <h1>Email Not Found!</h1>
          <p>
            The email you choose to verify with does not match any email on any
            account in our system. This means you did not signup with the same
            email as the email you tried to verify with! Your youtube account's
            email and PUSHXPULL email <b>MUST MATCH</b>!
          </p>
          <p>
            To have your email on your account changed to the email you are
            trying to verify with, contact us via our{" "}
            <LinkSpan to="/contact">contact form</LinkSpan> (topic to select is{" "}
            <StyledHighlightedSpan>"Account Issue"</StyledHighlightedSpan>).
            Give us the email you want to change to and why and you will receive
            a success/failure response within the next 1-3 working days
          </p>
        </div>
        <Cta to="/contact">Contact Us</Cta>
      </NotePage>
    );
  }

  return (
    <NotePage>
      <div>
        <h1>Account Verified!</h1>
        <p>You can now login to your account and use our services!</p>
      </div>
      <Cta to="/login">Login</Cta>
    </NotePage>
  );
};

export default JoinVerify;

import styled, { css } from "styled-components";

import { DashedUnderlineStyle } from "_components/atoms/inline/styles/Decorations.styled";

export interface StyledBeamButtons {
  $offsetStyle?: string; // Example: "top: -85px"
}

export const StyledDottedLinkText = styled.p`
  color: var(--action-primary);
  position: relative;
  width: min(80%, 350px);
  text-align: center;
  padding-bottom: 10px;

  cursor: pointer;

  &:hover::after {
    --main-col: var(--action-primary);
  }

  &::after {
    ${DashedUnderlineStyle};
    --invis-col: var(--main-secondary);
    bottom: 0;
  }
`;

export const StyledBeamButtons = styled.div<StyledBeamButtons>`
  --gap: 12px;

  height: 26px;

  ${(props) =>
    props.$offsetStyle &&
    css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      // Applies offset dynamically based on the given side 'from' with its value in 'offset'
      ${props.$offsetStyle};
    `}

  & > button {
    position: relative;
    color: var(--defaultTintDark);

    appearance: none;
    border: none;
    background-color: unset;

    &:not(.static):hover {
      color: var(--defaultTint);
    }
  }

  & .static {
    color: var(--defaultTint);
  }

  & > button:not(:first-child) {
    padding-left: var(--gap);

    // Beam separator
    &::before {
      content: "";
      height: 8px;
      width: 1px;
      background-color: var(--defaultTintDark);

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & > button:not(:last-child) {
    padding-right: var(--gap);
  }
`;

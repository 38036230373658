import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledSwitchFlip } from "styles/atoms/buttons/ToggleButtons.styled";

type SwitchFlip = {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
};

const SwitchFlip = ({ enabled, setEnabled }: SwitchFlip) => {
  return (
    <StyledFlexbox $row $align="center" $gap="17px">
      <p style={{ color: "var(--secondary)" }}>Are you a Roblox Youtuber?</p>
      <StyledSwitchFlip
        $enabled={enabled}
        onClick={() => setEnabled(!enabled)}
      />
    </StyledFlexbox>
  );
};

export default SwitchFlip;

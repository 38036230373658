import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledAlertNote } from "styles/atoms/AlertNote.styled";

import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

import { AccountContext } from "components/function/contexts/AccountContext";

type Navbar = {
  children: any;
};

const GlobalDashboardBackground = createGlobalStyle`
  body {
    background: linear-gradient(to bottom right, var(--bgLightGradientFirst), var(--bgLightGradientLast));
  }
`;

const Navbar = ({ children: routes }: Navbar) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [inDashboard, setInDashboard] = useState(false);
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  useEffect(() => {
    const inDashboard_ = location.pathname.split("/")[1] === "dashboard";
    setInDashboard(inDashboard_);
    if (inDashboard_ && !loggedIn) navigate("/");
  }, [location]);

  // Nesting routes allows sidebar to take up space, pushing page along with it (topbar doesn't do this)
  return inDashboard ? (
    <>
      <StyledAlertNote>
        Dashboard currently in <b>Preview Only Mode</b>. Your dashboard will be
        enabled on <b>Feb 1st</b>. You will still receive payouts from your{" "}
        <b>Affiliate Link</b>.
      </StyledAlertNote>
      <StyledFlexbox $row>
        <GlobalDashboardBackground />
        {/* // TODO: Remove once dashboard functionality is all caught up */}
        <Sidebar />
        {routes}
      </StyledFlexbox>
    </>
  ) : (
    <>
      <Topbar />
      {routes}
    </>
  );
};

export default Navbar;

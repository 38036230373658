import { createContext } from "react";

export const DownloadContext = createContext([
  [
    [],
    () =>
      console.error(
        "Set state failed! Download context has not been initialized."
      ),
  ],
  [
    [],
    () =>
      console.error(
        "Set state failed! Download context has not been initialized."
      ),
  ],
]);

export const learnContents = {
  [""]: {
    ["Pricing"]: ["/learn/pricing", "How our pricing system works"],
    ["Game Benefits"]: [
      "/learn/game",
      "Everything about the benefits you get from our games",
    ],
    ["Production Benefits"]: [
      "/learn/production",
      "Everything about the benefits from working with our production teams",
    ],
  },
};

export const exploreContents = {
  [""]: {
    ["Home"]: ["/", "Overview of our company"],
    ["Join our Staff"]: [
      "/explore/production",
      "For video editors & thumbnail artists interested in joining the production teams",
    ],
    ["Become an Affiliate"]: [
      "/explore/affiliate",
      "All information regarding being an Affiliate with our company",
    ],
  },
};

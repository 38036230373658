import { useState } from "react";

import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledLabelledIcon } from "styles/atoms/LabelledIcon.styled";
import { StyledWrappedImg, StyledImgFade } from "styles/atoms/FadedImg.styled";
import { StyledDashboard } from "styles/molecules/Dashboard.styled";
import { StyledBasket } from "features/shop/Basket.styled";

import Stat from "components/atoms/Stat";
import { Cta, CtaInlineText } from "components/atoms/CtaElements";
import BasketItem from "features/shop/BasketItem";
import FadedImg from "components/atoms/FadedImg";

const Shop = () => {
  const [currentValue, setCurrentValue] = useState(3);

  return (
    <StyledFlexbox $width="100%" $row aria-label="store">
      <StyledDashboard $row aria-label="content-list">
        <StyledFlexbox $width="250px" aria-label="item-card">
          <FadedImg $height="100px" round="5px 5px 0 0" />

          <StyledFlexbox
            $bg="var(--bgLightHighlight)"
            $pad="10px 20px"
            $round="0 0 5px 5px"
            $gap="20px"
          >
            <StyledFlexbox aria-label="heading">
              <h3>Video Edit</h3>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et
              </p>
            </StyledFlexbox>

            <StyledFlexbox $row>
              <Stat
                icon="/images/buttons/helmet.png" // todo: make dollar sign
                value={1}
                label="/ min"
              />
            </StyledFlexbox>

            <StyledFlexbox
              $row
              $justify="end"
              $height="25px"
              $width="100%"
              aria-label="cta-container"
            >
              <CtaInlineText
                style={{ paddingRight: "0.7rem" }}
                $hover="arrow"
                onClick={console.log}
              >
                Add to Cart
              </CtaInlineText>
            </StyledFlexbox>
          </StyledFlexbox>
        </StyledFlexbox>
      </StyledDashboard>

      <StyledBasket aria-label="basket-sidebar">
        <StyledLabelledIcon
          $icon="/images/icons/static/basket.png"
          $flipped
          $col="static"
          aria-label="icon-heading"
          style={{ alignSelf: "start" }}
        >
          Basket
        </StyledLabelledIcon>

        <div className="content">
          <div className="list">
            <BasketItem
              currentValue={currentValue}
              setCurrentValue={setCurrentValue}
            />
          </div>

          <div className="summary">
            <div className="summary-item">
              <p>Video Edit x 2</p>
              <p>+ $9</p>
            </div>

            <div className="summary-item">
              <p>Video Edit x 2</p>
              <p>+ $9</p>
            </div>

            <p className="summary-total">Total $21</p>
          </div>
        </div>

        <Cta>Checkout</Cta>
      </StyledBasket>
    </StyledFlexbox>
  );
};

export default Shop;

import { useState } from "react";
import { ReactSession } from "react-client-session";
import { useLocation, useNavigate } from "react-router-dom";

import { StyledSpacer } from "styles/util/Spacer.styled";
import { StyledFlexbox } from "styles/util/Flex.styled";
import { StyledSidebar, StyledTab } from "./Sidebar.styled";

import User from "components/atoms/User";
import { SimpleIconBtn } from "components/atoms/buttons/IconButtons";

import fadable from "_components/function/modifiers/fadable";
import DirectionalBtn from "components/atoms/buttons/DirectionalBtn";

type Tab = {
  $icon: string;
  $iconAlt?: string;
  title: string;
  path: string;
  setSelectedTab?: (tab: string) => void;
  exact?: boolean; // Sub tabs are exact while their parent tab is loosely matched by basename
  collapsed?: boolean;
  override?: boolean; // Allows the tab to be clicked even if it's already selected
  style?: object;
};

const FadableUser = fadable(User);

export const Tab = ({
  $icon,
  $iconAlt,
  title,
  path,
  setSelectedTab,
  exact,
  collapsed,
  override,
  style,
}: Tab) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <StyledTab
      $icon={$icon}
      $iconAlt={$iconAlt}
      $selected={
        exact ? location.pathname === path : location.pathname.includes(path)
      }
      style={style}
      onClick={() => {
        // Doesn't change the sub-tab by clicking out of the the sub-tab menu. Only changes when switching main tab
        if (override || !location.pathname.includes(path)) navigate(path);
        if (setSelectedTab) setSelectedTab(title);
      }}
    >
      {!collapsed ? title : ""}
    </StyledTab>
  );
};

const Sidebar = () => {
  const username = ReactSession.get("useruid") || "User";
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Dashboard");

  // Quick hand hiding of variables
  function collapsible(value: string | number) {
    if (typeof value === "string") {
      return !isCollapsed ? value : "";
    }
    return !isCollapsed ? value : 0;
  }

  const collapseBtn = (
    <SimpleIconBtn
      $imgHeight="20px"
      $active={!isCollapsed}
      $activeStyle="rotate: 180deg"
      src="/images/common/arrow-inactive.png"
      onClick={() => setIsCollapsed(!isCollapsed)}
      style={{
        position: "absolute",
        top: "20px",
        right: "20px",
      }}
    />
  );

  const subPages: { [title: string]: JSX.Element } = {
    Production: (
      <>
        <Tab
          $icon="/images/buttons/user.png"
          $iconAlt="/images/buttons/user-active.png"
          path="/dashboard/production/receive"
          title="Recieve"
          exact
          collapsed={isCollapsed}
        />
        <Tab
          $icon="/images/buttons/upload.png"
          $iconAlt="/images/buttons/upload-icon.png"
          path="/dashboard/production/send"
          title="Send"
          exact
          collapsed={isCollapsed}
        />
      </>
    ),
  };

  const subTabs = subPages[selectedTab];
  if (subTabs != undefined) {
    return (
      <StyledSidebar $collapsed={isCollapsed}>
        {collapseBtn}
        <StyledSpacer $pad="30px" />
        <FadableUser
          $opacity={0} // Doesn't show user, but keeps it's space
          $width="max(200px, 100%)" // Prevents lower elements being pushed down when 'collapsing'
          $text="center"
        >
          <p>Welcome, {username}</p>
        </FadableUser>
        <StyledSpacer $pad="80px" />

        <DirectionalBtn
          text={collapsible(selectedTab) as string}
          style={{
            alignSelf: "start",
            marginLeft: "20px",
          }}
          onClick={() => setSelectedTab("")}
        />
        <StyledSpacer $pad="20px" />
        <StyledFlexbox $gap="45px">{subTabs}</StyledFlexbox>
      </StyledSidebar>
    );
  }

  return (
    <StyledSidebar $collapsed={isCollapsed}>
      {collapseBtn}
      <StyledSpacer $pad="30px" />

      <FadableUser
        $opacity={collapsible(1)}
        $width="max(200px, 100%)" // Prevents lower elements being pushed down when 'collapsing'
        $text="center"
      >
        <p>Welcome, {username}</p>
      </FadableUser>

      <StyledSpacer $pad="80px" />
      <StyledFlexbox $gap="45px">
        <Tab
          $icon="/images/buttons/user.png"
          $iconAlt="/images/buttons/user-active.png"
          path="/dashboard/home"
          title="Dashboard"
          collapsed={isCollapsed}
          setSelectedTab={setSelectedTab}
        />
        <Tab
          $icon="/images/buttons/upload.png"
          $iconAlt="/images/buttons/upload-icon.png"
          path="/dashboard/production"
          title="Production"
          collapsed={isCollapsed}
          setSelectedTab={setSelectedTab}
        />
        <Tab
          $icon="/images/buttons/game.png"
          $iconAlt="/images/buttons/game-icon.png"
          path="/dashboard/games"
          title="Games"
          collapsed={isCollapsed}
          setSelectedTab={setSelectedTab}
        />
        <Tab
          $icon="/images/buttons/chat.png"
          $iconAlt="/images/buttons/chat-icon.png"
          path="/dashboard/chat"
          title="Chat"
          collapsed={isCollapsed}
          setSelectedTab={setSelectedTab}
        />
        <Tab
          $icon="/images/buttons/cart.png"
          $iconAlt="/images/icons/active/cart.png"
          path="/dashboard/shop"
          title="Shop"
          collapsed={isCollapsed}
          setSelectedTab={setSelectedTab}
        />
        <Tab
          $icon="/images/buttons/settings.png"
          $iconAlt="/images/buttons/settings-active.png"
          path="/dashboard/settings"
          title="Settings"
          collapsed={isCollapsed}
          setSelectedTab={setSelectedTab}
        />
      </StyledFlexbox>
      <Tab
        $icon="/images/buttons/logout.png"
        $iconAlt="/images/buttons/logout-active.png"
        path="/dashboard/logout"
        title="Logout"
        collapsed={isCollapsed}
        style={{ position: "absolute", bottom: "30px" }}
      />
    </StyledSidebar>
  );
};

export default Sidebar;

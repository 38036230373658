import styled from "styled-components";
import { StyledFlexbox } from "styles/util/Flex.styled";

interface StyledSidebarProps {
  $collapsed?: boolean;
}

interface StyledTabProps {
  $icon: string;
  $iconAlt?: string;
  $selected?: boolean;
  $visible?: boolean;
}

export const StyledSidebar = styled(StyledFlexbox)<StyledSidebarProps>`
  background-color: var(--bgLightShadow);
  height: 100%;
  min-height: 1000px;

  width: ${(props) => (props.$collapsed ? "60px" : props.$width || "200px")};
  min-width: ${(props) =>
    props.$collapsed ? "60px" : props.$width || "200px"};
  transition: min-width 0.25s ease-in-out, width 0.25s ease-in-out;
  overflow-x: hidden;

  padding: 30px 0;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const StyledTab = styled.div<StyledTabProps>`
  --size: 20px;

  position: relative;
  display: flex;
  align-items: center;

  gap: 14px;
  padding-left: 20px;

  // Standard height for all tab elements everywhere.
  // Also prevents height changes when text is hidden upon state changes
  height: 25px;

  color: ${(props) =>
    props.$selected ? "var(--primaryDim)" : "var(--iconInactive)"};

  cursor: pointer;
  user-select: none;
  transition: filter 0.1s ease-in-out;

  // Icon
  &::before {
    content: "";
    display: block;
    height: var(--size);
    width: var(--size);
    background: ${(props) =>
        `url(${
          props.$selected && props.$iconAlt ? props.$iconAlt : props.$icon
        })`}
      no-repeat center/contain;
  }

  &:hover {
    filter: brightness(1.2);
  }
`;
